var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-advanced-chat',{staticStyle:{"text-align":"left"},attrs:{"height":_vm.height,"current-user-id":_vm.currentUserId,"rooms":JSON.stringify(_vm.room),"rooms-loaded":_vm.roomsLoaded,"messages":JSON.stringify(_vm.messagesToDisplay),"messages-loaded":_vm.messagesLoaded,"message-actions":_vm.messageActions,"single-room":true,"show-audio":"false","show-files":"false","accepted-files":"image/jpeg","show-emojis":"false","styles":JSON.stringify(_vm.styles),"emojis-suggestion-enabled":false,"text-messages":JSON.stringify(
			{
				ROOMS_EMPTY: _vm.$t('ROOMS_EMPTY'),
				ROOM_EMPTY: _vm.$t('ROOM_EMPTY'),
				NEW_MESSAGES: _vm.$t('NEW_MESSAGES'),
				MESSAGE_DELETED: _vm.$t('MESSAGE_DELETED'),
				MESSAGES_EMPTY: _vm.$t('MESSAGES_EMPTY'),
				CONVERSATION_STARTED: _vm.$t('CONVERSATION_STARTED'),
				TYPE_MESSAGE: _vm.$t('TYPE_MESSAGE'),
				SEARCH: _vm.$t('SEARCH'),
				IS_ONLINE: _vm.$t('IS_ONLINE'),
				LAST_SEEN: _vm.$t('LAST_SEEN'),
				IS_TYPING: _vm.$t('IS_TYPING'),
				CANCEL_SELECT_MESSAGE: _vm.$t('CANCEL_SELECT_MESSAGE')
			}
		),"show-footer":true,"show-reaction-emojis":"false"},on:{"send-message":_vm.sendMessage,"open-file":function($event){return _vm.downloadFile($event.detail[0])}}},[_c('div',{staticStyle:{"width":"100% !important"},attrs:{"slot":"room-header"},slot:"room-header"},[(_vm.roleName !== 'HOSPITAL_ADMIN')?_c('v-col',{staticClass:"d-flex",staticStyle:{"width":"90% !important","justify-content":"space-between"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("communication")))])]),_vm._v(" "),_c('div',[_c('span',{staticClass:"d-flex align-center justify-center"},[(!_vm.video)?_c('v-btn',{staticClass:"d-flex align-center justify-center",attrs:{"elevation":"0","fab":"","small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.initiateCall(true)}}},[_c('img',{attrs:{"height":"18px","width":"18px","src":_vm.videoCallImg}})]):_vm._e(),_vm._v(" "),(!_vm.video)?_c('v-btn',{staticClass:"ml-2 d-flex align-center justify-center",attrs:{"elevation":"0","fab":"","small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.initiateCall(false)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-phone")])],1):_vm._e()],1)])]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }