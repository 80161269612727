<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat style="overflow: hidden">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{$t('assignMentalHealthAssessment')}}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
        >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text color="primary" class="mt-3" >
        <v-form dense>
          <v-row dense class="pt-5">
            <v-col cols="5"
            >
              <v-text-field
                  dense
                  readonly
                  disableds
                  outlined
                  hide-details
                  :value="medicalTemaName"
                  :label="$t('assign-mental-health-template')"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
            >
              <v-select
                  :items="verifiersForSelect"
                  hide-details
                  item-value="lowValue"
                  :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                  v-model="RecurrenceRuleForSurvey"
                  dense
                  :label="$t('recurrence')"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="3"
            >
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      dense
                      hide-details
                      outlined
                      :label="$t('start-date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    range
                    color="primary"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <!-- FIRST COLUMN -->
            <v-col v-if="path !== 'PatientDetailsCard'" cols="12" lg="6" md="6" sm="12">
              <v-text-field
                  v-model="searchPatient"
                  append-icon="mdi-magnify"
                  :label="$t('search-mental-health-template')"
                  single-line
                  hide-details
                  class="my-1"
              ></v-text-field>
              <v-data-table
                  dense
                  fixed-header
                  :items="surveysTable"
                  item-key="id"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  hide-default-footer
                  height="440"
                  :search="searchPatient"
                  :headers="patientHeadr"
                  v-model="selectedRows"
                  style="width: 500px;"
                  :items-per-page="-1"
                  class="elevation-1"
                  :footer-props="{
                  itemsPerPageText: this.$t('row'),
                  itemsPerPageAllText: this.$t('all'),
                  }"
              >
                <template v-slot:item="{ item }">
                  <tr :style="selectedRows.indexOf(item)>-1?'color: white':'' " :class="selectedRows.indexOf(item)>-1?'primary':''" @click="rowClicked(item)">
                    <td style="text-align: left;">{{item.templateName}}</td>
                    <td style="text-align: left;">{{item.templateDescription}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col v-else cols="12" lg="12" md="12" sm="12">
              <v-text-field
                  v-model="searchPatient"
                  append-icon="mdi-magnify"
                  :label="$t('search-mental-health-template')"
                  single-line
                  hide-details
                  class="my-1"
              ></v-text-field>
              <v-data-table
                  dense
                  fixed-header
                  :items="surveysTable"
                  item-key="id"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  hide-default-footer
                  height="440"
                  :search="searchPatient"
                  :headers="patientHeadr"
                  v-model="selectedRows"
                  style="width: 1000px;"
                  :items-per-page="-1"
                  class="elevation-1"
                  :footer-props="{
                  itemsPerPageText: this.$t('row'),
                  itemsPerPageAllText: this.$t('all'),
                  }"
              >
                <template v-slot:item="{ item }">
                  <tr :style="selectedRows.indexOf(item)>-1?'color: white':'' " :class="selectedRows.indexOf(item)>-1?'primary':''" @click="rowClicked(item)">
                    <td style="text-align: left;">{{item.templateName}}</td>
                    <td style="text-align: left;">{{item.templateDescription}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <!-- SECOND COLUMN -->
            <v-col v-if="path !== 'PatientDetailsCard'" cols="12" lg="6" md="6" sm="12">
              <v-text-field
                  v-model="searchMedicalTeam"
                  append-icon="mdi-magnify"
                  :label="$t('search-Patient')"
                  single-line
                  class="my-1"
                  hide-details
              ></v-text-field>
              <v-data-table
                  dense
                  fixed-header
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  :headers="staffHeadr"
                  :items="filteredUsers"
                  hide-default-footer
                  item-key="id"
                  show-select
                  v-model="selected"
                  :search="searchMedicalTeam"
                  height = "440"
                  :items-per-page="-1"
                  class="elevation-1"
                  :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card>
      </v-card>

      <v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
            <span cols="12" lg="6" md="6" sm="12" >
              <v-btn class="table-cancel-button" @click="$emit('close')">
                <span color="black--text">{{ $t("cancel") }}</span>
              </v-btn>
            </span>
        <!--        save button-->
        <span v-if="!disableSave && !disableSaveRecurrence && !disableSaveDate" cols="12" lg="6" md="6" sm="12">
              <v-btn
                  class="table-create-button mx-1"
                  :loading="loading"
                  @click="save">
                <span>{{ $t("save") }}</span>
              </v-btn>
            </span>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { doctorBlack, nurseBlack } from '@/assets';
import { calculateAge } from '@/utils/unit-converter';

export default {
	props: ['dialog', 'data', 'deniedPatients', 'edit', 'type', 'path', 'patientData'],
	data () {
		return {
			date: null,
			menu: false,
			modal: false,
			menu2: false,
			loader: null,
			loading: false,
			medicalTemaName: null,
			endDateFlag: false,
			selectedStaff: [],
			// relatedPatients: [],
			staff: [],
			doctors: [],
			disableSaveDate: true,
			RecurrenceRuleForSurvey: null,
			starterArray: [],
			selectedTamplate: null,
			selectedPatient: undefined,
			medicalTeamTitle: '',
			doctor_black: doctorBlack,
			nurse_black: nurseBlack,
			filteredUsers: [],
			disableSave: true,
			disableSaveRecurrence: true,
			searchPatient: null,
			searchMedicalTeam: null,
			primaryDoctor: null,
			patients: [],
			selected: [],
			primaryDoctorSelected: [],
			staffSelected: [],
			selectedRows: [],
			patientHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'templateName',
				},
				{
					text: this.$t('description'),
					align: 'start',
					value: 'checkBox',
				},
			],
			staffHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'fullname',
				},
				{
					text: this.$t('age'),
					value: 'age',
				},
				{
					text: this.$t('gender-header'),
					value: 'gender',
				},
			],

		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			relatedUsers: (state) => state.hospitals.users,
			verifiers: (state) => state.verifiers.verifiers,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			surveys: state => state.mentalhealth.surveyTemplates,
			usersFromRepo: (state) => state.users.users,
			assignedSurvay: state => state.mentalhealth.survey,
		}),
		staffData () {
			return this.staff.filter(s => { return s.id !== this.primaryDoctor && s.status === 1; });
		},
		verifiersForSelect () {
			if (this.verifiers) {
				return this.verifiers;
			} else {
				return [];
			}
		},
		surveysTable () {
			if (this.surveys) {
				// Assuming 'type' is a data property or passed as a prop
				const type = this.type;

				// Check if 'type' is defined
				if (type !== undefined && type !== null) {
					// Filter surveys based on mentalHealthCategoryId
					return this.surveys.filter(survey => survey.mentalHealthCategoryId === type);
				} else {
					// Return all surveys if 'type' is not provided
					return this.surveys;
				}
			} else {
				return [];
			}
		},
		checkValidity () {
			if (this.medicalTemaName === null) {
				return true;
			}
			if (this.staffSelected.length === 0) {
				return true;
			}
			if (this.primaryDoctorSelected.length === 0) {
				return true;
			}
			return false;
		},
	},
	watch: {
		medicalTemaName (val) {
			if (val !== null) {
				this.disableSave = false;
			} else {
				this.disableSave = true;
			}
		},
		RecurrenceRuleForSurvey (val) {
			if (val !== null) {
				this.disableSaveRecurrence = false;
			} else {
				this.disableSaveRecurrence = true;
			}
		},
		date (val) {
			if (val !== null) {
				this.disableSaveDate = false;
			} else {
				this.disableSaveDate = true;
			}
		},
		dialog (val) {
			if (val) {
				const header = { domainsList: 'SURVEY_RECURRENCER_RULE' };
				this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
			}
		},
		primaryDoctorSelected (val) {
			if (!this.staffSelected.includes(val)) {
				this.staffSelected.push(val);
			}
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.manageMedicalTeam()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	mounted () {
		if (!this.surveys || this.surveys.length === 0) {
			this.getPatients();
		}
	},
	methods: {
		save () {
			const patientId = this.patientData.userData.id;
			var obj = {
				body: {
					SurveyTemplateId: this.selectedTamplate,
					RecurrenceRuleForSurvey: this.RecurrenceRuleForSurvey,
					StartDate: this.date[0],
					EndDate: this.date[1] ? this.date[1] : null,
					HospitalId: this.userData.hospitalAssignedData.id,
				},
				header: null,
			};

			if (this.path === 'Patients') {
				obj.header = this.selected.map(obj => obj.id);
			} else {
				obj.header = [patientId];
			}

			this.$store.dispatch('mentalhealth/assigneMentalHealth', obj).then(() => {
				this.$emit('reloadMonitoringPlan');
				this.$emit('close');
			});

			this.selected = [];
			this.selectedTamplate = [];
			this.date = null;
			this.RecurrenceRuleForSurvey = null;
			this.selectedRows = [];
		},
		async getPatients () {
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			await this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospitalIdFromStore);

			// await this.$store.dispatch('users/getHospitalPatients').then(() => {
			// 	// Removed filter By Nusret this feature (this feature is added on moment when patient is created if the patient has mental health flag true in that case we are showing on this list here)
			// 	// Also thi feature can be shown when doctor has specialization psychiatry
			// 	this.usersFromRepo.filter(user => (user.patientMetadata && user.patientMetadata.mentalHealth && user.patientMetadata.mentalHealth !== null)).map(item => {
			// 		var obj = {
			// 			id: item.id,
			// 			fullname: item.firstName + ' ' + item.lastName,
			// 			age: calculateAge(item.birthdate),
			// 			gender: item.gender === 'M' ? this.$t('male') : this.$t('female'),
			// 		};
			// 		this.filteredUsers.push(obj);
			// 	});
			// }).then(() => {
			// });

			await this.$store.dispatch('hospitals/getRelatedPatientsv2', hospitalIdFromStore)
				.then(() => {
					this.filteredUsers = [];
					// Removed filter By Nusret this feature (this feature is added on moment when patient is created if the patient has mental health flag true in that case we are showing on this list here)
					// Also thi feature can be shown when doctor has specialization psychiatry
					this.relatedPatients.filter(user => (user.patientMetadata && user.patientMetadata.mentalHealth && user.patientMetadata.mentalHealth !== null)).map(item => {
						var obj = {
							id: item.id,
							fullname: item.firstName + ' ' + item.lastName,
							age: calculateAge(item.birthdate),
							gender: item.gender === 'M' ? this.$t('male') : this.$t('female'),
						};
						this.filteredUsers.push(obj);
					});
				});
		},
		rowClicked (row) {
			if (!this.edit) {
				this.toggleSelection(row);
				this.selectedTamplate = row.id;
				this.medicalTemaName = row.templateName;
				if (this.selectedRows.length > 1) {
					this.selectedRows.shift();
				}
			}
		},
		toggleSelection (keyID) {
			if (this.selectedRows.includes(keyID)) {
				this.selectedRows = this.selectedRows.filter(
					selectedKeyID => selectedKeyID !== keyID,
				);
			} else {
				this.selectedRows.push(keyID);
			}
		},
	},
};
</script>
