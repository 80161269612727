<template >
  <v-dialog
      v-model="dialog"
      width="1100"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      style="background-color: blue;"
  >
    <v-card  flat fixed-header>
      <v-card-title class="secondary py-1" style="height: 40px; position: sticky; top: 0; z-index: 10">
        <v-row  class="d-flex justify-end align-center">
          <span class="dialog-title">{{ $t("all") }} {{ $t("notes") }}</span>
          <v-spacer />
          <v-chip
              @click="downloadPDF(detailedUser.userData.firstName, detailedUser.userData.lastName)"
              pill
              small
              label
              medium
              color="primary"
              class="mx-1 text--black"
              style="float:right"
          >
            {{ $t("download-medical-notes") }}
          </v-chip>
          <!--        <v-chip-->
          <!--            @click="vitalsPdf()"-->
          <!--            pill-->
          <!--            small-->
          <!--            label-->
          <!--            medium-->
          <!--            color="primary"-->
          <!--            class="mx-1 text&#45;&#45;black"-->
          <!--            style="float:right"-->
          <!--        >-->
          <!--          Download Vitals-->
          <!--        </v-chip>-->
          <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text id="contentToPrint">
        <!--        <v-row class="my-3" style="border-top: 9px solid white;">-->
        <!--        </v-row>-->
        <v-row style="padding-bottom: 24px; margin-bottom: 0px; margin-top: 24px; font-family: Arial;">
          <v-col  style="text-align: left; margin-left: 24px; margin-right: 16px; padding-right: 64px">
            <v-row><span style="font-weight: bold">{{detailedUser.userData?.firstName + ' ' + detailedUser.userData?.lastName}}</span></v-row>
            <v-row><span>{{detailedUser.userData?.address}}</span></v-row>
            <v-row><span>{{phone(this.detailedUser.userData?.phone)}}</span></v-row>
            <v-row><span>{{detailedUser.userData?.email}}</span></v-row>
            <v-row><span>{{detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber ? detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber : ''}}</span></v-row>
          </v-col>
          <v-col style="text-align: left ;margin-left: 4px; margin-right: 4px;">
            <v-row style="font-weight: bold">{{detailedUser.medicalTeamData[0]?.hospitalName}}</v-row>
            <!-- <v-row style="text-right: right"><span>{{hospitals[0]?.address}}</span></v-row> -->
            <v-row >{{ hospitals[0]?.address }}</v-row>
            <v-row >{{hospitals[0]?.contactPhone}}</v-row>
            <v-row >{{hospitals[0]?.contactEmail}}</v-row>
          </v-col>
        </v-row>
        <v-col>
          <!--       PROBLEM LIST -->
          <v-col style="font-size: 22px;padding-bottom: 0px">
            <v-row style="font-family: Arial;margin-left: 4px; margin-top: 8px;margin-bottom: 8px;align-items: center; justify-content: start; display: flex; font-weight: bold" >
              {{ $t("problem-list") }}
            </v-row>
          </v-col>
          <v-card
              v-if="detailedUser.userData.patientMetadata.diagnose !== ''"
              :color="pdfColor"
              :style="showBorder ? 'background: #F4F4F4;' : '' "
          >
            <v-card-text>
              <div style="text-align: justify; align-items: center">
                <span style="font-family: Arial;" v-html="detailedUser.userData.patientMetadata.diagnose"></span>
              </div>
            </v-card-text>
          </v-card>
          <!--        Home MED.-->
          <v-col style="font-size: 22px;padding-bottom: 0px">
            <v-row style="font-family: Arial;margin-left: 4px; margin-top: 24px;align-items: center; justify-content: start; display: flex; font-weight: bold">
              {{ $t("home-medications") }}
            </v-row>
          </v-col>
          <v-card
              style="margin-bottom: 16px; margin-top: 16px"
              :color="pdfColor"
              :style="showBorder ? 'background: #F4F4F4;' : '' "
          >
            <v-card-text>
              <div style="text-align: justify; align-items: center">
                <span style="font-family: Arial;" v-html="detailedUser.userData.patientMetadata.prescriptions"></span>
              </div>
            </v-card-text>
          </v-card>
          <!--        Allergies-->
          <v-col style="font-size: 22px; padding-bottom: 0px">
            <v-row style="justify-content: start;margin-left: 0px;font-family: Arial; margin-top: 24px;align-items: center; display: flex; font-weight: bold">
              {{ $t("allergies") }}
            </v-row>
          </v-col>
          <v-card
              v-if="detailedUser.userData.patientMetadata.metadataInfo !== null"
              style="margin-top: 16px; margin-bottom: 4px"
              :color="pdfColor"
              :style="showBorder ? 'background: #F4F4F4;' : '' "
          >
            <v-card-text>
              <div style="text-align: justify; align-items: center">
                <span style="font-family: Arial;" v-html="detailedUser.userData.patientMetadata.metadataInfo"></span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--    Notes -->
        <v-col style="
        font-family: Arial;
        display: flex;
        justify-content: start;
        margin-left: 16px;
        padding-bottom: 0px;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        margin-top: 24px;
        margin-bottom: 0px;"
        >{{ $t("medical-notes") }}</v-col>
        <v-col style="padding-top: 0px" :style="showBorder ? '' : ''"  v-if="notesFromRepo" >
          <v-card
              elevation="1"
              style="margin-top: 0px;margin-bottom: 16px;"
              :color="pdfColor"
              :style="showBorder ? 'background: #F4F4F4;' : '' "
              flat
              v-for="(item, i) in notesFromRepo"
              :key="i"
          >
            <v-card-title>
              <v-row style="font-family: Arial;display: flex; justify-content: start;margin-left: 4px;align-items: center; font-size: 20px; font-weight: bold; margin-top: 8px; margin-bottom: 8px">
                {{ item.userData.firstName }} {{ item.userData.lastName }}
              </v-row>
            </v-card-title>
            <v-card-text  style="margin-bottom: 4px;margin-top: 4px; padding-top: 0px">
              <div  style="text-align: justify; align-items: center; margin-left: 8px; margin-right: 8px">
                <span style="font-family: Arial;" v-html="item.noteDescription"></span>
              </div>
              <v-list-item style="padding-right: 0px; padding-left: 0px">
                <v-row style="font-size: 12px">
                  <v-col style="font-family: Arial;display: flex; justify-content: start; align-items: center" v-if="item.appointmentData" > {{ $t("appointment") }}: [{{item.appointmentData?.appointmentDescription}}]</v-col>
                  <v-col style="font-family: Arial; display: flex;justify-content: end; align-items: center; margin-top: 24px" > {{ item.noteTimestamp | dateformat("timestamp_med", timeLocalization, item.userData.userTimezone) }}</v-col>
                </v-row>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-row>
        </v-row>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { vitalCheckLogo, allergies, calender, homemed, doctorblack, email, height, location, phone, weight, perscription } from '@/assets/';
import jsPDF from 'jspdf';
import { formatterFilter } from '@/utils/luxon-formater';
import { fromTo } from '@/utils/unit-converter';
// import googleLineNew from '@/views/Client/components/GoogleLineNew';
export default {
	components: {
		// googleLineNew,
	},
	props: ['dialog', 'detailedUser', 'googleChartData', 'vitalsPDF'],
	data () {
		return {
			hospitals: [],
			chartData2: [
				['Month', 'Value One', 'Value Two'],
				['2004/05', 165, 320],
				['2004/05', 165, 320],
			],
			logo: vitalCheckLogo,
			allergies: allergies,
			calender: calender,
			homemed: homemed,
			email: email,
			height: height,
			location: location,
			perscription: perscription,
			phones: phone,
			weight: weight,
			doctorblack: doctorblack,
			pdfColor: '#f6f6f6',
			showBorder: false,
			timeLocalization: this.$i18n.locale,
			// doctor_black: doctorBlack,
		};
	},
	computed: {
		...mapState({
			authUserData: (state) => state.authentication.userData,
			notesFromRepo: (state) => state.appointments.notes,
			hospitalData: (state) => state.hospitals.hospitals,
		}),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
		profileImage () {
			return this.detailedUser?.userProfileImage;
		},
		returnimage () {
			if (this.detailedUser?.userProfileImage) {
				return 'data:image/jpeg;base64,' + this.profileImage;
			} else { return 'https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png'; }
		},
		vitalsPdf () {
			return this.vitalsPDF;
		},
		// headers () {
		// 	var headers = [];
		// 	headers = [
		// 		{
		// 			text: this.$t('problem-list'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 		{
		// 			text: this.$t('home-medications'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 		{
		// 			text: this.$t('allergies'),
		// 			sortable: false,
		// 			value: 'actions',
		// 		},
		// 	];
		// 	return headers;
		// },
	},
	async mounted () {
		await this.getHospitals();
		this.detailedUser.userData.patientMetadata.diagnose = this.detailedUser.userData.patientDiagnosisList.map(diagnosis => this.formatDiagnosis(diagnosis)).join('<br>');
	},
	methods: {
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				if (this.hospitalData !== null) {
					this.hospitals = this.hospitalData;
				} else this.hospitals = [];
			});
		},
		formatDiagnosis (diagnosis) {
			const timestamp = formatterFilter(new Date(diagnosis.diagnoseTimestampUTC).toISOString(), 'datetime_med', this.timeLocalization, this.authUserData.userTimezone);
			return `${diagnosis.diagnosisCode} - ${diagnosis.diagnoseDescription} ${this.$t('created-at')}: ${timestamp} ${this.$t('created-by')}: ${diagnosis.staffFullname}`;
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		downloadPDF (firstName, lastName) {
			this.pdfColor = '#FFFFFF';
			this.showBorder = true;
			const fileName = 'Patient vital ' + firstName + ' ' + lastName + '.pdf';

			// eslint-disable-next-line new-cap
			var doc = new jsPDF('p', 'mm', [297, 210], true);
			var elementHTML = document.querySelector('#contentToPrint');
			doc.html(elementHTML, {
				callback: function (doc) {
					doc.save(fileName);
				},
				margin: [5, 5, 5, 5],
				autoPaging: 'text',
				x: 0,
				y: 3,
				width: 200, // target width in the PDF document
				windowWidth: 900, // window width in CSS pixels
			});
			// setTimeout(function () {
			setTimeout(() => {
				this.pdfColor = '#F6F6F6';
				this.showBorder = false;
			}, 0.1);
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
	},
};
</script>
<style>
</style>
