	<template>
		<GChart
		ref="gchart"
		type="LineChart"
		:data="chartData"
		:settings="{ packages: ['line'] }"
		:options="options"
		:createChart="(el, google) => new google.charts.Line(el)"
		:events="chartEvents"
		@ready="onChartReady"
		/>
	</template>

<script>
export default {
	props: ['data', 'googleChartData', 'maxValues', 'minValues', 'units'],
	data () {
		return {
			chartData2: [['Month', 'Value One', 'Value Two'], ['2004/05', 165, 320]], // testing purpose
			chartEvents: {
				select: () => {
				// on selection do something
				},
			},
		};
	},
	computed: {
		chartData () {
			return this.googleChartData;
		},
		options () {
			const chartOptions = {
				chart: {
					title: '',
					seriesType: 'bars',
					series: { 1: { type: 'line', curveType: 'polynomial' } }, // Set curveType to 'polynomial' for polynomial interpolation
				},
				axes: {
					x: { 0: { side: 'bottom' } },
				},
				bars: 'vertical',
				height: 500,
				elements: {
					line: {
						cubicInterpolationMode: 'default',
					},
				},
			};
			return chartOptions;
		},
	},
	methods: {
		onChartReady (chart, google) {
			// do something
		},
	},
};
</script>
