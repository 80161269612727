<template>
  <v-dialog
      v-model="dialog"
      width="1500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      style="background-color: blue; border-radius: 30px !important"
  >
    <v-container>
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{$t('assessments-reports')}}</span>
          <v-spacer />
          <v-icon small color="primary" @click=closeDialog>mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text style="overflow-y: auto" class="elevation-0 mt-2">
          <v-expansion-panels>
            <v-expansion-panel v-for="(item) in surveyPatientReports" :key="item.surveyTemplateId" @click="getTemplate(item)">
              <v-expansion-panel-header @click="toggleCard(item)" style="padding:0px;">
                <v-row>
                  <v-col cols="11" class="d-flex align-center">
                    <p class="ml-2 mb-n1 font-weight-bold" style="color: black;"> {{ item.templateName }}</p>
                  </v-col>
                  <v-col cols="1">
                  </v-col>

                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table
                    hide-default-footer
                    hide-default-header
                    dense
                    :items="selectedTemplate"
                    :expanded.sync="expanded"
                    :single-expand="true"
                >
                  <template v-slot:item="{ item, isOpen, group }">
                    <tr @click="expand(item)">
                      <td class="d-flex">
                        <p class="mt-1 ml-2">{{$t('assessment')}} : {{ item.templateName }} ::: [ Recurrence: {{ surveyRecurrencesRule.find(x => x.lowValue == item.recurrenceRuleForSurvey)?.shortDescription }}, {{ $t('starting-from') }} {{ item.startDate }} ]</p>
                      </td>
                      <td>
                        <v-row>
                          <v-col cols="5"></v-col>
                          <v-col cols="7" class="d-flex justify-end">
                            <v-icon color="primary" @click.stop="editSurveyTemplate(item)" class="mr-3">mdi-pencil</v-icon>
                            <v-btn
                                style="margin-top:5px; margin-right:10px;"
                                @click.stop="setConfirmationData(item)"
                                color="primary"
                                x-small
                            >
                              {{item.status === 1 ? $t('disable') : $t('enable')}}
                            </v-btn>
                            <v-btn small icon :ref="group" :data-open="isOpen">
                              <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                              <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <!-- Your existing content for the expanded panel here -->
                    <tr v-for="(recurrence, index) in item.surveyRecurrencesList" :key="index" @click="openDialog(recurrence)">
                      <td>
                        <div style="display:flex; justify-content: space-between">
                          <div><strong class="ml-10">{{$t('date')}}:</strong> {{ recurrence.submitAtDate }}</div>
                        </div>
                      </td>
                      <td class="text-right d-flex justify-end">
                        <div class="d-flex align-center" style="margin-right:20px;"><strong>{{recurrence.isCompleted ? $t('assessment-completed') : $t('assessment-not-completed')}}</strong>
                          <v-icon :color="recurrence.isCompleted ? 'green' : 'red'">
                            {{ recurrence.isCompleted ? 'mdi-check' : 'mdi-close' }}
                          </v-icon>
                        </div>
                        <v-icon color="primary" style="cursor:pointer">mdi-eye</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="showConfirmationDialog" max-width="400">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{$t('confirmation-dialog')}}</span>
          <v-spacer />
          <v-icon small color="primary" @click="cancelConfirmation">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text color="primary" class="mt-4">
          <v-form>
            {{ confirmationMessage }}
          </v-form>
        </v-card-text>

        <v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
          <span>
              <v-btn class="table-create-button" @click="confirmChangeStatus"><span>{{ buttonLabel }}</span></v-btn>
            </span>
        </v-row>
      </v-card>
    </v-dialog>
    <MedicalHealthSurveys v-if="viewDialog" style="width: 100%;" :dialog="viewDialog" @close="viewDialog = false" :item="surveyTemplateDetails" ></MedicalHealthSurveys>
    <PHQ9ReportDialog v-if="viewPHQ9Dialog" style="width: 100%;" :dialog="viewPHQ9Dialog" @close="viewPHQ9Dialog = false" :item="surveyTemplateDetails" ></PHQ9ReportDialog>
    <EditMedicalHealthAssigne :dialog="edit" @close="edit = false"></EditMedicalHealthAssigne>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import EditMedicalHealthAssigne from '@/views/dialogs/EditMedicalHealthAssigne.vue';
import PHQ9ReportDialog from '@/views/dialogs/PHQ9ReportDialog.vue';
import MedicalHealthSurveys from '@/views/dialogs/MedicalHealthSurveys.vue';

export default {
	components: { MedicalHealthSurveys, PHQ9ReportDialog, EditMedicalHealthAssigne },
	props: ['dialog', 'patientData', 'surveyPatientReports'],
	data () {
		return {
			deleteStatus: 0,
			surveyTemplateDetails: [],
			expanded: [],
			expandSurvey: false,
			selectedTemplate: [],
			edit: false,
			showConfirmationDialog: false,
			itemToRemove: null,
			expandedItems: [],
			viewPHQ9Dialog: false,
			viewDialog: false,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			verifiers: (state) => state.verifiers.verifiers,
			// surveyPatientReports: (state) => state.mentalhealth.surveyPatientReports,
			surveyRecurrencesRule: (state) => state.verifiers.surveyRecurrencesRule,
		}),
		buttonLabel () {
			return this.deleteStatus === 1 ? this.$t('disable') : this.$t('enable');
		},
		confirmationMessage () {
			const action = this.deleteStatus === 1 ? this.$t('disable') : this.$t('enable');
			return this.$t('confirm-' + action.toLowerCase());
		},
	},
	created () {
		this.getSurveyRecurrenceRules();
		// this.getSurveyReportsForPatient();
	},
	mounted () {
		// this.getSurveyRecurrenceRules();
	},
	watch: {
		dialog (newVal) {
			if (!newVal) {
				this.expandSurvey = false;
			}
		},
	},
	methods: {
		async getSurveyRecurrenceRules () {
			if (this.surveyRecurrencesRule == null) {
				await this.$store.dispatch('verifiers/getVerifiersForSurveyRecurrenceRules');
			}
		},
		setConfirmationData (item) {
			this.deleteStatus = item.status;
			// Set the item to be removed
			this.itemToRemove = item;
			// Show the confirmation dialog
			this.showConfirmationDialog = true;
		},
		openDialog (val) {
			if (val.mentalHealthCategoryId === 27) {
				this.viewPHQ9Dialog = true;
			} else {
				this.viewDialog = true;
			}
			this.surveyTemplateDetails = [val];
		},
		async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		generatedAt (date) {
			return date.slice(0, 10);
		},
		isCardOpen (item) {
			return this.expandedItems.includes(item);
		},
		cancelConfirmation () {
			this.showConfirmationDialog = false;
			this.itemToRemove = null;
		},
		editSurveyTemplate (item) {
			this.$store.dispatch('mentalhealth/SurveysAssignedById', item.id).then(() => {
				this.edit = true;
			});
		},
		getTemplate (item) {
			this.selectedTemplate = item.surveysList;
		},
		// getSurveyReportsForPatient () {
		// 	const filter = localStorage.getItem('FilterItem');
		// 	const patientId = this.patientData.userData.id;
		// 	const obj = {
		// 		PatientId: patientId,
		// 		FilterCase: filter,
		// 		LimitForOccurrences: '100',
		// 	};
		// 	this.$store.dispatch('mentalhealth/getSurveyReportsForPatient', obj);
		// },
		toggleCard () {
			this.expandSurvey = !this.expandSurvey;
		},
		confirmChangeStatus () {
			const item = this.itemToRemove;
			const newStatus = item.status === 1 ? 0 : 1;

			var obj = {
				body: {
					templateName: item.templateName,
					templateDescription: item.templateDescription,
					recurrenceRuleForSurvey: item.recurrenceRuleForSurvey,
					StartDate: item.startDate,
					id: item.id,
					EndDate: item.endDate,
					status: newStatus,
				},
				header: item.id,
			};

			this.$store.dispatch('mentalhealth/assigneMentalHealthEdit', obj).then(() => {
				item.status = newStatus;
				this.showConfirmationDialog = false;
				// this.getSurveyReportsForPatient();
				this.$emit('reloadMonitoringPlan');
				// this.$emit('close');
			});
		},
		closeDialog () {
			this.$emit('close');
		},
	},
};
</script>

<style>
.v-dialog{
  box-shadow:none !important;
}
</style>
