<template>
    <v-dialog
        v-model="dialog"
        width="600"
        class="dialog-style"
    >
        <v-card style="overflow-y: auto;">
            <v-card-title class="secondary py-1" style="height: 40px">
                <span class="dialog-title">More Information</span>
                <v-spacer />
                <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row class="mt-5">
                    <v-col class="col-left-alignment">
                        <span style="color: black; font-size: 18px" class="ml-1">Diagnosis and Prescriptions</span>
                    </v-col>
                </v-row>
                <v-row class="mx-2">
                    <v-col>
                        <v-textarea
                            style="border-radius: 12px"
                            label="Known Diagnosis"
                            :value="patientMetaData.diagnose"
                            readonly
                            :rows="patientMetaData.diagnose ? 6 : 1"
                        />
                    </v-col>
                </v-row>
                <v-row dense class="mx-4">
                    <v-col>
                        <v-textarea
                            style="border-radius: 12px"
                            :rows="patientMetaData.prescriptions ? 6 : 1"
                            label="Presrciptions"
                            :value="patientMetaData.prescriptions"
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-left-alignment">
                        <span style="color: black; font-size: 18px" class="ml-1">Notes</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card height="300px" style="overflow-y: auto;" flat>
                            <div style="background-color: white;">
                                <v-card style="border-radius: 0px" class="pa-3" v-for="(item, i) in myNotes" :key="i" flat>
                                    <div class="d-flex px-1">
                                        <span>{{ item.userData.firstName }} {{ item.userData.lastName }}</span>
                                        <v-spacer />
                                        <span>{{ item.noteTimestamp | dateformat("timestamp_med", timeLocalization, item.userData.userTimezone) }}</span>
                                    </div>
                                    <span class="d-flex align-left px-2" style="font-size: 14px; font-weight: bold;">{{item.noteDescription}}</span>
                                    <div v-if="item.appointmentData" class="col-right-alignment">
                                        <span class="px-2" style="font-style: italic !important;">Appointment: {{ item.appointmentData.appointmentTitle }}</span>
                                    </div>
                                    <div v-if="item.appointmentData" class="col-right-alignment">
                                        <span class="px-2" style="font-style: italic !important;">{{ item.appointmentData.appointmentDateTime | dateformat("timestamp_med", timeLocalization, item.userData.userTimezone) }}</span>
                                    </div>
                                    <v-divider></v-divider>
                                </v-card>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: ['dialog', 'patientMetaData'],
	data () {
		return {
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			notesFromRepo: (state) => state.appointments.notes,
		}),
		myNotes () {
			return this.notesFromRepo;
		},
	},
	async mounted () {
		await this.getAllNotes();
	},
	methods: {
		async getAllNotes () {
			await this.$store.dispatch('appointments/getNotesWithPatient', parseInt(this.$route.params.id));
		},
	},
};
</script>
