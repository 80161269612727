<template>
     <v-card v-if="!loading"  ref="content" :class="cardClass" style="border-radius: 12px; " :height="cardHeight" :max-height="cardHeight">
              <v-app-bar flat>
                  <v-toolbar-title>{{$t('monitoring')}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                      <v-select
                        class="px-10"
                        value="LAST7"
                        :items="filterCases"
                        item-text="text"
                        item-value="value"
                        filled
                        rounded
                        dense
                        hide-details
                        style="border-radius: 8px; width: 100px; height: 38px; font-size: 14px;"
                        @change="filterData">
                      </v-select>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="isForMentalHealth" icon color="primary" v-on="on" @click="openAssessmentReports()">
                      <v-icon class="primary" color="white" style="border-radius: 4px; cursor: pointer; height: 20px; width: 20px;">mdi-18px mdi-playlist-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('assessments-reports') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="isForMentalHealth" icon color="primary" v-on="on" @click="openAssignDialog()">
                      <v-icon class="primary" color="white" style="border-radius: 4px; cursor: pointer; height: 20px; width: 20px;">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('assignMentalHealthAssessment')}}</span>
                </v-tooltip>
                      <v-btn icon color="primary" @click="showPDFFunction">
                        <v-icon class="mx-2" color="primary" style="cursor: pointer; height: 16px; width: 16px; ">mdi-eye</v-icon>
                      </v-btn>
                      <v-btn icon color="primary" @click="medicalReports">
                        <v-icon medium>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                      <v-btn icon color="primary" @click="toggleFullScreen">
                          <v-icon large>{{ fullScreenIcon }}</v-icon>
                      </v-btn>
              </v-app-bar>
              <v-progress-linear v-if="loaderForAssessments" indeterminate></v-progress-linear>
              <v-card style="overflow-y: auto" :height="cardHeight-70" class="elevation-0">
                <div v-for="item in surveyPatientReports" :key="item.id">
                      <RenderAssessmentData v-if="detailedUser.userData.patientMetadata.mentalHealth" :item="item" @reloadMonitoringPlan="getSurveyReportsFromPatient"/>
                </div>
                <div v-if=" assignedMedicalDevices !== undefined && assignedMedicalDevices.length === 0 && surveyPatientReports.length === 0" class="pa-0 " style="backgroundcolor: transparent; border: 1px #E8E8E8; border-radius: 5px; line-height: 800px">
                  <span class="card_color--text pa-2" depressed outlined style=" border: solid #E8E8E8; background-color: transparent !important; border-radius: 8px;">{{ $t("thisUserHasNoDevices") }}</span>
                </div>
                      <RenderDeviceData v-for="(item, i) in assignedMedicalDevices" :key="i" :item="item" :idealWeight="idealWeight" :dates="dates" @show="showItem(item)" @hide="hideItem(item)"/>
              </v-card>
                <div v-if="assignedMedicalDevices === undefined" class="pa-6" style=" background-color: transparent; border: 1px dashed #E8E8E8; border-radius: 5px;">
                  <span class="card_color--text pa-2" depressed outlined style="border: solid #E8E8E8; background-color: transparent !important; border-radius: 8px;">{{ $t("thisUserHasNoHub") }}</span>
              </div>
       <show-notes-dialog v-if="showPDF" :dialog="showPDF" :vitalsPDF="exportPDF" :detailedUser="detailedUser" @close="showPDFFunction"></show-notes-dialog>
    <medicalReportsPDF v-if="medicalPDF" :detailedUser="detailedUser" :assignedMedicalDevices="assignedMedicalDevices" :dialog="medicalPDF" @close="medicalReports"></medicalReportsPDF>
       <assign-mental-health-template  @close="assigneMedicalTeamFlag=false" :dialog="assigneMedicalTeamFlag" :patientData="detailedUser" :path="'PatientDetailsCard'" @reloadMonitoringPlan="getSurveyReportsFromPatient"></assign-mental-health-template>
       <assessment-reports  @close="assessmentReports=false" :dialog="assessmentReports" :patientData="detailedUser" :surveyPatientReports="surveyPatientReports" @reloadMonitoringPlan="getSurveyReportsFromPatient"></assessment-reports>

     </v-card>
</template>
<script>
/* eslint-disable no-unused-vars */
import autoTable from 'jspdf-autotable';
import { formatterFilter } from '@/utils/luxon-formater';
import { vitalCheckLogo } from '@/assets/';
import { mapState, mapGetters } from 'vuex';
import RenderDeviceData from './RenderDeviceData.vue';
import RenderAssessmentData from '@/views/Client/partials/no-auth/RenderAssessmentData.vue';
import jsPDF from 'jspdf';
import medicalReportsPDF from '@/views/dialogs/MedicalReportsPDF';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import ShowNotesDialog from '@/views/dialogs/ShowNotesDialog';
import AssignMentalHealthTemplate from '@/views/dialogs/AssignMentalHealthTemplate.vue';
import AssessmentReports from '@/views/dialogs/AssessmentReports.vue';
export default {
	props: ['dialog'],
	components: {
		AssessmentReports,
		AssignMentalHealthTemplate,
		RenderDeviceData,
		RenderAssessmentData,
		medicalReportsPDF,
		ShowNotesDialog,
	},
	data () {
		return {
			loaderForAssessments: true,
			edit: false,
			assigneMedicalTeamFlag: false,
			assessmentReports: false,
			logo: vitalCheckLogo,
			dateData: [],
			keys: [],
			allDates: [],
			dates: ['2021-09-01', '2022-01-01'],
			selectedItem: 1,
			groups: [],
			allData: [],
			loading: true,
			filterCases: [
				{ text: this.$t('today'), value: 'TODAY' },
				{ text: this.$t('last-7-days'), value: 'LAST7' },
				{ text: this.$t('last-14-days'), value: 'LAST14' },
				{ text: this.$t('last-30-days'), value: 'LAST30' },
				{ text: this.$t('all'), value: 'ALL' },
			],
			hide: {
				temp: false,
				oxi: false,
				blood: false,
				glu: false,
				chol: false,
				scale: false,
			},
			items: [{ hidden: true }, { hidden: true }],
			showPDF: false,
			medicalPDF: false,
			oximeterTooltip: this.$t('oximeterIconTooltip'),
			cholesterolTooltip: this.$t('cholesterolMeterIconTooltip'),
			thermometerTooltip: this.$t('thermometerIconTooltip'),
			scaleTooltip: this.$t('scaleIconTooltip'),
			gluclosemeterTooltip: this.$t('glucoseMeterIconTooltip'),
			bloodsugarmeterTooltip: this.$t('bloodSugarMeterIconTooltip'),
			type: null,
			change: false,
			timeLocalization: this.$i18n.locale,
			filter: 'LAST7',
		};
	},
	computed: {
		...mapState({
			surveyPatientDetails: (state) => state.mentalhealth.surveyPatientDetails,
			bloodPresure: (state) => state.medicaldevice.bloodPresure,
			hubs: (state) => state.nosessionhubs.hubs,
			detailedUser: (state) => state.users.detailedUser,
			surveyPatientReports: (state) => state.mentalhealth.surveyPatientReports,

		}),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
		fullScreenIcon () {
			return this.type === 'vitals' ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
		},
		cardClass () {
			if (this.change) {
				return 'elevation-0 mt-n10';
			}
			return 'elevation-0 mt-n3';
		},
		isForMentalHealth () {
			return this.detailedUser?.userData?.patientMetadata?.mentalHealth;
		},
		cardHeight () {
			if (this.type !== null) {
				return (window.innerHeight) - 100;
			}
			return (window.innerHeight) / 2;
		},
		dateRangeText () {
			return this.dates[0] + ' - ' + this.dates[1];
		},
		idealWeight () {
			var weight = 0;
			if (this.detailedUser.userData.gender === 'M') {
				weight =
          50 +
          0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
			} else {
				weight =
          45.5 +
          0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
			}
			return weight;
		},
		identifier () {
			return this.hubs.hubIdentifier;
		},
		assignedMedicalDevices () {
			return this.hubs.assignedMedicalDevicesList?.map((item) => ({
				deviceDefaultValuesJson: item.deviceDefaultValuesJson,
				deviceType: item.deviceType,
				measurementsData: item.measurementsData,
				deviceName: item.deviceName,
				deviceNameEsp: item.deviceNameEsp,
				hide: this.getDeviceStatus(item.deviceType),
			}));
		},
		deviceName () {
			return this.bloodPresure.deviceName;
		},
	},
	mounted () {
		this.getSurveyReportsFromPatient();
		this.getHubsData();
	},
	methods: {
		openAssignDialog () {
			this.assigneMedicalTeamFlag = true;
		},
		openAssessmentReports () {
			this.assessmentReports = true;
		},
		// userDetails (id) {
		// 	const userId = id || this.detailedUser.userData.id;
		// 	this.$store.dispatch('mentalhealth/getSurveyForPatient', userId);
		// },
		medicalReports () {
			this.medicalPDF = !this.medicalPDF;
		},
		showPDFFunction () {
			this.showPDF = !this.showPDF;
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		async filterData (item) {
			this.$emit('filter-changed', item);
			const data = {
				id: this.detailedUser.userData.id,
				filter: item,
			};
			this.filter = item;
			await this.getHubsData(data);
			await this.reloadSurveyReportsForPatient(item);
			localStorage.setItem('FilterItem', item);
		},
		reloadSurveyReportsForPatient (item) {
			const patientId = this.detailedUser.userData.id;
			const obj = {
				PatientId: patientId,
				FilterCase: item,
				LimitForOccurrences: '100',
			};
			this.$store.dispatch('mentalhealth/getSurveyReportsForPatient', obj);
		},
		getSurveyReportsFromPatient () {
			const patientId = this.detailedUser.userData.id;
			const obj = {
				PatientId: patientId,
				FilterCase: this.filter,
				LimitForOccurrences: '100',
			};
			this.$store.dispatch('mentalhealth/getSurveyReportsForPatient', obj)
				.then(() => {
					this.loaderForAssessments = false;
				});
		},
		handleDateChange () {
			if (this.dates[0] === undefined || this.dates[1] === undefined) {
				return;
			}
			const startDate = new Date(this.dates[0]);
			const endDate = new Date(this.dates[1]);
			if (startDate < endDate) {
				return;
			}
			this.dates = [
				endDate.toISOString().slice(0, 10),
				startDate.toISOString().slice(0, 10),
			];
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		hideItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = true;
				break;
			case 2:
				this.hide.oxi = true;
				break;
			case 3:
				this.hide.blood = true;
				break;
			case 5:
				this.hide.scale = true;
				break;
			case 6:
				this.hide.glu = true;
				break;
			case 7:
				this.hide.chol = true;
				break;
			}
		},
		showItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = false;
				break;
			case 2:
				this.hide.oxi = false;
				break;
			case 3:
				this.hide.blood = false;
				break;
			case 5:
				this.hide.scale = false;
				break;
			case 6:
				this.hide.glu = false;
				break;
			case 7:
				this.hide.chol = false;
				break;
			}
		},
		getDeviceStatus (i) {
			var t = null;
			switch (i) {
			case 1:
				t = this.hide.temp;
				break;
			case 2:
				t = this.hide.oxi;
				break;
			case 3:
				t = this.hide.blood;
				break;
			case 5:
				t = this.hide.scale;
				break;
			case 6:
				t = this.hide.glu;
				break;
			case 7:
				t = this.hide.chol;
				break;
			}
			return t;
		},
		async getUserById (data) {
			await this.$store.dispatch('users/getUserById', data);
			this.loading = false;
		},
		async getHubsData () {
			const data = {
				id: this.detailedUser.userData.id,
				filter: this.filter,
			};
			await this.$store.dispatch('nosessionhubs/getHubsData', data);
			this.loading = false;
		},
		toggleFullScreen () {
			this.change = !this.change;
			if (this.change) {
				this.type = 'vitals';
			} else {
				this.type = null;
			}
			this.$emit('fullscreen', this.type);
		},
		exportPDF () {
			var isFirstMasurementExported = false;
			// eslint-disable-next-line new-cap
			var doc = new jsPDF('p', 'pt');
			var currentHeight = 15;
			var imgData = new Image();
			imgData.src = this.logo;
			var colorGray = '#808080';
			var black = '#000000';
			var pdfConfig = {
				headerTextSize: 14,
				labelTextSize: 12,
				fieldTextSize: 10,
				lineHeight: 6,
				subLineHeight: 12,
			};
			// var elementHTML =
			var docWidth = doc.internal.pageSize.width;
			var docOffsetHeight = 200;
			var docOffsetBlankPage = 10;
			doc.setFontSize(14);
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser.userData?.firstName + this.detailedUser.userData?.lastName);
			// currentHeight += pdfConfig.subLineHeight;
			doc.text(docWidth - 41, currentHeight, this.$t('doctor'), 'right');
			currentHeight += pdfConfig.subLineHeight;
			doc.setTextColor(black);
			doc.setTextColor(colorGray);
			doc.setFontSize(pdfConfig.fieldTextSize);
			doc.text(41, currentHeight, this.detailedUser.userData?.address);
			doc.text(docWidth - 41, currentHeight, this.detailedUser.medicalTeamData[0]?.primaryDoctorFullName, 'right');
			currentHeight += pdfConfig.subLineHeight;
			doc.setTextColor(colorGray);
			doc.setFontSize(pdfConfig.fieldTextSize);
			doc.text(41, currentHeight, this.detailedUser.medicalTeamData[0]?.primaryDoctorFullName);
			doc.text(docWidth - 41, currentHeight, this.detailedUser.medicalTeamData[0]?.hospitalName, 'right');
			doc.setFontSize(pdfConfig.fieldTextSize);
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.phone(this.detailedUser.userData?.phone));
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser.userData?.email);

			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber ? this.detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber : '');
			// doc.text(docWidth - 41, currentHeight, 'Invoice date', 'right');

			// doc.line(41, currentHeight, docWidth - 41, currentHeight);
			currentHeight += pdfConfig.subLineHeight;
			currentHeight += pdfConfig.subLineHeight;
			const temperatureBody = [];
			const oximeterBody = [];
			const bpmBody = [];
			const scaleBody = [];
			const glucoseBody = [];
			const cholesterolBody = [];
			const temperatureDate = [];
			const oximeterDate = [];
			const bpmDate = [];
			const scaleDate = [];
			const glucoseDate = [];
			const cholesterolDate = [];
			this.assignedMedicalDevices.filter(f => {
				if (f.measurementsData.length) {
					const measurements = f.measurementsData;
					measurements.forEach(element => {
						if (JSON.parse(element.measuredDataJson).Temperature_C !== undefined && JSON.parse(element.measuredDataJson).Temperature_F !== undefined) {
							temperatureBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).Temperature_C, JSON.parse(element.measuredDataJson).Temperature_F]);
							temperatureDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).Spo2 !== undefined && JSON.parse(element.measuredDataJson).BPM !== undefined) {
							oximeterBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), (JSON.parse(element.measuredDataJson).Spo2).toFixed(2), (JSON.parse(element.measuredDataJson).BPM).toFixed(2), (JSON.parse(element.measuredDataJson).PI).toFixed(2)]);
							oximeterDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).Sys !== undefined && JSON.parse(element.measuredDataJson).Dias !== undefined) {
							bpmBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).Sys, JSON.parse(element.measuredDataJson).Dias, JSON.parse(element.measuredDataJson).Pul]);
							bpmDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).weight_lbs !== undefined && JSON.parse(element.measuredDataJson).weight_kg !== undefined) {
							scaleBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).weight_lbs, JSON.parse(element.measuredDataJson).weight_kg]);
							scaleDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).glucose_mgL !== undefined && JSON.parse(element.measuredDataJson).glucose_mmolL !== undefined && JSON.parse(element.measuredDataJson).glucose_mgL !== 0 && JSON.parse(element.measuredDataJson).glucose_mmolL !== 0) {
							glucoseBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).glucose_mgL, JSON.parse(element.measuredDataJson).glucose_mmolL]);
							glucoseDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).cholesterol_mgL !== undefined && JSON.parse(element.measuredDataJson).cholesterol_mmolL !== undefined) {
							cholesterolBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).cholesterol_mgL, JSON.parse(element.measuredDataJson).cholesterol_mmolL]);
							cholesterolDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
					});
				}
			});
			// this.assignedMedicalDevices.filter(f => {
			// 	switch (f.deviceType) {
			// 	case 1:
			// 		var tableConfig1;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig1 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('temperature') + ' C', this.$t('temperature') + ' F']],
			// 				body: temperatureBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig1 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('temperature') + ' C', this.$t('temperature') + ' F']],
			// 				body: temperatureBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (temperatureBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('temperature') + this.$t('from') + temperatureDate[0] + this.$t('to') + temperatureDate[temperatureDate.length - 1]);
			// 			autoTable(doc, tableConfig1);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	case 2:
			// 		var tableConfig2;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig2 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Spo2', 'BPM', 'PI']],
			// 				body: oximeterBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig2 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Spo2', 'BPM', 'PI']],
			// 				body: oximeterBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (oximeterBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('oximeter') + this.$t('from') + oximeterDate[0] + this.$t('to') + oximeterDate[oximeterDate.length - 1]);
			// 			autoTable(doc, tableConfig2);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	case 3:
			// 		var tableConfig3;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig3 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Sys', 'Dias', 'Pul']],
			// 				body: bpmBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig3 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Sys', 'Dias', 'Pul']],
			// 				body: bpmBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (bpmBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('blood-pressure') + this.$t('from') + bpmDate[0] + this.$t('to') + bpmDate[bpmDate.length - 1]);
			// 			autoTable(doc, tableConfig3);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	case 5:
			// 		var tableConfig4;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig4 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Lbs', 'Kg']],
			// 				body: scaleBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig4 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), 'Lbs', 'Kg']],
			// 				body: scaleBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (scaleBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('weight-scale') + this.$t('from') + scaleDate[0] + this.$t('to') + scaleDate[scaleDate.length - 1]);
			// 			autoTable(doc, tableConfig4);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	case 6:
			// 		var tableConfig5;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig5 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('glucose') + ' mgl', this.$t('glucose') + ' mmol']],
			// 				body: glucoseBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig5 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('glucose') + ' mgl', this.$t('glucose') + ' mmol']],
			// 				body: glucoseBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (glucoseBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('glucose') + this.$t('from') + glucoseDate[0] + this.$t('to') + glucoseDate[glucoseDate.length - 1]);
			// 			autoTable(doc, tableConfig5);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	case 7:
			// 		var tableConfig6;
			// 		if (!isFirstMasurementExported) {
			// 			tableConfig6 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('cholesterol') + ' mgl', this.$t('cholesterol') + ' mmol']],
			// 				body: cholesterolBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		} else {
			// 			tableConfig6 = {
			// 				startY: 120,
			// 				headStyles: { fillColor: [102, 0, 255] },
			// 				alternateRowStyles: { fillColor: [224, 224, 224] },
			// 				head: [[this.$t('date'), this.$t('cholesterol') + ' mgl', this.$t('cholesterol') + ' mmol']],
			// 				body: cholesterolBody,
			// 				rowPageBreak: 'auto',
			// 			};
			// 		}
			// 		if (cholesterolBody.length > null) {
			// 			doc.setFontSize(14);
			// 			doc.setTextColor(black);
			// 			doc.text(41, currentHeight, this.$t('cholesterol') + this.$t('from') + cholesterolDate[0] + this.$t('to') + cholesterolDate[cholesterolDate.length - 1]);
			// 			autoTable(doc, tableConfig6);
			// 			isFirstMasurementExported = true;
			// 			doc.addPage();
			// 		}
			// 		break;
			// 	default:
			// 		break;
			// 	}
			// });
			var pageCount = doc.internal.getNumberOfPages();
			doc.deletePage(pageCount);
			// if (temperatureBody.length > 0 || oximeterBody.length || bpmBody.length || scaleBody.length || glucoseBody.length || cholesterolBody.length) {
			doc.save(`Patient ${this.detailedUser.userData?.firstName} ${this.detailedUser.userData?.lastName}.pdf`);
			// }
		},
	},
};
</script>
