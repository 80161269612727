<template>
    <v-card :loading="loading"  class="rounded-t-xl mt-0 pt-0" flat style="border-radius: 12px; border-top: 3px white solid;border-left: 3px white solid ;border-right: 3px white solid;width: 100%;">
          <v-tabs
              :show-arrows="true"
              v-model="tab"
              background-color="#E8E8E8"
              color="primary"
              grow
              dense
              class="rounded-t-xl"
              hide-slider
              style=""
              height="30px"
              centered dark icons-and-text
              active-class="background-color: deep-purple accent-4 ma-0"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab @click="change('all')" class="pa-3 rounded-tl-xl" >
              <v-img :src="allNotifications" height="18px" width="18px"></v-img>
            </v-tab>

            <v-tab @click="change('abnormal')" class="pa-3"  style="">
              <v-img :src="abnormal" height="18px" width="18px"></v-img>
            </v-tab>

            <v-tab @click="change('missed')" class="pa-3 rounded-tr-xl"  >
              <v-img :src="missedNotificationColor" height="18px" width="18px"></v-img>
            </v-tab>
          </v-tabs>
      <v-row class="mt-0" dense>
        <v-col cols="8" class="d-flex align-center justify-start  my-0 pl-5 " style="font-size: 15px;color:#6600FF">
					{{description}}</v-col>
        <v-col cols="4">
          <v-text-field
              v-model="searchNotification"
              append-icon="mdi-magnify"
              single-line
              hide-details
              :label="$t('search')"
              dense
              background-color="#E8E8E8"
              rounded
              class="table-search-button"
              height="25"
          />
        </v-col>
      </v-row>
        <v-row class="my-0">
            <v-col   cols="12" lg="12" md="12" sm="12">
              <v-data-table
                  dense
                  fixed-header
                  :search="searchNotification"
                  :headers="localizedHeaders"
                  :height="height"
                  :no-data-text="$t('no-data-available')"
                  :loading-text="$t('loading')"
                  :items="notifications"
                  hide-default-footer
                  :items-per-page="-1"
                  class="elevation-3"
                  :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
              >
                <template v-slot:[`item.NotificationTimestamp`]="{ item }">
                  {{ item.NotificationTimestamp | dateformat('timestamp_med', timeLocalization, null) }}
                </template>
              </v-data-table>
            </v-col>
        </v-row>
        <AbnormalNotificationDialog v-if="abnormalNotificationDialog" :dialog="abnormalNotificationDialog" :item="notificationToProcess" :type="3" @false="abnormalProcessed" @close="abnormalNotificationDialog = false" />
    </v-card>
</template>
<script>
import { mapState } from 'vuex';
import { allNotification, abnormalNotification, missedNotificationWhite, messagesNotificationWhite } from '@/assets/';
import AbnormalNotificationDialog from '../../dialogs/AbnormalNotificationDialog';
export default {
	components: {
		AbnormalNotificationDialog,
	},
	data () {
		return {
			allNotifications: allNotification,
			abnormal: abnormalNotification,
			missedNotificationColor: missedNotificationWhite,
			messages: messagesNotificationWhite,
			loading: false,
			description: this.$t('notifications'),
			tab: null,
			items: [],
			selectedNotification: null,
			notificationToProcess: null,
			abnormalNotificationDialog: false,
			searchNotification: null,
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			notificationsFromRepo: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
			reminders: (state) => state.notifications.reminders,
			missed: (state) => state.notifications.missMeasurements,
		}),
		localizedHeaders () {
			return [
				{ text: this.$t('messages'), value: this.language },
				{ text: this.$t('time'), value: 'NotificationTimestamp' },
			];
		},
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return 450;
			case 'sm':
				return 400;
			case 'md':
				return window.innerHeight - 550;
			case 'lg':
				return (window.innerHeight / 2) - 240;
			case 'xl':

				return (window.innerHeight / 2) - 238;
			default:
				return 0;
			}
		},
		notifications () {
			if (this.tab === 0) {
				return this.notificationsFromRepo.filter(
					(p) =>
						p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1,
				);
			}
			if (this.tab === 1) return this.abnormalNotifications;
			return this.missed;
		},
		language () {
			return this.$i18n.locale === 'en' ? 'MessageToSend' : 'MessageToSendEsp';
		},
	},
	watch: {
		selectedNotification (val) {
			const item = this.notifications.filter(n => { return n.NotificationId === val; });
			if (item) {
				this.notificationToProcess = item[0];
			}
		},
		notificationToProcess (val) {
			if (val && val.NotificationTypeId === 3) {
				this.abnormalNotificationDialog = true;
			}
		},
		// searchNotification (e) {
		// 	const itemsCopy = this.items;
		// 	if (e === '') {
		// 		this.items = this.notifications;
		// 		return;
		// 	}
		//
		// 	this.items = itemsCopy.filter(item => {
		// 		return item.MessageToSend.toLowerCase().indexOf(e.toLowerCase()) > -1;
		// 	});
		// },
		searchNotification () {
			const searchTerm = this.search ? this.search.toLowerCase() : '';
			// TODO make sure to filter time
			return this.items.filter(item => {
				return item.MessageToSend.toLowerCase().includes(searchTerm);
			});
		},
	},
	async mounted () {
		await this.initialize();
	},
	methods: {
		async initialize () {
			this.loading = true;
			this.items = this.notificationsFromRepo.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1; });
			this.loading = false;
		},
		change (type) {
			this.loading = true;
			switch (type) {
			case 'all':
				this.items = this.notificationsFromRepo.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId !== 1; });
				this.description = this.$t('notifications');
				break;
			case 'abnormal':
				this.items = this.abnormalNotifications;
				this.description = this.$t('missed');
				break;
			case 'missed':
				this.items = this.missed;
				this.description = this.$t('abnormal');
				break;
			default:
				break;
			}
			this.loading = false;
		},
		abnormalProcessed () {
			this.abnormalNotificationDialog = false;
		},
	},
};
</script>
