<template>
    <v-card :loading="loading" flat style="width: 100%;" >
        <v-card class="mx-1" flat color="#E8E8E8" style="border-radius: 8px;">
          <span class="d-flex align-center justify-space-between">
            <span class="d-flex align-center justify-start" style="font-size: 12px">
				<v-icon class="mx-2" @click="showNotes" :disabled="hasNotes" color="primary" style="cursor: pointer; height: 16px; width: 16px; ">mdi-eye</v-icon>{{$t('notes')}}
            </span>
            <span class="d-flex justify-center align-center ma-1">
				<v-icon @click="process" class="icon primary" color="white" style="border-radius: 4px; cursor: pointer; height: 24px; width: 24px;" >mdi-plus</v-icon>
            </span>
          </span>
        </v-card>
<!--                <v-card class="my-4" :height="height" style="overflow-y: auto;" flat>-->
<!--                        <v-card style="border: solid;  border-color: #E8E8E8; border-width: thin; " class="mx-4 my-1" v-for="(item, i) in myNotes" :key="i" flat>-->
<!--                            <span class="d-flex justify-space-between align-center px-4">-->
<!--								<span></span>-->
<!--                                <span style="font-style: italic; font-size: 12px">{{ item.noteTimestamp | dateformat("timestamp_med", timeLocalization, item.patientData.userTimezone) }}</span>-->
<!--							</span>-->
<!--							<span class="d-flex justify-space-between align-center px-4">-->
<!--								<v-spacer></v-spacer>-->
<!--								<span v-if="item.appointmentData" style="font-style: italic !important; font-size: 12px">Appointment: {{ item.appointmentData.appointmentTitle }}</span>-->
<!--							</span>-->
<!--							&lt;!&ndash; <span class="d-flex justify-space-between align-center px-4">-->
<!--								<v-spacer></v-spacer>-->
<!--								<span v-if="item.appointmentData" style="font-style: italic !important; float: left; font-size: 12px">{{ item.appointmentData.appointmentDateTime | dateformat("timestamp_med", timeLocalization, item.patientData.userTimezone) }}</span>-->
<!--							</span> &ndash;&gt;-->
<!--							<div style="text-align: left;">-->
<!--							<span style="font-size: 14px;" v-html="item.noteDescription"></span>-->
<!--							</div>-->
<!--                        </v-card>-->
<!--                </v-card>-->
        <AbnormalNotificationDialog v-if="abnormalNotificationDialog" :dialog="abnormalNotificationDialog" :patientId="patientId" :item="notificationToProcess" :type="1" :source="'note'" @false="abnormalProcessed" @close="medicalNoteClosed" />
		<ShowNotesDialog v-if="showNotesDialog" :dialog="showNotesDialog" :detailedUser="detailedUser" @close="showNotesDialog = false"></ShowNotesDialog>
    </v-card>
</template>
<script>
import AbnormalNotificationDialog from '@/views/dialogs/AbnormalNotificationDialog.vue';
import { mapState } from 'vuex';
import ShowNotesDialog from '../../dialogs/ShowNotesDialog.vue';
import { fromTo } from '@/utils/unit-converter';

export default {
	components: {
		AbnormalNotificationDialog,
		ShowNotesDialog,
	},
	props: ['dialog', 'detailedUser', 'type'],
	data () {
		return {
			loading: false,
			searchNote: null,
			patientId: null,
			myNotes: null,
			abnormalNotificationDialog: false,
			showNotesDialog: false,
			notificationToProcess: null,
		};
	},
	computed: {
		...mapState({
			notesFromRepo: (state) => state.appointments.notes,
		}),
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return 300;
			case 'sm':
				return 325;
			case 'md':
				return 360;
			case 'lg':
				return (window.innerHeight / 2) - 205;
			case 'xl':
				return (window.innerHeight / 2) - 100;
			default:
				return 0;
			}
		},
		hasNotes () {
			return !this.notesFromRepo?.length;
		},
	},
	watch: {
		searchNote (e) {
			const itemsCopy = this.myNotes;
			if (e === '') {
				this.myNotes = this.notesFromRepo;
				return;
			}

			this.myNotes = itemsCopy.filter(item => {
				return item.noteDescription.toLowerCase().indexOf(e.toLowerCase()) > -1;
			});
		},
	},
	async mounted () {
		await this.getAllNotes();
	},
	methods: {
		async medicalNoteClosed () {
			await this.getAllNotes();
			this.abnormalNotificationDialog = false;
		},
		noteRows (item) {
			if (item.length < 25) return 1;
			if (item.length > 25 && item.length < 50) return 1;
			if (item.length > 50 && item.length < 100) return 2;
			if (item.length > 100 && item.length < 150) return 4;
			if (item.length > 150 && item.length < 200) return 4;
			else return 8;
		},
		async getAllNotes () {
			this.patientId = this.detailedUser.userData.id;
			this.loading = true;
			await this.$store.dispatch('appointments/getNotesWithPatient', parseInt(this.detailedUser?.userData.id));
			this.myNotes = this.notesFromRepo;
			this.loading = false;
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		process () {
			const object = {
				FromUserId: parseInt(this.$route.params.id),
			};
			this.notificationToProcess = object;
			this.abnormalNotificationDialog = true;
		},
		async abnormalProcessed () {
			await this.getAllNotes();
			this.abnormalNotificationDialog = false;
		},
		showNotes () {
			this.showNotesDialog = true;
		},
	},
};
</script>
