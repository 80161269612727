<template>
  <v-row class="fill-height px-4" style="overflow: hidden">
    <v-col class="pt-3" cols="12" xl="4" lg="4" md="12" sm="12">
      <PatientDetailsCard
        :noChat="false"
        :video="false"
        :filterChanged="filterChat"
        :item="items"
        @device-edited="deviceEdited"
		@get-devices="reload += 1"
        v-if="!userLoading && (toggle === 'patientdetails' || toggle === null)"
      />
    </v-col>
    <v-col cols="12" :xl="changeScreen" :lg="changeScreen" :md="changeScreen" :sm="changeScreen">

      <v-row>
			<v-col>
				<RenderDataForPatient v-if="!userLoading && (toggle === 'vitals' || toggle === null)" :key="reload" @fullscreen="toggleFullScreen" @filter-changed="applyFilter"/>
			</v-col>
    </v-row>
      <v-row >
        <v-col>
        <NotificationsCard
            v-if="!notificationsLoading && (toggle === 'notifications' || toggle === null)"
            :items="items"
            :itemsPerPage="items.length"
            :loading="notificationsLoading"
            :userTimeZone="userTimeZone"
            @rerender="getMyNotifications"
        />
        </v-col>
        <PatientDetailsExitConfirmationDialog
		:show-dialog="showPatientDetailsConfirmationDialog"
		@exitPatientFromPatientDetails="exitPatientFromPatientDetails"
		@stayPatientFromPatientDetails="stayPatientFromPatientDetails"
        />
      </v-row>
<!--      <span v-if="rerender > 1 && roleName !== 'HOSPITAL_ADMIN'" class="" style="position: absolute; right: 0; bottom: 0;">-->
<!--        <div class="text-center">-->
<!--    <v-dialog-->
<!--        hide-overlay-->
<!--        v-model="dialog"-->
<!--        width="500"-->
<!--    >-->
<!--          <template v-slot:activator="{ on, attrs}">-->
<!--              <v-badge-->
<!--                  left-->
<!--                  bordered-->
<!--                  color="error"-->
<!--                  :content="notificationNumber"-->
<!--                  :value="notificationNumber"-->
<!--                  overlap-->
<!--              >-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            fab-->
<!--            v-bind="attrs"-->
<!--            v-on="on"-->
<!--            style="height: 40px; width: 40px;"-->
<!--        >-->
<!--          <img height="16px" width="16px" :src="messagesNotificationWhite" />-->
<!--        </v-btn>-->
<!--      </v-badge>-->

<!--      </template>-->
<!--      <v-card style="position: absolute; right: 2%; bottom: auto; height: 200px; width: 400px">-->
<!--        <v-card-text class="ma-0 pa-0">-->
<!--          <Chat v-if="detailedUser && (toggle === 'chat' || toggle === null) " :userToChat="detailedUser.userData" :key="rerender" :filter="filterChat" @close="closeChat"/>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
<!--            </div>-->

<!--          </span>-->
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RenderDataForPatient from './RenderDataForPatient.vue';
import NotificationsCard from '@/views/Client/components/PatientNotifications.vue';
import PatientDetailsExitConfirmationDialog from '../../dialogs/PatientDetailsExitConfirmationDialog.vue';
// import Chat from './Chat/Chat.vue';
import PatientDetailsCard from '../components/PatientDetailsCard.vue';
import { abnormalNotification, allNotification, missedNotificationWhite, messagesNotificationWhite, appointmentsWhite } from '@/assets';
// import GoogleLine from './GoogleLine.vue';

export default {
	components: {
		RenderDataForPatient,
		NotificationsCard,
		PatientDetailsExitConfirmationDialog,
		// Chat,
		// GoogleLine,
		PatientDetailsCard,
	},
	data () {
		return {
			filterChange: false,
			userLoading: true,
			dialog: true,
			display: false,
			chatShow: false,
			notificationNumber: null,
			counter: 0,
			lastCount: null,
			readedNotification: null,
			notificationsLoading: true,
			messagesNotificationWhite: messagesNotificationWhite,
			toggle: null,
			rerender: 1,
			sheet: false,
			reload: null,
			filterChat: 'LAST7',
			showPatientDetailsConfirmationDialog: false,
			timerId: null,
			inactivityTimerId: null,
		};
	},
	async mounted () {
		if (this.$route.params.type === 'endCall') {
			location.reload();
		}
		await this.getUserById();
		await this.getMyNotifications();
		this.rerender += 1;

		// Listen for user activity (e.g., mousemove) to restart the timer
		this.startTimers();
		document.addEventListener('mousemove', this.restartTimers);
		// ---- ROLES -----
		// ID 3 Doctor
		// ID 4 Nurse
		// ID 7 CareGiver
		// ------ For those roles we count the timeTracking ----------
		if ([3, 4, 7].includes(this.authUser.roleData.id)) {
			await this.checkIfThereIsAciveTimeTracking();
		}
	},
	computed: {
		userTimeZone () {
			return this.detailedUser?.userData.userTimezone;
		},
		...mapState({
			authUser: (state) => state.authentication.userData,
			startTimeTrackingInfo: (state) => state.timetracking.startTimeTrackingInfo,
			detailedUser: (state) => state.users.detailedUser,
			filterCase: (state) => state.authentication.filterCase,
			notifications: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
			reminders: (state) => state.notifications.reminders,
			missed: (state) => state.notifications.missMeasurements,
			chatNotifications: (state) => state.notifications.chatNotifications,
			appointmentRequests: (state) => state.notifications.appointmentRequests,
			notificationsWithUser: (state) => state.notifications.notificationsWithUser,
		}),
		...mapGetters({ roleName: 'authentication/getRole' }),
		changeScreen () {
			if (this.toggle === 'vitals') {
				return 12;
			} else {
				switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 12;
				case 'sm': return 12;
				case 'md': return 12;
				case 'lg': return 8;
				case 'xl': return 8;
				default: return 8;
				}
			}
		},
		cardHeight () {
			return (window.innerHeight / 2) - 180;
		},
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return 220;
			case 'sm': return 10;
			case 'md': return window.innerHeight - 400;
			case 'lg': return (window.innerHeight / 2) - 10;
			case 'xl': return (window.innerHeight / 2) - 10;
			default: return 0;
			}
		},
		items () {
			var items = this.roleName === 'DOCTOR'
				? [
					{
						text: 'All Notifications',
						img: allNotification,
						cardColor: '#6600FF',
						read: this.notifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.notifications.length,
					},
					{
						text: 'Messages',
						img: messagesNotificationWhite,
						cardColor: '#3A3A3A',
						read: this.chatNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.chatNotifications.length,
					},
					{
						text: 'Abnormal',
						img: abnormalNotification,
						cardColor: '#D30202',
						read: this.abnormalNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.abnormalNotifications.length,
					},
					{
						text: 'Missed',
						img: missedNotificationWhite,
						cardColor: '#FF931E',
						read: this.missed.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.missed.length,
					},
					{
						text: 'Appointment Requests',
						img: appointmentsWhite,
						cardColor: '#006837',
						read: this.appointmentRequests.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.appointmentRequests.length,
					},
				]
				: [
					{
						text: 'All Notifications',
						img: allNotification,
						cardColor: '#6600FF',
						read: this.notifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.notifications.length,
					},
					{
						text: 'Abnormal',
						img: abnormalNotification,
						cardColor: '#D30202',
						read: this.abnormalNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.abnormalNotifications.length,
					},
					{
						text: 'Missed',
						img: missedNotificationWhite,
						cardColor: '#FF931E',
						read: this.missed.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.missed.length,
					},
					{
						text: 'Messages',
						img: messagesNotificationWhite,
						cardColor: '#3A3A3A',
						read: this.chatNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.chatNotifications.length,
					},
				];
			return items;
		},
	},
	watch: {
		filterChat () {
			this.filterChange = true;
		},
		notificationsWithUser (val) {
			if (this.filterChange) {
				this.readedNotification = this.notificationsWithUser.length - this.lastCount;
				this.notificationNumber = this.lastCount;
				this.filterChange = false;
			} else {
				if (this.dialog) {
					this.notificationNumber = null;
					this.readedNotification = this.notificationsWithUser.length;
				} else {
					this.notificationNumber = this.notificationsWithUser.length - this.readedNotification;
					this.lastCount = this.notificationNumber;
				}
			}
		},
		dialog (val) {
			if (val) {
				this.notificationNumber = null;
				this.readedNotification = this.notificationsWithUser.length;
			}
		},
	},
	beforeRouteEnter (to, from, next) {
		// console.log('Entering Component at:', new Date());
		next(vm => {
			vm.setupBeforeUnloadListener();
		});
	},
	beforeRouteLeave (to, from, next) {
		this.stopTimeTracking(false);
		this.removeBeforeUnloadListener();
		// console.log('Leaving Component at:', new Date());
		next();
	},
	methods: {
		async checkIfThereIsAciveTimeTracking () {
			const getTimeTrackingBody = {
				PatientId: this.detailedUser.userData.id,
			};
			await this.$store.dispatch('timetracking/getNotFinishedWithPatient', getTimeTrackingBody).then(() => {
				if (this.startTimeTrackingInfo.data.length === 0) {
					const startTimeTrackingBody = {
						patientId: this.detailedUser.userData.id,
						visitedPageUrl: 'WEB:' + this.$route.name,
					};
					this.$store.dispatch('timetracking/starttrackingwithpatient', startTimeTrackingBody);
				}
			});
		},
		resetCounterForMessages () {
			this.chatShow = true;
			this.notificationNumber = 0;
		},
		closeChat (val) {
			this.readedNotication = val;
			this.dialog = false;
		},
		deviceEdited () {
			this.getUserById();
		},
		async getUserById () {
			this.userLoading = true;
			await this.$store
				.dispatch('users/getUserById', this.$route.params.id)
				.then(() => {
					this.userLoading = false;
				});
		},
		async getMyNotifications () {
			const object = { filterCase: this.filterChat, patientId: parseInt(this.$route.params.id) };
			this.notificationsLoading = true;
			await this.$store.dispatch('notifications/getNotificationsWithPatient', object).then(() => {
				this.readedNotication = this.notificationsWithUser.length;
			});
			this.notificationsLoading = false;
		},
		toggleFullScreen (type) {
			this.toggle = type;
		},
		applyFilter (item) {
			this.filterChat = item;
		},
		setupBeforeUnloadListener () {
			window.addEventListener('beforeunload', this.handleBeforeUnload);
		},
		removeBeforeUnloadListener () {
			window.removeEventListener('beforeunload', this.handleBeforeUnload);
		},
		handleBeforeUnload (event) {
			// This function will be called before the browser/tab is closed
			// console.log('Browser is closing at:', new Date());
			// You can perform additional actions here, such as sending data to a server.
			// To prompt the user with a confirmation dialog, you can return a string.
			// event.returnValue = 'Are you sure you want to exit Vital Checkups? You are currently monitoring a patient.';
		},
		startTimers () {
			// Set an activity timer for 10 seconds
			this.activityTimerId = setTimeout(this.askToCloseComponent, 300000);
			// Set an inactivity timer for 15 seconds
			this.inactivityTimerId = setTimeout(this.autoCloseComponent, 315000);
		},
		restartTimers () {
			// Clear the existing timers and start new ones
			// console.log('timere reseted');
			clearTimeout(this.activityTimerId);
			clearTimeout(this.inactivityTimerId);
			this.startTimers();
		},
		askToCloseComponent () {
			this.showPatientDetailsConfirmationDialog = true;
			// if (confirm('You are not showing activity. Are you still actively monitoring patient data?')) { // the buttons Yes/No
			// 	this.$router.go(-1); // Navigate back or perform another action when confirmed
			// 	return;
			// }
			// this.restartTimers();
		},
		closeExitConfirmationDialog () {
			this.showPatientDetailsConfirmationDialog = false;
		},
		exitPatientFromPatientDetails () {
			this.showPatientDetailsConfirmationDialog = false;
			this.stopTimeTracking(true);
			// Navigate back or perform another action when confirmed
		},
		stayPatientFromPatientDetails () {
			this.showPatientDetailsConfirmationDialog = false;
			this.restartTimers();
		},
		autoCloseComponent () {
			this.showPatientDetailsConfirmationDialog = false;
			this.stopTimeTracking(true);
			// console.log('auto close');
			// Automatically navigate back after 15 seconds of inactivity
		},
		stopTimeTracking (useRouter) {
			// console.log('this.authUser: ', this.authUser);
			if ([3, 4, 7].includes(this.authUser.roleId)) {
				const getTimeTrackingBody = {
					PatientId: this.detailedUser.userData.id,
				};
				this.$store.dispatch('timetracking/getNotFinishedWithPatient', getTimeTrackingBody).then(() => {
					const stopTimeTrackingBody = {
						trackingId: this.startTimeTrackingInfo.data[0].id,
					};
					this.$store.dispatch('timetracking/stoptrackingwithpatient', stopTimeTrackingBody).then(() => {
						if (useRouter) {
							this.$router.go(-1);
						}
					});
				});
			}
		},
	},
	created () {
		// This hook is called when the component is created
		// console.log('11111111111 ----- Component created at:', new Date());
	},
	beforeDestroy () {
		// This hook is called before the component is destroyed
		this.removeBeforeUnloadListener();
		// console.log('222222222222 ------- Component will be destroyed at:', new Date());
		// Clean up event listener and clear the timers when the component is destroyed
		document.removeEventListener('mousemove', this.restartTimers);
		clearTimeout(this.activityTimerId);
		clearTimeout(this.inactivityTimerId);
	},
};
</script>
