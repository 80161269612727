<template>
  <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      width="1000"
  >
    <v-card-title class="secondary py-1 d-flex justify-space-between">
      <span class="dialog-title" v-if="this.show === 'problemList'">{{ $t("problem-list") }}</span>
      <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
    </v-card-title>
    <v-row class="ma-auto pb-0" style="background-color:white;">
      <v-autocomplete
          @input="handleAutoCompleteInput"
          v-model="selectedVerifier"
          :items="diagnosesCategory"
          :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
          item-value="lowValue"
          hide-details
          filled
          outlined
          dense
          :label="this.$t('main-category-of-diagnosis')"
          style="background-color:#E8E8E8; margin:2px; border-radius:10px;"
          class=""
      >
      </v-autocomplete>
    </v-row>
    <v-row class="ma-auto pt-0" style="background-color:white;">
      <v-col cols="6" class="pa-0">
        <!-- Your search input and data-table here -->
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search_diagnose')"
            single-line
            hide-details
            dense
            background-color="#E8E8E8"
            rounded
            class="table-search-button ma-2"
        />
        <v-data-table
            :headers="headers"
            dense
            fixed-header
			:no-data-text="$t('no-data-available')"
            :items="filteredCategory"
            :items-per-page="-1"
            hide-default-footer
            height="300"
        >
          <!-- ... -->
          <template v-slot:item="{ item }">
            <tr @click="selectedDiagnose(item)">
              <td class="text-left" ><span>{{ item.icd10Code }}</span></td>
              <td class="text-left"><span>{{ item.fullDescription }}</span></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6" class="pa-0">
        <div class="q">
<!--			<v-row>-->
<!--				<v-col>-->
<!--					<vue-editor v-if="show === 'problemList'"-->
<!--						style="background-color: white; height: 50%; margin-bottom: 20px; overflow-y: auto;"-->
<!--						v-model="diagnose"-->
<!--						:editorToolbar="customToolbar"-->
<!--						@selection-change="onSelectionChange"-->
<!--						:editorOptions="editorOptions"-->
<!--					>-->
<!--						&lt;!&ndash; Display selected diagnoses list &ndash;&gt;-->
<!--						<v-list>-->
<!--						<v-list-item v-for="(diagnosis, index) in selectedDiagnosesList" :key="index" class="diagnosis-item">-->
<!--							<v-list-item-content>-->
<!--							<v-list-item-title>{{ diagnosis.value }}</v-list-item-title>-->
<!--							<v-list-item-subtitle>Added at: {{ diagnosis.timestamp }}</v-list-item-subtitle>-->
<!--							</v-list-item-content>-->
<!--						</v-list-item>-->
<!--						</v-list>-->
<!--					</vue-editor>-->
<!--				</v-col>-->
<!--			</v-row>-->
            <v-data-table
                :items="patient.userData.patientDiagnosisList" item-key="id" style="margin-top:42px;"
                :headers="patientHeader"
                dense
                fixed-header
                :no-data-text="$t('no-data-available')"
                :items-per-page="patient.userData.patientDiagnosisList.length"
                hide-default-footer
                height="300"
                sort-by="id"
                sort-desc
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td><span style="margin-right:10px;">{{ item.diagnosisCode }}</span></td>
                  <td class="text-left"><span>{{ item.diagnoseDescription }}</span></td>
                  <td>
                    <v-icon class="mdi mdi-delete" color="primary" @click="removeDiagnose(item)">
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
      </v-col>
    </v-row>
        <div style="float: right; background-color:white;padding:10px;" class="justify-end d-flex px-5">
          <!-- <v-btn class="table-cancel-button " @click="$emit('false')">
            <span class="" color="black--text">{{ $t("cancel") }}</span>
          </v-btn>
          <v-btn
              class="table-create-button mx-2"
              :disabled = saveButtonDisabled
              @click="updatePatientMetaData">
            <span>{{ $t("save") }}</span>
          </v-btn> -->
        </div>
  </v-dialog>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import { mapGetters, mapState } from 'vuex';
// import patientmetadata from '@/data/store/modules/patientmetadata';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	// components: {
	// 	VueEditor,
	// },
	props: ['dialog', 'patient', 'show', 'edit'],
	data () {
		return {
			temp: '0',
			search: '',
			categoryLowValue: '',
			selectedDiagnosesList: [],
			saveButtonDisabled: true,
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4] }],
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
				[{ align: '' },
					{ align: 'center' },
					{ align: 'justify' }],
			],
			editorOptions: {
				modules: {
					toolbar: false,
				},
			},
			isLoading: false,
			prescriptions: '',
			diagnose: '',
			category: [],
			diagnosesCategory: [],
			alergies: '',
			lowValue: '',
			selectedVerifier: '',
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
			categoryDiagnose: (state) => state.patientmetadata.diagnosisByCategory,
		}),
		...mapGetters({
			getDiagnoseCategory: 'patientmetadata/getDiagnoseCategory',
			pageHeight: 'pageHeight',
		}),
		headers () {
			const headers = [
				{ text: 'ICD-10', sortable: false, value: 'ICD_10' },
				{ text: this.$t('diagnose'), sortable: false, value: 'diagnose' },
			];
			return headers;
		},
		patientHeader () {
			const headers = [
				{ text: 'ICD-10', sortable: false, value: 'ICD_10' },
				{ text: this.$t('description'), sortable: false, value: 'description' },
				{ text: '', sortable: false, value: '' },
			];
			return headers;
		},
		filteredCategory () {
			if (!this.search) {
				return this.category; // Return all items if search is empty
			}
			const keyword = this.search.toLowerCase();
			return this.category.filter(item => {
				return (
					item.icd10Code.toLowerCase().includes(keyword) ||
            item.fullDescription.toLowerCase().includes(keyword)
				);
			});
		},
	},
	async mounted () {
		this.diagnose = this.patient.userData.patientMetadata.diagnose;
		this.prescriptions = this.patient.userData.patientMetadata.prescriptions;
		this.alergies = this.patient.userData.patientMetadata.metadataInfo;
		await this.getVerifiersForDomain();
	},
	watch: {
		searchDiagnose (e) {
			const itemsCopy = this.diagnose;
			if (e === '') {
				this.diagnose = this.verifiersFromRepo;
				return;
			}

			this.diagnose = itemsCopy.filter(item => {
				return item.diagnose.toLowerCase().indexOf(e.toLowerCase()) > -1;
			});
		},
	},
	methods: {
		async selectedDiagnose (item) {
			this.saveButtonDisabled = false;
			try {
				// Filter the category array based on either selected fullDescription or icd10Code
				const filteredItems = this.category.filter(category =>
					category.fullDescription === item.fullDescription || category.icd10Code === item.icd10Code,
				);

				if (filteredItems.length > 0) {
					const selectedICD10Code = filteredItems[0].icd10Code;
					const selectedFullDescription = filteredItems[0].fullDescription;

					// Get the current datetime
					const currentDatetime = new Date().toLocaleString(); // Adjust formatting as needed
					//   :label="$t('search_diagnose')"

					// Add the selected diagnosis with datetime to the list array
					const newDiagnosis = `${selectedICD10Code} - ${selectedFullDescription}\n` + this.$t('at') + ': ' + `${currentDatetime}` + ' ' + this.$t('created-by') + ': ' + `${this.userData.firstName}` + ' ' + `${this.userData.lastName}`;// + ${currentDatetime} - $t('created-by') ${this.userData.firstName + ' ' + this.userData.lastName}`;

					// Clear the selectedVerifier after adding the diagno sis
					this.selectedVerifier = '';

					// Update the diagnose content with the selected diagnoses list
					this.diagnose = this.diagnose ? `${this.diagnose}\n${newDiagnosis}` : newDiagnosis;

					this.insertPatientDiagnose(selectedICD10Code, selectedFullDescription);
				}
			} catch (error) {
				// console.error('Error filtering diagnoses:', error);
			}
		},
		async insertPatientDiagnose (selectedICD10Code, selectedFullDescription) {
			// hospitalId is taken from context of auth user
			const body = {
				patientUserId: this.patient.userData.id,
				staffId: this.userData.id,
				hospitalId: this.userData.hospitalAssignedData.id,
				diagnosisCode: selectedICD10Code,
				diagnoseDescription: selectedFullDescription,
			};

			const response = await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
			if (response.resFlag) {
				// this.category = response.data;
				// remove from table
				showSuccessAlert(this.$t('success'));

				this.patient.userData.patientDiagnosisList.push(response.data);
			} else {
				showErrorAlert(this.$t('failed'));
			}
		},
		onSelectionChange () {
			this.saveButtonDisabled = false;
		},
		async updatePatientMetaData () {
			const metaDataBody = {
				userId: this.patient.userData.id,
			};
			switch (this.show) {
			case 'problemList':
				metaDataBody.diagnose = this.diagnose;
				break;
			}
			try {
				await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$emit('false');
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} catch (error) {
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'error',
					color: 'main_red',
					// message: error.message,
					message: this.$t('failed'),
				});
			}
		},
		async handleAutoCompleteInput () {
			if (!this.selectedVerifier) {
				// Exit the function if no selection made yet
				return;
			}
			try {
				this.isLoading = true; // Set loading indicator
				const body = this.selectedVerifier.toString();
				const response = await this.$store?.dispatch('patientmetadata/getDiagnosesByCategory', body);
				if (response && response.data) {
					this.category = response?.data;
				}
			} catch (error) {
				// console.error('Error fetching diagnoses:', error);
			} finally {
				this.isLoading = false; // Clear loading indicator
			}
		},
		async getVerifiersForDomain () {
			try {
				const header = { domainValue: 'ICD_10_MAIN_CODES' };
				await this.$store.dispatch('verifiers/getVerifiersForDomain', header).then(() => {
					this.diagnosesCategory = this.verifiersFromRepo.map((category) => ({
						...category, lowValue: category.lowValue.toString(),
					}));
					this.diagnosesCategory.sort((a, b) => parseFloat(a.lowValue) - parseFloat(b.lowValue));
				});
			} catch (error) {
				// console.error('Error fetching verifiers:', error);
			}
		},
		async getDiagnosesByCategory (item) {
			try {
				// const body = this.selectedVerifier.toString();
				const response = await this.$store.dispatch('patientmetadata/getDiagnosesByCategory', item);
				if (response && response.data) {
					this.category = response.data;
				}
			} catch (error) {
				// console.error('Error fetching diagnoses:', error);
			}
		},
		async removeDiagnose (item) {
			try {
				// const body = this.selectedVerifier.toString();
				const response = await this.$store.dispatch('patientdiagnosis/deletePatientDiagnosis', item.id);
				if (response.resFlag) {
					// this.category = response.data;
					// remove from table
					showSuccessAlert(this.$t('success'));

					// patient.userData.patientDiagnosisList
					const elementToDeleteIndex = this.patient.userData.patientDiagnosisList.findIndex((obj) => obj.id === item.id);
					if (elementToDeleteIndex > -1) {
						this.patient.userData.patientDiagnosisList.splice(elementToDeleteIndex, 1);
					}
				} else {
					showErrorAlert(this.$t('failed'));
				}
			} catch (error) {
				// console.error('Error fetching diagnoses:', error);
				showErrorAlert(this.$t('failed'));
			}
		},
	},

	// async getDeviceTypes () {
	// 	const actualDevices = [];
	// 	if (this.trustedHubs !== null) {
	//     // eslint-disable-next-line no-unused-expressions
	//     this.trustedHubs[0]?.assignedMedicalDevicesList.forEach((dev) => { actualDevices.push(dev.deviceType); });
	// 	}
	// 	const header = { domainValue: 'DEVICE_TYPE' };
	// 	await this.$store.dispatch('verifiers/getVerifiersForDomain', header).then(() => { this.deviceTypes = this.verifiersFromRepo.map((device) => ({ ...device, value: actualDevices.includes(device.lowValue) })); });
	// },
	onSelectionChange () {
		this.saveButtonDisabled = false;
	},
	async updatePatientMetaData () {
		const metaDataBody = {
			userId: this.patient.userData.id,
		};
		switch (this.show) {
		case 'problemList':
			metaDataBody.diagnose = this.diagnose;
			break;
		case 'homeMed':
			metaDataBody.prescriptions = this.prescriptions;
			break;
		case 'allergies':
			metaDataBody.metadataInfo = this.alergies;
			break;
		}
		try {
			await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);
			this.$store.commit('alerts/add', {
				id: 'addedUser',
				type: 'success',
				color: 'main_green',
				// message: res.msg,
				message: this.$t('success'),
			});
			this.$emit('false');
			this.$store.dispatch('alerts/clearWithDelay', this.delay);
		} catch (error) {
			this.$store.commit('alerts/add', {
				id: 'addedUser',
				type: 'error',
				color: 'main_red',
				// message: error.message,
				message: this.$t('failed'),
			});
		}
	},
};
</script>

<style scoped>
th{
  height:50px;
}
.v-list{
 text-align:left;
}
.ql-container.ql-snow{
  border:none !important;
}

</style>
