<template>
  <v-card :height="cardHeight" class="elevation-0" style="border-radius: 12px;" :min-height="cardHeight" :max-height="cardHeight">
    <v-row cols="12" xl="12" lg="12" md="12">
      <v-col v-if="false" cols="4" flat color="#E8E8E8" class="d-flex align-center justify-center " style="border-radius: 12px">
        <v-card class=" elevation-0 ml-5">
          <v-img height="145" width="140" :src="userProfileImageUrl" style="border-radius: 12px;"></v-img>
        </v-card>
      </v-col>
      <v-col class="px-8">
        <v-row class="ma-0 d-flex justify-space-between" style="font-weight: bold" >{{ detailedUser.userData.firstName }} {{ detailedUser.userData.lastName }}

        </v-row>
        <v-row class="ma-0"  style="font-size: 12px">
          <span class="font-weight-bold">{{ calculateAge(detailedUser.userData.birthdate) }}</span>
          <span class="ml-1">{{$t('years-old')}}</span>
          <v-icon small color="black ">mdi-human-male-height </v-icon>
          <span class="font-weight-bold">{{userSettings.Units === "METRIC" ? detailedUser.userData.patientMetadata?.heightInCm : toFeet(detailedUser.userData.patientMetadata?.heightInCm)}}</span>
          <span class="ml-1">{{userSettings.Units === "METRIC" ? 'cm  ': 'ft '}}</span>
          <v-icon small color="black">mdi-weight</v-icon>
          <span class="font-weight-bold">{{userSettings.Units === "METRIC" ? detailedUser.userData.patientMetadata?.weightInKg : toLbs(detailedUser.userData.patientMetadata?.weightInKg)}}</span>
          <span class="ml-1">{{userSettings.Units === "METRIC" ? 'kg' : 'lbs'}}</span>
        </v-row>

        <v-row class="ma-0" style="font-size: 12px">
              <span class="">
						<v-icon small color="black">mdi-email</v-icon>
						{{ detailedUser.userData.email }}
            </span></v-row>

        <v-row class=" ma-0" style="font-size: 12px">
              <span >
						<v-icon small color="black">mdi-phone</v-icon>
						{{phone(detailedUser.userData.phone)}}
              </span>
        </v-row>

        <v-row class="ma-0" style="font-size: 12px">
          <span  class="font-weight-bold"> {{$t('mrn')}}:</span>
          <span class="ml-1">
							{{detailedUser.userData.patientMetadata?.medicalRegistrationNumber}}</span>
        </v-row>

        <v-row class="ma-0"  style="font-size: 12px">
						<span >
						<img height="15px" width="15px" :src="doctor_black" />
							{{detailedUser.userData.medicalTeamsData[0].primaryDoctorFullName}}
							</span>
        </v-row>

        <v-row class="ma-0 text-left" style="font-size: 12px">
						<span>
						<v-icon small color="black">mdi-map-marker </v-icon>
							{{detailedUser.userData.address}}
						</span>
        </v-row>
      </v-col>
      <v-col cols="1" flat color="#E8E8E8" class="d-flex mr-8" style="border-radius: 12px">
      <span v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"   >
					<v-card
              v-if="!video"
              flat
              color="primary"
              class="pa-1 d-flex align-center justify-center"
              @click="editPatientDetails = true"
          >
						<img height="16px" width="16px" :src="pencilImg" />
					</v-card>
				</span>
      </v-col>
    </v-row>

    <v-row class="px-5">
      <v-col cols="4" >
        <v-row dense >
          <v-card flat color="#E8E8E8" style="border-radius: 8px; width: 100%;" @click="showProblemListDialog(problemList, editTrue)">
            <span class="d-flex align-center justify-start pa-1">
                <span class="d-flex align-center justify-center" style="font-size: 12px">
                  <v-card
                      class="d-flex align-center justify-center mr-1"
                      height="24px" width="24px"
                      color='primary'
                  >
                    <v-icon color="white" class="mx-1" >mdi-prescription</v-icon>
                  </v-card>
              {{ $t("problem-list") }}
                </span>
            </span>
          </v-card>
        </v-row >
      </v-col>
      <v-col cols="4" class="px-0">
      <v-row dense  >
        <v-card v-if="roleName === 'NURSE' || roleName === 'DOCTOR' || roleName === 'HOSPITAL_ADMIN' || video" flat color="#E8E8E8" style="border-radius: 8px; width: 100%;" @click="showAbnormalNotificationDialogAllergies(homeMed, editTrue)">
          <span class="d-flex align-center pa-1 ">
            <span class="d-flex align-center justify-start" style="font-size: 12px">
              <v-card
                  class="d-flex align-center justify-center mr-1"
                  height="24px" width="24px"
                  color='primary'
              >
                <v-icon color="white"  class="mx-1" >mdi-text-box-outline</v-icon>
              </v-card>
              {{ $t("home-med") }}
            </span>
          </span>
        </v-card>
      </v-row>
      </v-col>
      <v-col cols="4" >
      <v-row dense>
        <v-card flat color="#E8E8E8" style="border-radius: 8px; width: 100%;" @click="showAbnormalNotificationDialogAllergies(allergies, editTrue)">
          <span class="d-flex align-center pa-1">
            <span class="d-flex align-center justify-start" style="font-size: 12px">
          <v-card
            class="d-flex align-center justify-center mr-1"
            height="24px" width="24px"
            color='primary'
        >
          <v-icon  color="white" class="mx-1" >mdi-information-variant </v-icon>
						</v-card>
        {{ $t("allergies") }}
        </span>
      </span>
      </v-card>
    </v-row>
      </v-col>
    </v-row>

    <v-row dense class="px-4">
      <v-col>
        <v-card  v-if="roleName === 'NURSE' || roleName === 'DOCTOR' || roleName === 'HOSPITAL_ADMIN' || video" @click="detailedUser.medicalTeamData.length > 0 ? medicalTeamMembersDialog = true : null" flat color="#E8E8E8" style="border-radius: 8px; width: 100%;">
				<span class="d-flex align-center">
					<span class="pa-1 d-flex align-center justify-start" style="font-size: 12px">
            <v-card
                class="d-flex align-center justify-center mr-1"
                height="24px" width="24px"
                color='primary'
            >
          <img class="mx-1" height="16px" width="16px" :src="medical_team_white" />
            </v-card>
              {{$t('medicalTeam')}}
					</span>
				</span>
        </v-card>
      </v-col>
      <v-col>
        <v-card @click="deviceClick()" flat color="#E8E8E8" style="border-radius: 8px; width: 100%;">
				<span class="d-flex align-center justify-space-between">
					<span class="pa-1 d-flex align-center justify-start" style="font-size: 12px">
            <v-card
                class="d-flex align-center justify-center mr-1"
                height="24px" width="24px"
                color='primary'
            >
							<img class="mx-1" height="16px" width="16px" :src="devices_white" />
						</v-card>
              {{$t('devices')}}
					</span>
				</span>
        </v-card>
      </v-col >
      <NotesCard :detailedUser="detailedUser"></NotesCard>
    </v-row>
    <v-col class="mt-2 fill-height">
      <Chat v-if="detailedUser" :userToChat="detailedUser.userData" :video="video" :key="rerender" :filter="filterChanged" />
    </v-col>
    <DeviceManage v-if="deviceDialog" :dialog="deviceDialog" :currentDevice="currentDevice" :commingType="commingType" @close="deviceDialogClosed" @false="deviceEdited"></DeviceManage>
    <AssignMedicalTeamMember
        v-if="manageMedicalTeamMemberDialog && roleName === 'HOSPITAL_ADMIN'"
        :dialog="manageMedicalTeamMemberDialog"
        :medicalTeamId="medicalTeamId"
        :team="medicalTeam"
        :existingMembers="existingMembers"
        @membersChanged="membersChanged = true"
        @false="closeAssignMedicatTeamDialg"
    />
    <MedicalTeamMembers
        v-if="medicalTeamMembersDialog"
        :dialog="medicalTeamMembersDialog"
        :medicalTeams="detailedUser.medicalTeamData"
        @false="medicalTeamMembersDialog = false"
    />
    <ManageMedicalTeamDialog
        v-if="manageMedicalTeamDialog"
        :dialog="manageMedicalTeamDialog"
        :deniedHospitals="deniedHospitals"
        :method="medicalTeamMethod"
        :data="medicalTeamData"
        :userId="detailedUser.userData.id"
        @false="
        () => {
          deviceEdited();
          manageMedicalTeamDialog = false;
        }
      "
        @close="manageMedicalTeamDialog = false"
    />
    <PatientEdit
        v-if="editPatientDetails"
        :dialog="editPatientDetails"
        :type="'edit'"
        :patient="detailedUser"
        @false="editPatientDetails = false"
        @edited="deviceEdited"
    ></PatientEdit>
    <MoreInformationDialog
        v-if="moreInfoDialog"
        :dialog="moreInfoDialog"
        :patientMetaData="detailedUser.userData.patientMetadata"
        @false="moreInfoDialog = false"
    ></MoreInformationDialog>
    <ProblemListComponent v-if="ProblemListComponent" @edited="deviceEdited" :dialog="ProblemListComponent" :patient="detailedUser" :show="show" @false="refreshPatientMetaData()"/>
    <AbnormalNotificationDialogAllergies v-if="AbnormalNotificationDialogAllergies" @edited="deviceEdited" :dialog="AbnormalNotificationDialogAllergies" :patient="detailedUser" :show="show" @false="AbnormalNotificationDialogAllergies = false" />
<!--    <assign-mental-health-template :edit="edit" @close="assigneMedicalTeamFlag=false" :dialog="assigneMedicalTeamFlag" :patientData="detailedUser" :path="'PatientDetailsCard'"></assign-mental-health-template>-->
  </v-card>

</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { pencilImg, medicalTeamWhite, glucoseMeterWhtie, cholesterolMeterWhite,	oxymeterWhite,	scaleWhite, thermometerWhite,	bloodPresureWhite,	devicesWhite,	monitoringPlanBlack,	seeMoreWhite, videocallBlack, doctorBlack, mentalHealthWhiteAssign } from '@/assets/';
import MedicalTeamMembers from '@/views/dialogs/MedicalTeamMembers.vue';
import AssignMedicalTeamMember from '@/views/dialogs/AssignMedicalMember.vue';
import ManageMedicalTeamDialog from '@/views/dialogs/ManageMedicalTeamDialog.vue';
import PatientEdit from '@/views/Client/components/PatientDetailsEdit.vue';
import MoreInformationDialog from '@/views/dialogs/MoreInformationDialog.vue';
import DeviceManage from '@/views/dialogs/DeviceManageNew.vue';
import { fromTo } from '@/utils/unit-converter.js';
import Chat from './Chat/Chat.vue';
import NotesCard from '@/views/Client/components/PatientNotes.vue';
import AbnormalNotificationDialogAllergies from '@/views/dialogs/AbnormalNotificationDialogAllergies.vue';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import PatientObject from '@/data/models/PatientObject';
import ProblemListComponent from '@/views/dialogs/ProblemListComponent.vue';
// import AssignMentalHealthTemplate from '@/views/dialogs/AssignMentalHealthTemplate.vue';
export default {
	components: {
		// AssignMentalHealthTemplate,
		ProblemListComponent,
		MedicalTeamMembers,
		AssignMedicalTeamMember,
		ManageMedicalTeamDialog,
		PatientEdit,
		AbnormalNotificationDialogAllergies,
		DeviceManage,
		MoreInformationDialog,
		Chat,
		// NotificationsCard,
		NotesCard,
	},
	props: ['noChat', 'video', 'type', 'Notes', 'item', 'filterChanged'],
	data () {
		return {
			edit: false,
			// assigneMedicalTeamFlag: false,
			ShowPerscriptionDialogVue: false,
			ShowDiagnosisDialogVue: false,
			ShowAlegiesDialogVue: false,
			AbnormalNotificationDialogAllergies: false,
			ProblemListComponent: false,
			pencilImg: pencilImg,
			filterChange: false,
			problemList: 'problemList',
			editTrue: true,
			editFalse: false,
			homeMed: 'homeMed',
			allergies: 'allergies',
			panel: [0, 1],
			panel1: [0, 1],
			panel2: [0, 1],
			filterChat: 'LAST7',
			show: null,
			mentalHealthWhiteAssign: mentalHealthWhiteAssign,
			see_more_white: seeMoreWhite,
			medical_team_white: medicalTeamWhite,
			devices_white: devicesWhite,
			monitoring_plan_black: monitoringPlanBlack,
			videoCallImg: videocallBlack,
			deviceTypes: [],
			filterCase: 'LAST7',
			treatmentPlanDialog: false,
			commingType: null,
			medicalTeamMembersDialog: false,
			manageMedicalTeamMemberDialog: false,
			coggClick: false,
			membersChanged: false,
			manageMedicalTeamDialog: false,
			rerender: 1,
			medicalTeamMethod: '',
			medicalTeamData: {},
			editPatientDetails: false,
			deviceDialog: false,
			currentDevice: {},
			moreInfoDialog: false,
			notificationsLoading: true,
			reloadDevices: 0,
			doctor_black: doctorBlack,
		};
	},
	computed: {
		userProfileImageUrl () {
			return this.getUserProfileImage();
		},
		...mapState({
			verifiersFromRepo: (state) => state.verifiers.verifiers,
			detailedUser: (state) => state.users.detailedUser,
			notesFromRepo: (state) => state.appointments.notes,
		}),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
		breakpoints () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return '100px';
			default:
				return '42px';
			}
		},
		userDetails () {
			return new PatientObject(this.detailedUser, 45);
		},
		paddingDevices () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return 'pt-3';
			default:
				return '';
			}
		},
		userTimeZone () {
			return this.detailedUser?.userData.userTimezone;
		},
		hasMedicalTeam () {
			return this.detailedUser.medicalTeamData.length > 0;
		},
		deviceImageSize () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
			case 'sm':
			case 'md':
			case 'xl':
				return '22px';
			case 'lg':
				return '16px';
			default:
				return '18px';
			}
		},
		cardHeight () {
			if (this.type === 'room') {
				return '600px';
			}
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return (window.innerHeight / 0.97) - 10;
			case 'sm':
				return (window.innerHeight / 0.97) - 50;
			case 'md':
				return (window.innerHeight / 0.97) - 50;
			case 'lg':
				return (window.innerHeight / 0.97) - 155;
			case 'xl':
				return (window.innerHeight / 0.97) - 155;
			default:
				return 0;
			}
		},
		deniedHospitals () {
			const d = [];
      // eslint-disable-next-line no-unused-expressions
      this.detailedUser.medicalTeamData?.forEach((data) => d.push(data.hospitalId));
      return d;
		},
		medicalTeamId () {
			return this.detailedUser.medicalTeamData[0].id;
		},
		medicalTeam () {
			return this.detailedUser.medicalTeamData[0];
		},
		existingMembers () {
			const e = [];
			this.detailedUser.medicalTeamData[0].membersList.forEach((m) => {
				e.push(m.id);
			});
			return e;
		},
		profileImage () {
			return this.detailedUser?.userProfileImage;
		},
		trustedHubs () {
			return this.detailedUser.trustedHubsData;
		},
		returnimage () {
			if (this.profileImage !== null) {
				return 'data:image/jpeg;base64,' + this.profileImage;
			} else { return 'https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png'; }
		},
		// items () {
		// 	var items = this.roleName === 'DOCTOR'
		// 		? [
		// 			{
		// 				text: 'All Notifications',
		// 				img: allNotification,
		// 				cardColor: '#6600FF',
		// 				read: this.notifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.notifications.length,
		// 			},
		// 			{
		// 				text: 'Messages',
		// 				img: messagesNotificationWhite,
		// 				cardColor: '#3A3A3A',
		// 				read: this.chatNotifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.chatNotifications.length,
		// 			},
		// 			{
		// 				text: 'Abnormal',
		// 				img: abnormalNotification,
		// 				cardColor: '#D30202',
		// 				read: this.abnormalNotifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.abnormalNotifications.length,
		// 			},
		// 			{
		// 				text: 'Missed',
		// 				img: missedNotificationWhite,
		// 				cardColor: '#FF931E',
		// 				read: this.missed.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.missed.length,
		// 			},
		// 			{
		// 				text: 'Appointment Requests',
		// 				img: appointmentsWhite,
		// 				cardColor: '#006837',
		// 				read: this.appointmentRequests.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.appointmentRequests.length,
		// 			},
		// 		]
		// 		: [
		// 			{
		// 				text: 'All Notifications',
		// 				img: allNotification,
		// 				cardColor: '#6600FF',
		// 				read: this.notifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.notifications.length,
		// 			},
		// 			{
		// 				text: 'Abnormal',
		// 				img: abnormalNotification,
		// 				cardColor: '#D30202',
		// 				read: this.abnormalNotifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.abnormalNotifications.length,
		// 			},
		// 			{
		// 				text: 'Missed',
		// 				img: missedNotificationWhite,
		// 				cardColor: '#FF931E',
		// 				read: this.missed.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.missed.length,
		// 			},
		// 			{
		// 				text: 'Messages',
		// 				img: messagesNotificationWhite,
		// 				cardColor: '#3A3A3A',
		// 				read: this.chatNotifications.filter((a) => {
		// 					return a.IsRead;
		// 				}).length,
		// 				all: this.chatNotifications.length,
		// 			},
		// 		];
		// 	return items;
		// },
	},
	async mounted () {
		await this.getDeviceTypes();
		await this.getMyNotifications(this.filterCase);
		this.rerender += 1;
	},
	watch: {
		filterChat () {
			this.filterChange = true;
		},
		filterChanged (val) {
			this.filterCase = val;
			this.getMyNotifications(this.filterCase);
		},
	},
	methods: {
		refreshPatientMetaData () {
			this.ProblemListComponent = false;
			this.getUserById();
		},
		async deviceDialogClosed () {
			await this.getDeviceTypes();
			this.deviceDialog = false;
			await this.deviceEdited();
		},
		getUserProfileImage () {
			if (this.detailedUser.userProfileImage !== null) {
				return 'data:image/jpeg;base64,' + this.detailedUser.userProfileImage;
			} else {
				const initials = this.detailedUser.userData.firstName.charAt(0) + this.detailedUser.userData.lastName.charAt(0);
				const canvas = document.createElement('canvas');
				canvas.width = 150;
				canvas.height = 150;
				const ctx = canvas.getContext('2d');
				ctx.fillStyle = '#6600FF';
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				ctx.font = '75px sans-serif';
				ctx.fillStyle = '#fff';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillText(initials, canvas.width / 2, canvas.height / 2);
				return canvas.toDataURL();
			}
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		showAbnormalNotificationDialogAllergies (show, edit) {
			this.AbnormalNotificationDialogAllergies = true;
			this.show = show;
			this.edit = edit;
		},
		showProblemListDialog (show, edit) {
			this.ProblemListComponent = true;
			this.show = show;
			this.edit = edit;
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		deviceClick (item, i) {
			// this.currentDevice = item;
			// this.currentDevice.i = i;
			this.deviceDialog = true;
			this.commingType = null;
		},
		addMedicalTeam () {
			this.snackStatus = false;
			let a = [];
			a = this.detailedUser.medicalTeamData?.filter((data) => {
				return !this.deniedHospitals.includes(data.hospitalId);
			});
			if (this.deniedHospitals.length === 0) {
				this.medicalTeamMethod = 'add';
				this.medicalTeamData = {};
				this.manageMedicalTeamDialog = true;
			} else {
				if (a.length !== 0) {
					this.medicalTeamMethod = 'add';
					this.medicalTeamData = {};
					this.manageMedicalTeamDialog = true;
				} else {
					this.medicalTeamMethod = 'edit';
					this.medicalTeamData = this.detailedUser.medicalTeamData[0];
					this.manageMedicalTeamDialog = true;
				}
			}
		},
		closeAssignMedicatTeamDialg () {
			if (this.membersChanged) {
				this.deviceEdited();
			} else {
				this.manageMedicalTeamMemberDialog = false;
			}
		},
		async getUserById () {
			this.userLoading = true;
			await this.$store
				.dispatch('users/getUserById', this.detailedUser.userData.id)
				.then(() => {
					this.userLoading = false;
				});
		},
		async deviceEdited () {
			this.editPatientDetails = false;
			await this.getUserById();
			await this.getDeviceTypes();
			this.reloadDevices += 1;
			this.deviceDialog = false;
			this.$emit('get-devices');
		},
		getImageForDevice (deviceType) {
			switch (deviceType) {
			case 1:
				return thermometerWhite;
			case 2:
				return oxymeterWhite;
			case 3:
				return bloodPresureWhite;
			case 5:
				return scaleWhite;
			case 6:
				return glucoseMeterWhtie;
			case 7:
				return cholesterolMeterWhite;
			}
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString?.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		async getDeviceTypes () {
			const actualDevices = [];
			if (this.trustedHubs !== null) {
        // eslint-disable-next-line no-unused-expressions
        this.trustedHubs[0]?.assignedMedicalDevicesList.forEach((dev) => { actualDevices.push(dev.deviceType); });
			}
			const header = { domainValue: 'DEVICE_TYPE' };
			await this.$store.dispatch('verifiers/getVerifiersForDomain', header).then(() => { this.deviceTypes = this.verifiersFromRepo.map((device) => ({ ...device, value: actualDevices.includes(device.lowValue) })); });
		},
		showTreatmentPlanDialog () {
			this.deviceDialog = true;
			this.commingType = 'treatmentplan';
		},
		// async initiateCall (videotrack) {
		// 	if (await permissions()) return;
		// 	const type = videotrack ? 'video' : 'audio';
		// 	const body = {
		// 		UserId: this.detailedUser?.userData?.id,
		// 		MessageToSend: `${this.detailedUser?.userData?.firstName} ${this.detailedUser?.userData?.lastName} has started ${type} call`,
		// 		NeedVideoTrack: videotrack,
		// 	};
		// 	await this.$store.dispatch('notifications/initVideoCall', body).then(async (res) => {
		// 		this.loading = true;
		// 		await this.goToRoom(res.data.appointmentData, videotrack);
		// 		this.loading = false;
		// 	});
		// },
		// async goToRoom (item, videotrack) {
		// 	this.$router.push({
		// 		name: 'VideoRoom',
		// 		params: {
		// 			id: `${item.videoRoomId}`,
		// 			userId: `${item.patientId}`,
		// 			appointmentId: item.appointmentId ? item.appointmentId : item.id,
		// 			token: localStorage.sessionToken,
		// 			needVideoRecording: item.needVideoRecording,
		// 			videotrack: videotrack,
		// 		},
		// 	});
		// },
		async getMyNotifications (val) {
			const object = { filterCase: val, patientId: parseInt(this.$route.params.id) };
			this.notificationsLoading = true;
			await this.$store.dispatch('notifications/getNotificationsWithPatient', object);
			this.notificationsLoading = false;
		},
	},
};
</script>
