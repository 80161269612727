module.exports = class ValueObject {
	constructor (value, dev, date, unit, secondValue, thirdValue) {
		this.value = value;
		this.dev = dev;
		this.date = date;
		this.unit = unit;
		this.secondValue = secondValue || '';
		this.thirdValue = thirdValue || '';
	}
};
