<template>
	<div>
		<vue-advanced-chat
			:height="height"
			:current-user-id="currentUserId"
			:rooms="JSON.stringify(room)"
			:rooms-loaded="roomsLoaded"
			:messages="JSON.stringify(messagesToDisplay)"
			:messages-loaded="messagesLoaded"
			:message-actions="messageActions"
			:single-room="true"
			show-audio="false"
			show-files="false"
			style="text-align: left"
			accepted-files="image/jpeg"
			show-emojis="false"
			:styles="JSON.stringify(styles)"
			:emojis-suggestion-enabled="false"
			:text-messages="JSON.stringify(
				{
					ROOMS_EMPTY: $t('ROOMS_EMPTY'),
					ROOM_EMPTY: $t('ROOM_EMPTY'),
					NEW_MESSAGES: $t('NEW_MESSAGES'),
					MESSAGE_DELETED: $t('MESSAGE_DELETED'),
					MESSAGES_EMPTY: $t('MESSAGES_EMPTY'),
					CONVERSATION_STARTED: $t('CONVERSATION_STARTED'),
					TYPE_MESSAGE: $t('TYPE_MESSAGE'),
					SEARCH: $t('SEARCH'),
					IS_ONLINE: $t('IS_ONLINE'),
					LAST_SEEN: $t('LAST_SEEN'),
					IS_TYPING: $t('IS_TYPING'),
					CANCEL_SELECT_MESSAGE: $t('CANCEL_SELECT_MESSAGE')
				}
			)"
			:show-footer="true"
			show-reaction-emojis="false"
			@send-message="sendMessage"
			@open-file="downloadFile($event.detail[0])">

			<div slot="room-header" style="width:100% !important">
							<v-col v-if="roleName !== 'HOSPITAL_ADMIN'" style="width:90% !important; justify-content: space-between" class="d-flex">
							<div class="d-flex align-center justify-center">
							<span class="ml-3">{{ $t("communication") }}</span>
							</div>
							<div>
								<span class="d-flex align-center justify-center">
									<v-btn v-if="!video" elevation="0" fab small color="secondary" class="d-flex align-center justify-center" @click.stop="initiateCall(true)">
										<img height="18px" width="18px" :src="videoCallImg" />
									</v-btn>
									<v-btn v-if="!video" elevation="0" fab small color="secondary" class="ml-2 d-flex align-center justify-center" @click.stop="initiateCall(false)">
										<v-icon color="primary">mdi-phone</v-icon>
									</v-btn>
								</span>
							</div>
							</v-col>
			</div>
		</vue-advanced-chat>
	</div>
</template>

<script>
import { register } from 'vue-advanced-chat';
import { mapGetters, mapState } from 'vuex';
import { formatterFilter } from '@/utils/luxon-formater';
import icon from '@/assets/account.svg';
import { videocallBlack } from '@/assets/';
import { permissions } from '@/utils/calls';
import base64 from 'base64-js'; // Import the base64-js library
register();

export default {
	props: ['userToChat', 'filter', 'video'],
	data () {
		return {
			icon: icon,
			currentUserId: '',
			messagesLoaded: false,
			roomsLoaded: false,
			patient: null,
			videoCallImg: videocallBlack,
			styles: {
				general: {
					color: '#6600FF',
					colorSpinner: '#6600FF',
					borderStyle: '1px solid #6600FF',
					colorButton: '#6600FF',
					colorButtonClear: '#6600FF',
					backgroundColorButton: '#E8E8E8',
					backgroundInput: 'white',
					colorPlaceholder: '#E8E8E8',
					colorCaret: '#6600FF',
					backgroundScrollIcon: '#E8E8E8',
					borderRadius: '12px',
				},

				container: {
					border: '0px solid #3A3A3A',
					borderRadius: '12px',
					boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
				},

				message: {
					// background: '#5e5e5e',
					// backgroundMe: '#6600FF',
					// color: '#E8E8E8',
					// colorUsername: '#9ca6af',
					// colorDate: '#3A3A3A',
					// backgroundDate: 'white',
					background: '#5e5e5e',
					backgroundMe: '#6600FF',
					color: '#E8E8E8',
					colorStarted: '#9ca6af',
					backgroundDeleted: '#1b1c21',
					backgroundSelected: '#c2dcf2',
					colorDeleted: '#a2a5a8',
					colorUsername: '#9ca6af',
					colorTimestamp: '#ebedf2',
					backgroundDate: '#fff',
					colorDate: '#3A3A3A',
					backgroundSystem: 'rgba(0, 0, 0, 0.3)',
					colorSystem: '#bec5cc',
					backgroundMedia: 'rgba(0, 0, 0, 0.18)',
					backgroundReply: 'rgba(0, 0, 0, 0.18)',
					colorReplyUsername: '#fff',
					colorReply: '#d6d6d6',
					colorTag: '#f0c60a',
					backgroundImage: '#ddd',
					colorNewMessages: '#fff',
					backgroundScrollCounter: '#1976d2',
					colorScrollCounter: '#fff',
					backgroundReaction: 'none',
					borderStyleReaction: 'none',
					backgroundReactionHover: '#202223',
					borderStyleReactionHover: 'none',
					colorReactionCounter: '#fff',
					backgroundReactionMe: '#4e9ad1',
					borderStyleReactionMe: 'none',
					backgroundReactionHoverMe: '#4e9ad1',
					borderStyleReactionHoverMe: 'none',
					colorReactionCounterMe: '#fff',
					backgroundAudioRecord: '#eb4034',
					backgroundAudioLine: 'rgba(255, 255, 255, 0.15)',
					backgroundAudioProgress: '#b7d4d3',
					backgroundAudioProgressSelector: '#b7d4d3',
					colorFileExtension: '#a2a5a8',
				},

				icons: {
					search: '#9ca6af',
					add: '#1976d2',
					toggle: 'black',
					menu: '#6600FF',
					close: '#6600FF',
					closeImage: '#6600FF',
					file: '#6600FF',
					paperclip: '#6600FF',
					closeOutline: '#6600FF',
					closePreview: '#6600FF',
					send: '#6600FF',
					sendDisabled: '#9ca6af',
					emoji: '#6600FF',
					emojiReaction: 'rgba(0, 0, 0, 0.3)',
					document: '#6600FF',
					pencil: '#6600FF',
					checkmark: '#E8E8E8',
					checkmarkSeen: '#E8E8E8',
					eye: '#fff',
					dropdownMessage: '#fff',
					dropdownRoom: '#E8E8E8',
					dropdownScroll: '#6600FF',
				},

				footer: {
					background: '#E8E8E8',
					backgroundReply: 'rgba(0, 0, 0, 0.08)',
					color: '#6600FF',
				},
				// autoScroll: {
				// 	send: {
				// 		new: true, // will scroll down after sending a message
				// 		newAfterScrollUp: false, // will not scroll down after sending a message when scrolled up
				// 	},
				// 	receive: {
				// 		new: true, // will not scroll down when receiving a message
				// 		newAfterScrollUp: true, // will scroll down when receiving a message when scrolled up
				// 	},
				// },
			},
			timeLocalization: this.$i18n.locale,
			messageActions: [],
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
			notificationsWithUser: (state) => state.notifications.notificationsWithUser,
		}),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return (window.innerHeight - 300).toString() + 'px';
			case 'sm': return (window.innerHeight - 330).toString() + 'px';
			case 'md': return (window.innerHeight - 330).toString() + 'px';
			case 'lg': return (window.innerHeight - 450).toString() + 'px';
			case 'xl': return (window.innerHeight - 440).toString() + 'px';
			default: return '500px';
			}
		},

		messagesToDisplay () {
			if (this.userToChat === null) {
				return [];
			}
			// const notif = this.notificationsWithUser.filter((n) => {
			// 	return (((n.FromUserId === this.userToChat.id || n.ToUserId === this.userToChat.id) && n.IsGroupNotification === true));
			// });

			const messagesToDisplay = this.notificationsWithUser.map((m) => {
				const message = {
					_id: m.NotificationId,
					indexId: m.NotificationId,
					content: '<b>' + m.fromUserFullname + '</b><br>' + m.MessageToSend,
					senderId: '' + m.FromUserId,
					username: m.UserFullname,
					date: formatterFilter(m.NotificationTimestamp, 'date_huge', this.timeLocalization, this.userDetails?.userTimezone),
					timestamp: formatterFilter(m.NotificationTimestamp, 'time_am_pm', this.timeLocalization, this.userDetails?.userTimezone),
					system: false,
					saved: true,
					distributed: true,
					seen: true,
					deleted: false,
					disableActions: false,
					disableReactions: false,
				};

				if (m.AttachedFile !== null) {
					const file = {
						name: 'Picture-' + m.NotificationId,
						size: 67351,
						type: 'image/jpeg',
						url: 'data:image/jpeg;base64,' + m.AttachedFile,
					};
					message.files = [file];
				}

				return message;
			});
			return messagesToDisplay;
		},

		// lastMessage () {
		// 	return this.messagesToDisplay[this.messagesToDisplay.length - 1];
		// },
		room () {
			const room = [
				{
					roomId: this.detailedUser.userData.id,
					// roomName: this.userToChat.firstName + ' ' + this.userToChat.lastName,
					// avatar: this.patientProfilePicture,
					unreadCount: 0,
					index: this.messagesToDisplay[this.messagesToDisplay.length],
					lastMessage: {
						content: this.lastMessage?.content,
						senderId: this.lastMessage?.senderId,
						username: '',
						timestamp: this.lastMessage?.date,
						saved: true,
						distributed: false,
						seen: false,
						new: false,
					},
					users: [
						{
							_id: this.userDetails?.id,
							username: '',
							avatar: this.icon,
						},
						{
							_id: this.userToChat.id,
							username: '',
							avatar: this.icon,
						},
					],
					typingUsers: [],
				},
			];
			return room;
		},
		patientProfilePicture () {
			return this.detailedUser?.userProfileImage !== null ? 'data:image/jpeg;base64,' + this.detailedUser?.userProfileImage : this.icon;
		},
	},
	watch: {
		async filter (value) {
			const request = {
				filterCase: value,
				id: this.patient,
			};
			// await this.getMessagesWithUser(request);
			await this.getMessagesWithUserFromMedicalTeam(request);
			// this.scrollChatToBottom();
		},
	},
	async mounted () {
		await this.renderChat(this.userToChat);
		this.messages = this.messagesToDisplay;
		this.messagesLoaded = true;
		this.roomsLoaded = true;
		this.currentUserId = `${this.userDetails?.id}`;
		// this.scrollChatToBottom();
	},
	methods: {
		downloadFile (file) {
			if (file && file.file && file.file.file.url) {
				const fileInfo = file.file.file;
				const imageData = fileInfo.url.split(',')[1]; // Extract base64 data after the comma

				// Decode base64 data
				const decodedData = base64.toByteArray(imageData);

				// Save the decoded data to a file
				const blob = new Blob([decodedData], { type: fileInfo.type });
				const url = URL.createObjectURL(blob);

				// Create a link element
				const link = document.createElement('a');
				link.href = url;

				// Set the download attribute to specify the filename
				link.setAttribute('download', fileInfo.name);

				// Trigger the click event to start the download
				link.click();
			}
		},

		closechat () {
			this.$emit('close');
		},
		async renderChat (user) {
			if (user !== null) {
				this.patient = user.id;
				const request = {
					filterCase: this.filter,
					id: this.patient,
				};
				// await this.getMessagesWithUser(request);
				await this.getMessagesWithUserFromMedicalTeam(request);
			}
		},
		async sendMessage (message) {
			if (message.type === 'file') {
				// TODO

				// const blob = new Blob([message.detail[0].files[0].blob], { type: message.detail[0].files[0].type });
				// var reader = new FileReader();
				// reader.readAsDataURL(blob);
				// setTimeout(() => {
				// 	const formData = new FormData();
				// 	formData.append('formFile', message.detail[0].files[0].blob, message.detail[0].files[0].type);
				// 	formData.append('fileName', 'Issue Image');
				// 	var bodySend = {
				// 		RelatedToSupportMessageId: this.getChatDetails[0].relatedToSupportMessageId,
				// 		FormDataSend: formData,
				// 	};
				// 	var files = [];
				// 	this.$store.dispatch('hospitals/sendFileSupportMessage', bodySend).then(res => {
				// 		var file = {
				// 			name: 'Issue',
				// 			size: 67351,
				// 			type: 'jpeg',
				// 			url: 'data:image/jpeg;base64,' + res.data.attachmentsList[0].attachedFile,
				// 		};
				// 		files.push(file);
				// 		mess.files = files;
				// 		this.messages.push(mess);
				// 	});
				// }, 1000);
			} else {
				// const body = {
				// 	userId: message.detail[0].roomId,
				// 	messageToSend: message.detail[0].content,
				// 	notificationTypeId: 1,
				// };
				// await this.$store.dispatch('notifications/sendMessage', body);

				const body = {
					medicalTeamId: this.detailedUser.userData.medicalTeamsData[0].id,
					messageToSend: message.detail[0].content,
					notificationTypeId: 1,
				};
				await this.$store.dispatch('notifications/sendMessageToMedicalTeam', body);
				// await this.renderChat(this.userToChat);
			}
		},
		async getMessagesWithUser (object) {
			await this.$store.dispatch('notifications/getMessagesWithUser', object);
		},
		async getMessagesWithUserFromMedicalTeam (object) {
			await this.$store.dispatch('notifications/getMessagesWithUserFromMedicalTeam', object);
		},
		async initiateCall (videotrack) {
			if (await permissions()) return;
			const type = videotrack ? 'video' : 'audio';
			const body = {
				UserId: this.detailedUser?.userData?.id,
				MessageToSend: `${this.detailedUser?.userData?.firstName} ${this.detailedUser?.userData?.lastName} has started ${type} call`,
				NeedVideoTrack: videotrack,
			};
			await this.$store.dispatch('notifications/initVideoCall', body).then(async (res) => {
				this.loading = true;
				await this.goToRoom(res.data.appointmentData, videotrack);
				this.loading = false;
			});
		},
		async goToRoom (item, videotrack) {
			this.$router.push({
				name: 'VideoRoom',
				params: {
					id: `${item.videoRoomId}`,
					userId: `${item.patientId}`,
					appointmentId: item.appointmentId ? item.appointmentId : item.id,
					token: localStorage.sessionToken,
					needVideoRecording: item.needVideoRecording,
					videotrack: videotrack,
				},
			});
		},
		// scrollChatToBottom () {
		// 	if (this.messages.length > 0 && this.messagesLoaded) {
		// 		const messageContainer = document.querySelector('.vac-container-scroll');
		// 		// eslint-disable-next-line no-return-assign
		// 		this.$nextTick(() => messageContainer.scrollTop = messageContainer.scrollHeight);
		// 	}
		// },
	},
};
</script>

<style>
.communication-text {
  margin-right: 90px; /* Adjust the margin as needed */
  text-align: left;
}
.vac-box-footer {
  border-radius: 12px !important;
}
.vac-room-header {
  border-radius: 12px !important;
}
.custom-date-class {
  color: red;
}
</style>
