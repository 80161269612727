<template>
  <div>
    <v-container>
      <v-card-title @click="toggleCard" class="py-1 my-n2" style="cursor: pointer; background-color:#E8E8E8; border-radius:8px;">
        <v-row>
          <v-col cols="5" class="d-flex justify-start" style="font-size: 14px;">{{ item.templateName }}</v-col>
          <v-col cols="7" class="d-flex justify-end">
<!--            <v-icon color="primary" @click.stop="editSurveyTemplate(item)" class="mr-3">mdi-pencil</v-icon>-->
<!--            <v-btn style="margin-top:6px; margin-right:10px;" @click.stop="showConfirmationDialog = true; itemToRemove = item" color="primary" x-small>{{item.status === 1 ? $t('disable') : $t('enable')}}</v-btn>-->
<!--            <v-icon @click.stop="openAssignDialog(item)" class="primary" color="white" style=" margin-right: 5px; border-radius: 4px; cursor: pointer; height: 20px; width: 20px; margin-top: 6px" >mdi-plus</v-icon>-->
            <v-icon color="primary" v-if="!isCardOpen">mdi-arrow-down-drop-circle</v-icon>
            <v-icon color="primary" v-else>mdi-arrow-up-drop-circle</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <div v-show="isCardOpen" style="overflow-y: scroll">
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                  hide-default-footer
                  hide-default-header
                  dense
                  :items="flattenedSurveyRecurrences"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <div style="display:flex; justify-content: space-between">
                        <div class="mt-2"><strong>{{$t('date')}}:</strong> {{ item.submitAtDate }}</div>
                      </div>
                    </td>
                    <td class="text-right d-flex justify-end">
                      <div class="d-flex align-center" style="margin-right:20px;">
                        <strong>{{$t('is-completed')}}</strong>
                        <v-icon :color="item.isCompleted ? 'green' : 'red'">
                          {{ item.isCompleted ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                      </div>
                      <v-icon color="primary" @click="openDialog(item)">mdi-eye</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-container>
    <v-dialog v-model="showConfirmationDialog" max-width="400">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{$t('confirmation-dialog')}}</span>
          <v-spacer />
          <v-icon small color="primary" @click="cancelConfirmation">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text color="primary" class="mt-4">
          <v-form>
            {{ $t('confirm-disable') }}
          </v-form>
        </v-card-text>

        <v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
          <span>
              <v-btn class="table-create-button" @click="confirmChangeStatus"><span>{{ $t("disable") }}</span></v-btn>
            </span>
        </v-row>
      </v-card>
    </v-dialog>
    <MedicalHealthSurveys v-if="viewDialog" style="width: 100%;" :dialog="viewDialog" @close="viewDialog = false" :item="surveyTemplateDetails" ></MedicalHealthSurveys>
    <PHQ9ReportDialog v-if="viewPHQ9Dialog" style="width: 100%;" :dialog="viewPHQ9Dialog" @close="viewPHQ9Dialog = false" :item="surveyTemplateDetails" ></PHQ9ReportDialog>
    <EditMedicalHealthAssigne :dialog="edit" @close="edit = false"></EditMedicalHealthAssigne>
    <assign-mental-health-template :edit="edit" @close="assigneMedicalTeamFlag=false" :type="type" :dialog="assigneMedicalTeamFlag" :patientData="detailedUser" :path="'PatientDetailsCard'" @reloadMonitoringPlan="reloadMonitoringPlan"></assign-mental-health-template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import EditMedicalHealthAssigne from '@/views/dialogs/EditMedicalHealthAssigne.vue';
import MedicalHealthSurveys from '@/views/dialogs/MedicalHealthSurveys.vue';
import AssignMentalHealthTemplate from '@/views/dialogs/AssignMentalHealthTemplate.vue';
import PHQ9ReportDialog from '@/views/dialogs/PHQ9ReportDialog.vue';

export default {
	components: {
		PHQ9ReportDialog,
		AssignMentalHealthTemplate,
		MedicalHealthSurveys,
		EditMedicalHealthAssigne,
	},
	props: ['item'],
	data () {
		return {
			flattenedSurveyRecurrences: [],
			surveyTemplateDetails: [],
			viewDialog: false,
			viewPHQ9Dialog: false,
			assigneMedicalTeamFlag: false,
			detailsPatient: [],
			isCardOpen: false,
			timeLocalization: this.$i18n.locale,
			edit: false,
			type: null,
			showConfirmationDialog: false,
			itemToRemove: null,
		};
	},
	watch: {
		item: {
			handler () {
				this.flattenSurveyRecurrences();
			},
			immediate: true,
		},
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
		}),
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
		}),
	},
	mounted () {
		this.flattenSurveyRecurrences();
	},
	methods: {
		flattenSurveyRecurrences () {
			const flattened = [];
			this.item.surveysList.forEach((survey) => {
				if (Array.isArray(survey.surveyRecurrencesList)) {
					flattened.push(...survey.surveyRecurrencesList);
				}
			});
			this.flattenedSurveyRecurrences = flattened;
		},
		cancelConfirmation () {
			this.showConfirmationDialog = false;
			this.itemToRemove = null;
		},
		// generatedAt (date) {
		// 	return date.slice(0, 10);
		// },
		reloadMonitoringPlan () {
			this.$emit('reloadMonitoringPlan');
		},
		openDialog (val) {
			if (val.mentalHealthCategoryId === 27) {
				this.viewPHQ9Dialog = true;
			} else {
				this.viewDialog = true;
			}
			this.surveyTemplateDetails = [val];
		},
		openAssignDialog (val) {
			this.assigneMedicalTeamFlag = true;
			this.type = val.mentalHealthCategoryId;
		},
		editTemplate () {
			this.edit = true;
			this.assigneMedicalTeamFlag = true;
		},
		editSurveyTemplate (item) {
			this.$store.dispatch('mentalhealth/SurveysAssignedById', item.id).then(() => {
				this.edit = true;
			});
		},
		confirmChangeStatus () {
			const item = this.itemToRemove;
			const newStatus = item.status === 1 ? 0 : 1;

			var obj = {
				body: {
					templateName: item.templateName,
					templateDescription: item.templateDescription,
					recurrenceRuleForSurvey: item.recurrenceRuleForSurvey,
					StartDate: item.startDate,
					id: item.id,
					EndDate: item.endDate,
					status: newStatus,
				},
				header: item.id,
			};

			this.$store.dispatch('mentalhealth/assigneMentalHealthEdit', obj).then(() => {
				// item.status = newStatus;
				this.showConfirmationDialog = false;
				this.reloadMonitoringPlan();
				this.$emit('close');
			});
		},
		toggleCard () {
			this.isCardOpen = !this.isCardOpen;
		},
	},
};
</script>

<style>
.no-background-hover::before {
  background-color: transparent !important;
}

.v-input--selection-controls {
  margin-left: 60px !important;
  margin-top: 5px !important;
  padding-top: 4px;
}
</style>
