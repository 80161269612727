<template>
	<v-card class="elevation-3" min-height="540">
		<v-card-title>
			<span  style="font-size:18px;color:#6600FF">{{$t('reference')}}</span>
		</v-card-title>
		<v-card-text>
			<v-timeline dense>
			<v-slide-x-reverse-transition group hide-on-leave>
				<v-timeline-item v-for="item in items" :key="item.id" :color="item.color" class="mt-0 pt-0">
				<v-alert :value="true" :color="item.color" style="font-size: 15px;" class="white--text ma-0 pa-5">
					<v-row v-if="deviceType === 3" style="display:flex; align-items:center;justify-content: center;">
<!--					<v-row dense class="d-flex justify-left m-3">{{ item.classification }}</v-row>-->
					<v-row class="text-left">
            <v-col>{{item.classification}}</v-col>
						<v-col>{{ item.systolicBloodPressure }}</v-col>
					</v-row>
					</v-row>
					<v-row v-else class="text-left">
					<v-row dense class="d-flex justify-left m-3">
						<v-col>{{ item.description }}</v-col>
            <v-col class="d-flex justify-end">{{ item.first_unit }}</v-col>
					</v-row>
<!--					<v-row dense>-->
<!--						<v-col class="d-flex justify-end">{{ item.first_unit }}</v-col>-->
<!--					</v-row>-->
					</v-row>
				</v-alert>
				</v-timeline-item>
			</v-slide-x-reverse-transition>
			</v-timeline>
		</v-card-text>
	</v-card>
  </template>
<script>
// eslint-disable-next-line no-unused-vars
const COLORS = [
	'blue lighten-2',
	'green darken-1',
	'amber accent-2',
	'orange accent-4',
	'deep-orange accent-4',
	'blue lighten-3',
	'blue lighten-4',
];
const ICONS = {
	low: 'mdi-thermometer-low',
	normal: 'mdi-thermometer',
	advice: 'mdi-thermometer-plus',
	acceptable: 'mdi-thermometer-alert',
	urgent: 'mdi-thermometer-high',
};

export default {
	props: ['item'],
	computed: {
		deviceType () {
			return this.item.deviceType;
		},
		items () {
			var items = [];
			switch (this.item.deviceType) {
			case 1:
				items = [
					{
						id: 1,
						color: COLORS[0],
						icon: ICONS.low,
						description: this.$t('low'),
						first_unit: '< 35°C   < 95.0°F ' + this.$t('or-less'),
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.normal,
						description: this.$t('normal-readings'),
						first_unit: '(36.5-37.5)°C  (99.5-99.7)°F',
					},
					{
						id: 3,
						color: COLORS[2],
						icon: ICONS.acceptable,
						description: this.$t('home-monitoring'),
						first_unit: '(37.5-38)°C  (99.7-100.4)°F',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.advice,
						description: this.$t('advice'),
						first_unit: '(38.1-39)°C  (100.58-102.2)F',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: this.$t('call-emergency'),
						first_unit: '39°C  102.2°F ' + this.$t('or-more'),
					},
				];
				break;
			case 2:
				items = [
					{
						id: 1,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: this.$t('normal-readings'),
						first_unit: '96% ' + this.$t('or-more'),
					},
					{
						id: 2,
						color: COLORS[2],
						icon: ICONS.urgent,
						description: this.$t('home-monitoring'),
						first_unit: '95%',
					},
					{
						id: 3,
						color: COLORS[3],
						icon: ICONS.urgent,
						description: this.$t('advice'),
						first_unit: '93-94%',
					},
					{
						id: 4,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: this.$t('call-emergency'),
						first_unit: '92% ' + this.$t('or-less'),
					},
				];
				break;
			case 3:
				items = [
					{
						id: 1,
						color: COLORS[0],
						icon: ICONS.urgent,
						classification: this.$t('low'),
						systolicBloodPressure: '(<90/<60) mmHg',
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.urgent,
						classification: this.$t('normal'),
						systolicBloodPressure: '(<120/<80) mmHg',
					},
					{
						id: 3,
						color: COLORS[2],
						icon: ICONS.urgent,
						classification: this.$t('prehypertension'),
						systolicBloodPressure: '(120-139/80-89) mmHg',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.urgent,
						classification: this.$t('stage-one-hypertension'),
						systolicBloodPressure: '(140-159/90-99) mmHg',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						classification: this.$t('stage-two-hypertension'),
						systolicBloodPressure: '(≥160/≥100) mmHg',
					},
				];
				break;
			case 6:
				items = [
					{
						id: 1,
						color: COLORS[5],
						icon: ICONS.urgent,
						description: this.$t('severe-hypoglycemia'),
						first_unit: '<53 mg/dL  <3 mmol/L',

					},
					{
						id: 2,
						color: COLORS[0],
						icon: ICONS.urgent,
						description: this.$t('hypoglycemia'),
						first_unit: '<70 mg/dL <3.9 mmol/L',
					},
					{
						id: 3,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: this.$t('normal'),
						first_unit: '<125 mg/dL  <7 mmol/L',
					},
					{
						id: 4,
						color: COLORS[3],
						icon: ICONS.urgent,
						description: this.$t('hyperglycemia'),
						first_unit: '<200 mg/dL <10 mmol/L',
					},
					{
						id: 5,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: this.$t('diabetes'),
						first_unit: '(200-500+)mg/dL (10-27.7+)mmol/L',
					},
				];
				break;
			case 7:
				items = [
					{
						id: 1,
						color: COLORS[5],
						icon: ICONS.urgent,
						description: this.$t('optimal'),
						first_unit: '<200 mg/dL  <5.2 mmol/L',
					},
					{
						id: 2,
						color: COLORS[1],
						icon: ICONS.urgent,
						description: this.$t('intermediate'),
						first_unit: '(200-239)mg/dL  (5.3-6.2)mmol/L',
					},
					{
						id: 3,
						color: COLORS[4],
						icon: ICONS.urgent,
						description: this.$t('high'),
						first_unit: '>239 mg/dL  >6.2mmol/L',
					},
				];
				break;
			}
			return items;
		},
	},
};
</script>
<style>
</style>
