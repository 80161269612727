<template>
	<v-dialog v-if="showDialog" v-model="showDialog" max-width="600px" persistent>
		<v-card flat style="overflow: auto">
			<v-card-title  class="secondary py-1" style="height: 40px">
				<span class="dialog-title">{{ $t('exit-patient-details-dialog-alert') }}</span>
				<v-spacer></v-spacer>
				<!-- <v-btn icon small color="primary" @click="emitEventToExitPatientDetails">
					<v-icon>mdi-close</v-icon>
				</v-btn> -->
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<span style="font-size: 15px;">{{ $t('exit-patient-details-dialog-content') }}</span>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
                <v-btn color="primary" dense @click="emitEventToExitPatientDetails">{{ $t('no') }}</v-btn>
				<v-btn color="primary" dense @click="emitEventToStayPatientDetails">{{ $t('yes') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data () {
		return {
		};
	},
	props: ['showDialog'],
	methods: {
		emitEventToExitPatientDetails () {
			this.$emit('exitPatientFromPatientDetails');
		},
		emitEventToStayPatientDetails () {
			this.$emit('stayPatientFromPatientDetails');
		},

	},
};
</script>
<style>
</style>
