<template>
  <v-dialog
      v-model="dialog"
      :width="type !== 'edit' ? 1000 : 800"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      class="dialog-style"
  >
    <v-card style="overflow: hidden">
      <v-card-title fixed-header class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{$t('patient')}}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
      </v-card-title>
      <v-row>
        <v-col class="d-flex justify-start pa-6">
          <span style="font-size: 16px">{{$t('personal')}} {{$t('information')}}</span>
        </v-col>
        <v-col class="d-flex justify-space-between pa-6" v-if="type !== 'edit'">
          <span style="font-size: 16px">{{$t('additionalData')}}</span>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-card flat style="overflow: hidden">
          <v-row class="px-4" dense>
            <v-col :cols="type !== 'edit' ? 6 : 12 ">
              <v-row dense class="pt-1">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="userDetails.firstName"
                      :label="this.$t('name')"
                      prepend-inner-icon="mdi-account mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="userDetails.lastName"
                      :label="this.$t('surname')"
                      prepend-inner-icon="mdi-account mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                      ref="email"
                      :name="$t('email')"
                      rules="required|email"
                      v-slot="{ errors }">
                    <v-text-field
                        v-model="userDetails.email"
                        :label="$t('email')"
                        prepend-inner-icon="mdi-email mr-1"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        :disabled="type === 'edit' ? true : false"
                        style="border-radius: 12px !important; height: 40px"
                        :error-messages="(emailError = errors)">
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      ref="phoneNumber"
                      v-model="phone"
                      validate-on-blur
                      :label="$t('phone-number')"
                      prepend-inner-icon="mdi-cellphone mr-4"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <!-- grid was with 3 col now we hide the timeZone -->
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="userDetails.mrn"
                      :label="$t('mrn')"
                      prepend-inner-icon="mdi-account mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      hide-details
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <!-- grid was with 3 col now we hide the timeZone -->
                <v-col cols="12" lg="6" md="6" sm="12">
					<v-text-field
                      v-model="userDetailspolicyNumber"
                      :label="$t('policy_number')"
                      prepend-inner-icon="mdi-certificate mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      hide-details
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" lg="6" md="6" sm="12">
                <v-select
                  :items= "healthInsuranceProgram"
                  :item-text= "domain=> domain.shortDescription"
                  v-model="healthInsuranceProgramID"
                  item-value="lowValue"
                  :label="$t('insurance')"
                  prepend-inner-icon="mdi-medical-bag mr-1"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  hide-details
                  style="border-radius: 12px !important; height: 40px"
                        ></v-select>
              </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <BirthdatePicker :inputValue.sync="birthdate" />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col><v-btn-toggle
                    class="my-2 d-flex justify-start"
                    v-model="userDetails.gender"
                    row
                    mandatory
                    active-class="primary white--text"
                    rounded
                >
                  <v-btn
                      value= 1
                      style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
                  >{{$t('male')}}</v-btn>
                  <v-btn
                      value= 2
                      style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
                  >{{$t('female')}}</v-btn>
                </v-btn-toggle></v-col>
                <v-col>
                  <v-checkbox
                      class="ma-0"
                      v-model="userDetails.mentalHealth"
                      :label="$t('mental-health-treatment')"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col>
                  <v-btn-toggle
                      class="my-2 d-flex justify-end"
                      v-model="dataStatus"
                      row
                      mandatory
                      active-class="primary white--text"
                      rounded
                  >
                    <v-btn
                        :value=false
                        style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
                    >{{$t('automatic-reading')}}</v-btn>
                    <v-btn
                        :value=true
                        style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
                    >{{$t('manual-reading')}}</v-btn>
                  </v-btn-toggle>
				</v-col> -->
              </v-row>
              <v-row dense cols="12">
                <v-col cols="12" lg="6" md="6" sm="6">
                  <validation-provider
                      :name="$t('height')"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        v-model="heightInCm"
                        :label="userSettings.Units === 'METRIC' ? $t('height-in-cm') : $t('height-in-ft')"
                        prepend-inner-icon="mdi-human-male-height mr-1"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        hide-details
                        :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="6">
                  <validation-provider
                      :name="$t('weight')"
                      rules="required"
                      v-slot="{ errors }">
                    <v-text-field
                        v-model="weightInKg"
                        :label="userSettings.Units === 'METRIC' ? $t('weight-in-kg') : $t('weight-in-lbs')"
                        prepend-inner-icon="mdi-weight mr-1"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; height: 40px"
                        hide-details
                        :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols>
                  <v-btn-toggle
                      class="my-2 justify-start"
                      v-model="userDetails.smoker"
                      row
                      mandatory
                      active-class="primary white--text"
                      rounded
                  >
                    <v-btn
                        value='1'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                    >{{$t('smoker')}}</v-btn>
                    <v-btn
                        value='0'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                    >{{$t('non-smoker')}}</v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col>
                  <v-btn-toggle
                      class="my-2 justify-start"
                      v-model="language"
                      row
                      mandatory
                      active-class="primary white--text"
                      rounded
                  >
                    <v-btn
                        value="ENG"
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        :class="{'primary': language === 'ENG'}"
                    >{{$t('english')}}</v-btn>
                    <v-btn
                        value="ESP"
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        :class="{'primary': language === 'ESP'}"
                    >{{$t('spanish')}}</v-btn>
                  </v-btn-toggle>

                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-btn-toggle
                      class="my-2 justify-start"
                      v-model="scaleAndTempUnit"
                      row
                      mandatory
                      active-class="primary white--text"
                      rounded>
                    <v-btn
                        value='IMPERIAL'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        @click="setImperialOrMetric('IMPERIAL')"
                    >{{$t('imperial')}}</v-btn>
                    <v-btn
                        value='METRIC'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        @click="setImperialOrMetric('METRIC')"
                    >{{$t('metric')}}</v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col>
                  <v-btn-toggle
                      class="my-2 justify-start"
                      v-model="glucoseAndCholesterolUnit"
                      row
                      mandatory
                      active-class="primary white--text"
                      rounded>
                    <v-btn
                        value='mg/dl'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        @click="setGlucoseAndCholesterolUnit('mg/dl')"
                    >{{$t('glucose-mg-dL')}}</v-btn>
                    <v-btn
                        value='mmol/l'
                        style="width: 185px; font-size: 10px; justify-content: center; height: 20px"
                        @click="setGlucoseAndCholesterolUnit('mmol/l')"
                    >{{$t('glucose-mmol-L')}}</v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!--			<v-row dense>-->
              <!--				<v-col>-->
              <!--                  <v-checkbox-->
              <!--                      class="ma-0"-->
              <!--                      v-model="userDetails.mentalHealth"-->
              <!--                      :label="$t('mental-health-treatment')"-->
              <!--                  ></v-checkbox>-->
              <!--                </v-col>-->
              <!--			</v-row>-->

              <v-row class="px-3" cols="12" lg="12" md="12" sm="12">
                <v-text-field
                    ref="address"
                    v-model="address_info.address"
                    validate-on-blur
                    :label="$t('address')"
                    prepend-inner-icon="mdi-map-marker mr-4"
                    dense
                    background-color="#E8E8E8"

                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-row>
              <v-row dense class="pt-4">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="address_info.city"
                      :label="this.$t('city')"
                      prepend-inner-icon="mdi-map-marker mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="address_info.postal_code"
                      :label="this.$t('zip-code')"
                      prepend-inner-icon="mdi-map-marker mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="pt-1">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="address_info.state"
                      :label="this.$t('state')"
                      prepend-inner-icon="mdi-map-marker mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                      v-model="address_info.country"
                      :label="this.$t('country')"
                      prepend-inner-icon="mdi-map-marker mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="timezonePatient"
                      :label="$t('timezone')"
                      :items=timeZones
                      prepend-inner-icon="mdi-earth mr-4"
                      dense
                      item-text="timeZoneId"
                      item-value="value"
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pl-4" cols="6" v-if="type !== 'edit'">
              <v-row dense cols="12" class="px-1 mt-0">
                <v-tabs
                    background-color="#E8E8E8"
                    color="primary"
                    grow
                    hide-slider
                    height="40px"
                    style="border-radius: 12px"
                    active-class="background-color: primary white--text"
                    v-model="tab"
                    left
                    :show-arrows="true"
                >
                  <v-tab class="tab-header-left">{{$t('problem-list')}}</v-tab>
                  <v-tab class="tab-header-middle">{{$t('home-med')}}</v-tab>
                  <v-tab class="tab-header-right">{{$t('allergies')}}</v-tab>
                  <v-tab-item v-for="n in 3" :key="n" class="pt-4 px-0">
                    <vue-editor
                        v-if="n === 3"
                        v-model="userDetails.additionalInfo"
                        style="height: 270px; overflow-y: auto; border-radius: 12px; background-color: #E8E8E8"
                        :editorToolbar="customToolbar"
                        :editorOptions="editorOptions"
                    ></vue-editor>
                    <vue-editor
                        v-if="n === 2"
                        v-model="userDetails.prescriptions"
                        style="height: 270px; overflow-y: auto; border-radius: 12px; background-color: #E8E8E8"
                        :editorToolbar="customToolbar"
                        :editorOptions="editorOptions"
                    ></vue-editor>
                    <vue-editor
                        v-if="n === 1"
                        v-model="userDetails.diagnose"
                        style="height: 270px; overflow-y: auto; border-radius: 12px; background-color: #E8E8E8"
                        :editorToolbar="customToolbar"
                        :editorOptions="editorOptions"
                        label="Any known diagnose"
                        prepend-inner-icon="mdi-text-box-outline mr-1"
                        dense
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        filled
                        rows="10"
                        row-height="30"
                        shaped
                        hide-details
                    ></vue-editor>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </v-col>
          </v-row>

          <Alert
              class="ml-5"
              v-if="showErrors"
              :title="title"
              :type="alertType"
          ></Alert>

          <div class="my-4 mx-2" style="float: right;">
            <v-btn class="table-cancel-button" @click="$emit('false')">
              <span color="black--text">{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
                class="table-create-button mx-2"
                :loading="loading"
                :disabled="loading"
                @click="loader = 'loading'"
            >
              <span>{{ $t("save") }}</span>
            </v-btn>
          </div>

        </v-card>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Alert from '@/views/Client/components/AlertDialog.vue';
import BirthdatePicker from '@/views/Client/components/BirthdatePicker';
import PatientObject from '@/data/models/PatientObject';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { VueEditor } from 'vue2-editor';
import { fromTo } from '@/utils/unit-converter.js';
// var convert = require('convert-units');

export default {
	components: {
		Alert,
		VueEditor,
		BirthdatePicker,
	},
	props: ['dialog', 'type', 'patient'],
	data () {
		return {
			addressIsNotValid: false,
			loader: null,
			loading: false,
			title: 'Something went wrong',
			alertType: false,
			showErrors: false,
			selfAssesments: false,
			validationErrors: [],
			scaleAndTempUnit: 'IMPERIAL',
			glucoseAndCholesterolUnit: 'mg/dl',
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4] }],
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
				[{ align: '' },
					{ align: 'center' },
					{ align: 'justify' }],
			],
			editorOptions: {
				modules: {
					toolbar: false,
				},
			},
			tabs: [
				{
					text: 'Additional info',
					cardColor: '#6600FF',
				},
				{
					text: 'Prescriptions',
					cardColor: '#D30202',
				},
				{
					text: 'Any known diagnose',
					cardColor: '#FF931E',
				},
			],
			userLoading: false,
			snackStatus: false,
			snackColor: '',
			dataStatus: null,
			firstName: '',
			lastName: '',
			email: '',
			emailError: false,
			username: '',
			phoneNumber: null,
			address_info: {
				address: '',
				postal_code: '',
				city: '',
				state: '',
				country: '',
				lat: 32.798718741846955,
				lng: -96.79917112574036,
			},
			gender: '',
			smoker: false,
			alcoolConsumtionLevels: [
				{ text: 'Frequent consumption', value: 2 },
				{ text: 'Consuming rarely', value: 1 },
				{ text: 'Not consuming', value: 0 },
			],
			birthdate: '2000-01-01',
			valid: false,
			metadataInfo: '',
			heightInCm: null,
			weightInKg: null,
			medicalRegistrationNumber: null,
			alcoolConsumtion: null,
			prescriptions: '',
			diagnose: '',
			phoneCodeModel: '',
			hospitals: [],
			hospitalId: -1,
			countries: [],
			addressToValidate: null,
			formattedAddress: null,
			timezone: null,
			timezonePatient: '',
			tab: 0,
			phone: null,
			phoneErrors: false,
			language: '',
			healthInsuranceProgram: [],
			healthInsuranceProgramID: -1,
			userDetailspolicyNumber: '',
		};
	},
	computed: {
		...mapState({
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
			timeZones: (state) => state.verifiers.timeZones,
			loggedUser: (state) => state.authentication.userData,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
			delay: 'authentication/getDelay',
		}),
		userDetails () {
			return new PatientObject(this.patient);
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createPatient()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				this.phone = new AsYouType().input(val);
			}
		},
		language (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.language = newValue;
			}
		},
	},
	async mounted () {
		// await this.healthInsuranceVerifiers();
		// await this.getHospitals();
		await this.getVerifiers();
		await this.getTimeZones();
		this.phone = this.userDetails?.phoneNumber;
		this.language = this.userDetails.language;
		this.smoker = this.userDetails.smoker;
		this.scaleAndTempUnit = (JSON.parse(this.userDetails.userSettingsJson)).Units;
		this.glucoseAndCholesterolUnit = (JSON.parse(this.userDetails.userSettingsJson)).Concentration;
		if (this.type === 'edit') {
			this.heightInCm = this.userSettings.Units === 'METRIC' ? this.userDetails?.height : parseFloat(fromTo(this.userDetails?.height, 'cm', 'ft-us').toFixed(2));
			this.weightInKg = this.userSettings.Units === 'METRIC' ? this.userDetails?.weight : parseFloat(fromTo(this.userDetails?.weight, 'kg', 'lb').toFixed(2));
			this.addressData();
			this.birthdate = this.userDetails?.birthdate;
			if (!isValidPhoneNumber(this.phone)) {
				this.phoneErrors = true;
			}
		}
		this.dataStatus = this.patient?.userData?.patientMetadata.isManualMeasurement;
		this.timezonePatient = this.patient?.userData?.userTimezone;
		this.healthInsuranceProgram = this.verifiersFromRepo.filter((domain) => domain.rvDomain === 'HEALTH_INSURANCE_PROGRAM');
		this.healthInsuranceProgramID = this.patient.userData.patientMetadata?.healthInsurance;
		this.userDetailspolicyNumber = this.patient.userData.patientMetadata?.memberIdPolicyNumber;
	},
	methods: {
		// handleLostFocus () {
		// 	if (this.address_info.address !== '' && this.address_info.city !== '' && this.address_info.postal_code !== '' && this.address_info.state !== '' && this.address_info.country !== '') {
		// 		this.userDetails.address = this.address_info.address + ', ' + this.address_info.city + ', ' + this.address_info.state + ' ' + this.address_info.postal_code + ', ' + this.address_info.country;
		// 		this.locationDetails();
		// 	}
		// },
		async getVerifiers () {
			const header = { domainsList: 'DEVICE_TYPE,HEALTH_INSURANCE_PROGRAM' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
		},
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.getPreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.getPreferences();
		},
		getPreferences () {
			const preferences = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			return preferences;
		},

		async healthInsuranceVerifiers () {
			const header = { domainValue: 'HEALTH_INSURANCE_PROGRAM' };
			await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
		},
		async addressData () {
			this.address_info = {
				address: this.userDetails.address,
				postal_code: this.patient?.userData?.zip,
				city: this.patient?.userData?.city,
				state: this.patient?.userData?.state,
				country: this.patient?.userData?.country,
				lat: this.patient?.userData?.addressLatitude,
				lng: this.patient?.userData?.addressLongitude,
			};
		},
		async getTimeZones () {
			await this.$store.dispatch('verifiers/getTimeZones').then(() => {
			});
		},

		async locationDetails () {
			if (this.userDetails.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.userDetails.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.address_info = {
						address: this.userDetails.address,
						postal_code: this.address_info.postal_code,
						formattedAddress: this.userDetails.address,
						city: this.address_info.city,
						state: this.address_info.state,
						country: this.address_info.country,
						lat: 32.798718741846955,
						lng: -96.79917112574036,
					};
					// this.$store.commit('alerts/add', {
					// 	id: 'addedUser',
					// 	type: 'error',
					// 	color: 'main_red',
					// 	message: `${this.$t('geocoder-error-validation')}`,
					// });
					// this.$emit('edited');
					// this.$store.dispatch('alerts/clearWithDelay', this.delay);
					this.addressIsNotValid = false;
					this.timezone = 'America/Chicago';
					return;
				}

				if (geoLocation.address === undefined || geoLocation.address == null) {
					this.validationErrors.push('address');
					this.address_info = {
						address: this.userDetails.address,
						postal_code: this.address_info.postal_code,
						formattedAddress: this.userDetails.address,
						city: this.address_info.city,
						state: this.address_info.state,
						country: this.address_info.country,
						lat: 32.798718741846955,
						lng: -96.79917112574036,
					};
					// this.$store.commit('alerts/add', {
					// 	id: 'addedUser',
					// 	type: 'error',
					// 	color: 'main_red',
					// 	message: `${this.$t('address-cant-be-validated')}`,
					// });
					// this.$emit('edited');
					// this.$store.dispatch('alerts/clearWithDelay', this.delay);
					this.addressIsNotValid = false;
					this.errorMessage = '';
					this.timezone = 'America/Chicago';
					return;
				} else {
					this.addressIsNotValid = false;
					this.errorMessage = '';
				}
				const addressComponents = geoLocation.address;

				this.address_info.lat = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
				this.address_info.lng = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
				this.formattedAddress = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.userDetails.address : geoLocation.display_name;
				this.address_info.address = this.formattedAddress;
				this.address_info.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
				this.address_info.state = addressComponents.state || 'N/A';
				this.address_info.country = addressComponents.country || 'N/A';
				this.address_info.postal_code = addressComponents.postcode || 'N/A';

				if (this.address_info.lat !== null) {
					const tz = await this.timeZone(this.address_info.lat, this.address_info.lng);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},

		async updatePatientMetaData (userId) {
			const metaDataBody = {
				userId: userId,
				metadataInfo: this.userDetails.additionalInfo,
				heightInCm: this.userSettings.Units === 'METRIC' ? parseInt(this.heightInCm) : parseFloat(fromTo(this.heightInCm, 'ft-us', 'cm').toFixed(2)),
				weightInKg: this.userSettings.Units === 'METRIC' ? parseInt(this.weightInKg) : parseFloat(fromTo(this.weightInKg, 'lb', 'kg').toFixed(2)),
				ssn: this.ssn,
				medicalRegistrationNumber: this.userDetails.mrn,
				patientIdentifier: this.patientIdentifier,
				isCigaretteSmoker: this.userDetails.smoker === '1',
				alcoolConsumtion: this.userDetails.alcoolConsumtion,
				prescriptions: this.userDetails.prescriptions,
				mentalHealth: this.userDetails.mentalHealth,
				healthInsurance: this.healthInsuranceProgramID,
				memberIdPolicyNumber: this.userDetailspolicyNumber,
				// mentalHealth: false,
				diagnose: this.userDetails.diagnose,
				IsManualMeasurement: this.dataStatus,
			};
			await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch(
				'googleAPI/getTimeZone',
				object,
			);
			return timeZone;
		},
		checkValid () {
			this.validationErrors = [];
			if (this.phoneErrors) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			if (this.address_info.address === '') {
				this.validationErrors.push('address');
			}
			if (this.birthdate === '') {
				this.validationErrors.push('birthdate');
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createPatient () {
			// const address = await this.locationDetails();
			// if (address === false) {
			// 	this.validationErrors.push('address');
			// 	this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
			// 	this.alertType = 'error';
			// 	this.showErrors = true;
			// 	return;
			// }
			const valid = this.checkValid();
			if (!valid) {
				this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
				return;
			}
			if (valid) {
				this.showErrors = false;
				this.validationErrors = [];
				this.userLoading = true;

				const body = {
					firstName: this.userDetails.firstName,
					lastName: this.userDetails.lastName,
					phone: this.phone.split(' ').join(''),
					email: this.userDetails.email,
					address: this.address_info.address,
					zip: this.address_info.postal_code,
					city: this.address_info.city,
					state: this.address_info.state,
					// MentalHealth: this.userDetails.mentalHealth,
					MentalHealth: false,
					country: this.address_info.country,
					addressLatitude: this.address_info.lat,
					addressLongitude: this.address_info.lng,
					username: this.userDetails.email,
					gender: this.userDetails.gender === '1' ? 'M' : 'F',
					birthdate: this.birthdate,
					roleId: 5,
					// userTimezone: this.timezone,
					userTimezone: this.timezonePatient,
					userSettingsJson: JSON.stringify(this.getPreferences()),
					IsManualMeasurement: this.dataStatus,
					language: this.language,
				};
				if (this.type === 'edit') {
					body.id = this.userDetails.id;
					// body.status = 0;
					await this.$store
						.dispatch('users/updateUser', body)
						.then(async (res) => {
							if (res) {
								await this.updatePatientMetaData(res.data.id).then(() => {
									this.userLoading = false;
									this.$emit('edited', res);
								});
								this.$store.commit('alerts/add', {
									id: 'addedUser',
									type: 'success',
									color: 'main_green',
									// message: res.msg,
									message: this.$t('success'),
								});
								this.$emit('edited');
								await this.$store.dispatch('alerts/clearWithDelay', this.delay);
							}
						});
				} else {
					await this.$store.dispatch('users/createUser', body)
						.then(async (res) => {
							if (res.statusCode >= 200 && res.statusCode < 300) {
								if (res.resFlag) {
									this.$store.commit('alerts/add', {
										id: 'addedUser',
										type: 'success',
										color: 'main_green',
										// message: res.msg,
										message: this.$t('success'),
									});
									this.$store.dispatch('alerts/clearWithDelay', this.delay);
								} else {
									this.$store.commit('alerts/add', {
										id: 'addedUser',
										type: 'error',
										color: 'main_red',
										// message: res.msg,
										message: this.$t('failed'),
									});
									this.$store.dispatch('alerts/clearWithDelay', this.delay);
								}
								await this.updatePatientMetaData(res.data.id).then(() => {
									this.userLoading = false;
									this.$emit('false', res);
								});
							}
						});
				}
			}
		},
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				if (this.hospitalsFromRepo !== null) {
					this.hospitals = this.hospitalsFromRepo;
				} else this.hospitals = [];
			});
		},
	},
};
</script>
