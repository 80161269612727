<template>
  <v-dialog v-model="dialog" width="1200" persistent>
      <v-card>
          <v-card-title class="secondary py-1" style="height: 40px">
              <span class="dialog-title">{{ $t("manage") + " " + $t("devices") }}</span>
              <v-spacer />
              <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row class="my-2">
              <v-col>
                <template>
                  <v-data-table
                      dense
                      :headers="headers"
                      :items="assignDevices"
                      item-key="name"
                      class="elevation-1"
                      hide-default-footer
                      :single-expand="true"
                      :expanded.sync="expanded"
                      :footer-props="{
        itemsPerPageText: this.$t('row'),
        itemsPerPageAllText: this.$t('all'),
      }"
                  >
                    <template v-slot:top>
                      <v-row class="my-1">
                        <v-col class="col-4">
                          <span class="mx-2 d-flex justify-start" style="font-weight: bold;">{{$t("available-devices")}}</span>
                        </v-col>
                        <v-col class="col-6">
                          <v-select
                              v-model="createdevice"
                              v-if="createDropdownStatus"
                              :items="filteredDevices"
                              item-text="deviceName"
                              item-value="body"
                              v-bind:label="$t('device')"
                              hide-details
                              class="ma-0 pa-0 d-flex justify-end"
                              dense
                          ></v-select>
                        </v-col >
                        <v-col class=" col-1 mx-2 d-flex justify-end" style="font-weight: bold;"> <v-icon @click="addDevice">mdi-plus</v-icon></v-col>
                      </v-row>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td class="elevation-1" :colspan="headers.length">
                        <v-row class="my-1" >
                        <v-col v-for="(keys, i) in item.deviceDefaultValuesJsonNames" :key="i" class="px-10" cols="6" >
                            <v-text-field
                                type="number"
                                hide-details
                                v-model="item.deviceDefaultValuesJson[keys]"
                                :label="stringFormater(keys) + ' ' + unitFormater(keys, item)"
                            ></v-text-field>
                        </v-col>
                        </v-row>
                        <span class="d-flex justify-end">
                          <v-btn @click="saveParameters(item)" class="table-create-button ma-2"
                                 style="padding-left: 17px !important" color="primary">{{ $t("save") }}</v-btn>
                        </span>
                      </td>
                    </template>
                    <template v-slot:item="{ item, expand, isExpanded }">
                      <tr @click="() => expand(!isExpanded && item.deviceType !== 5)">
                        <td class="d-flex align-start" v-if="$i18n.locale === 'en'">
                          {{item.name}}
                        </td>
                        <td class="d-flex align-start" v-else>
                          {{item.nameEsp}}
                        </td>
                        <td>
                          {{item.manufacturer}}
                        </td>
                        <td>
                          <span >
                            <v-row class="d-flex justify-end mx-1">
                              <v-col cols="2">
                             <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-if="item.deviceType !== 5" v-on="on">mdi-pencil</v-icon>
                                </template>
                                <span>{{$t("edit")}}</span>
                              </v-tooltip>
                                </v-col>
                               <v-col cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{  on }">
                                  <span v-on="on" @click.stop="isManual(item)">
                                <img  :src="item.isManualMeasurement ? letterM : letterA" alt="Icon" class="small-icon mt-1"/>
                               </span>
                                </template>
                                <span>{{item.isManualMeasurement ? $t("manual") : $t("automatic")}}</span>
                              </v-tooltip>
                               </v-col>							 <v-col cols="2">
                               <!-- <v-col v-if="getRole === 'HOSPITAL_ADMIN' || " cols="2"> -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon @click.stop="deleteMedicalDevice(item)" v-on="on">mdi-delete</v-icon>
                                </template>
                                <span>{{$t("delete")}}</span>
                              </v-tooltip>
                              </v-col>
                               <v-col cols="2">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon @click.stop="assignToTreatmentPlan(item)" v-if="!item.isThisDeviceAssignedToMonitoringPlan" v-on="on">mdi-arrow-right-bold</v-icon>
                                </template>
                                <span>{{$t("assign-device-in-to-monitoring-plan")}}</span>
                              </v-tooltip>
                               </v-col>
                              </v-row>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
              <v-col>
                <v-data-table
                    dense
                    :headers="headers2"
                    :items="monitoringDevice"
                    item-key="id"
                    class="elevation-1"
                    hide-default-footer
                    :single-expand="true"
                    :expanded.sync="expandedMonitoringItem"
                    :footer-props="{
        itemsPerPageText: this.$t('row'),
        itemsPerPageAllText: this.$t('all'),
      }"
                >
                  <template v-slot:top>
                    <v-row class="my-1">
                      <v-col class="col-4">
                        <span class="mx-2 d-flex justify-start" style="font-weight: bold;">{{$t("monitoring-plan")}}</span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <!--                        {{item}}-->
                    <td class="elevation-1" :colspan="headers.length">
                      <v-row class="mx-10 my-1">
                        <v-row class="my-1">
                          <v-select
                              hide-details
                              v-model="item.measurementsCount"
                              :items="selectiontimes"
                              item-text="name"
                              item-value="id"
                          ></v-select>
                        </v-row>
                        <v-row>
                        <v-col v-if="item.measurementsCount > 0" cols="6">
                          <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="item.time1"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  hide-details
                                  dense
                                  v-model="item.time1"
                                  v-bind:label="$t('time1')"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="menu1"
                                v-model="item.time1"
                                full-width
                                @click:minute="$refs.menu1.save(item.time1)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>

                          <v-col v-if="item.measurementsCount > 1" cols="6">
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="item.time2"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details
                                    dense
                                    v-model="item.time2"
                                    v-bind:label="$t('time2')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  v-if="menu2"
                                  v-model="item.time2"
                                  full-width
                                  @click:minute="$refs.menu2.save(item.time2)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>

                          <v-col v-if="item.measurementsCount > 2" cols="6">
                            <v-menu
                                ref="menu3"
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="item.time3"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details
                                    dense
                                    v-model="item.time3"
                                    v-bind:label="$t('time3')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  v-if="menu3"
                                  v-model="item.time3"
                                  full-width
                                  @click:minute="$refs.menu3.save(item.time3)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>

                          <v-col v-if="item.measurementsCount > 3" cols="6">
                            <v-menu
                                ref="menu4"
                                v-model="menu4"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="item.time4"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details
                                    dense
                                    v-model="item.time4"
                                    v-bind:label="$t('time4')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  v-if="menu4"
                                  v-model="item.time4"
                                  full-width
                                  @click:minute="$refs.menu4.save(item.time4)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-row>

                      <span class="d-flex justify-end">
                          <v-btn @click="saveTimers(item)" class="table-create-button ma-2"
                                 style="padding-left: 17px !important" color="primary">{{ $t("save") }}</v-btn>
                        </span>
                    </td>
                  </template>
                  <template v-slot:item="{ item, expand, isExpanded }">
                    <tr @click="() => expand(!isExpanded)">
                      <td class="d-flex align-start">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon @click.stop="deAssignToTreatmentPlan(item)" v-on="on">mdi-arrow-left-bold</v-icon>
                          </template>
                          <span>{{ $t("de-assign-device-from-monitoring-plan") }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-row v-if="$i18n.locale === 'en'">
                        {{item.deviceTypeTitle}}
                        </v-row>
                        <v-row v-else>
                          {{item.deviceTypeTitleEsp}}
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-6">
              <span class="grey--text" style="font-size: 14px">{{ $t("units-should-be") }}
                  <span class="primary--text" style="font-size: 14px">{{ concentration }}</span> {{ $t("for-glucose/cholesterol-and") }}
                  <span class="primary--text" style="font-size: 14px">{{ unit.toLowerCase() }}</span> {{ $t("for-temperature") }}</span>
              <v-spacer />
              <v-btn class="table-create-button" style="padding-left: 12px !important" @click="$emit('close')">
                  <span color="black--text" class="mx-1">{{ $t("close") }}</span>
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { letterM, letterA } from '@/assets';
import { fromTo } from '@/utils/unit-converter.js';

export default {
	components: {},

	props: ['dialog', 'currentDevice', 'commingType', 'video'],
	data () {
		return {
			letterM: letterM,
			letterA: letterA,
			assignDevices: [],
			createDropdownStatus: false,
			filteredDevices: [],
			createdevice: [],
			monitoringDevice: [],
			expandedMonitoringItem: [],
			expanded: [],
			headers: [
				{
					text: this.$t('device-type'),
					align: 'start',
					value: 'name',
				},
				{
					text: this.$t('manufacturer'),
					align: 'end',
					value: 'add',
				},
				{
					sortable: false,
					align: 'end',
					value: 'add',
				},
			],
			headers2: [
				{
					align: 'start',
					sortable: false,
					value: 'deviceTypeTitle',
					width: '0%',
				},
				{
					text: this.$t('device-type'),
					align: 'start',
					value: 'deviceTypeTitle',
				},
			],
			loader: null,
			loading: false,
			loadingForm: false,
			time1: null,
			menu1: false,
			modal1: false,
			time2: null,
			menu2: false,
			modal2: false,
			time3: null,
			menu3: false,
			modal3: false,
			time4: null,
			menu4: false,
			modal4: false,
			selectedDevice: null,
			treatmentSection: 0,
			deviceManufacturers: [],
			manufacturer: null,
			trustedHubIdentifier: null,
			selectiontimes: [
				{ name: this.$t('one-time-per-day'), id: 1 },
				{ name: this.$t('two-time-per-day'), id: 2 },
				{ name: this.$t('three-time-per-day'), id: 3 },
				{ name: this.$t('four-time-per-day'), id: 4 },
			],
		};
	},
	computed: {
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
			getRole: 'authentication/getRole',
		}),
		...mapState({
			authenticated: state => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
			devicetemplates: state => state.medicaldevice.deviceTemplates,
			// treamentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		concentration () {
			return this.userSettings?.Concentration;
		},
		unit () {
			return this.userSettings?.Units;
		},
		userData () {
			return this.detailedUser;
		},
		trustedHubs () {
			return this.userData?.trustedHubsData ?? [];
		},
		valid () {
			if ([1, 6].includes(this.deviceType)) {
				if ((this.value1 === '' || this.value1 === null) ||
        (this.value2 === '' || this.value2 === null)) {
					return true;
				}
				return false;
			} else if (this.deviceType === 3) {
				if ((this.value1 === '' || this.value1 === null) ||
        (this.value2 === '' || this.value2 === null) ||
        (this.value3 === '' || this.value3 === null) ||
        (this.value4 === '' || this.value4 === null) ||
        (this.value5 === '' || this.value5 === null) ||
        (this.value6 === '' || this.value6 === null)
				) {
					return true;
				}
				return false;
			} else if (this.deviceType === 7) {
				if ((this.value1 === '' || this.value1 === null) ||
        (this.value2 === '' || this.value2 === null) ||
        (this.value3 === '' || this.value3 === null) ||
        (this.value4 === '' || this.value4 === null)) {
					return true;
				}
				return false;
			} else if (this.deviceType === 2) {
				if ((this.value0 === '' || this.value0 === null) ||
        (this.value1 === '' || this.value1 === null) ||
        (this.value2 === '' || this.value2 === null) ||
        (this.value3 === '' || this.value3 === null) ||
        (this.value4 === '' || this.value4 === null)) {
					return true;
				}
				return false;
			}
			return false;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.save()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		async createdevice (val) {
			await this.$store.dispatch('medicaldevice/createMedicalDevice', val).then(() => {
				this.initialize();
			});
		},
		async manufacturer (val) {
			if (val) {
				await this.deviceTreatmentPlan(this.localDeviceManufacturer);
			}
		},
	},
	async mounted () {
		const header = { domainsList: 'MEDICAL_DEVICE_MANUFACTURER' };
		await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header).then(async () => {
			await this.initialize();
		});
	},
	methods: {
		async saveTimers (val) {
			await this.$store.dispatch('treatmentplan/updateDeviceTimes', val).then(async () => {
				await this.initialize();
			});
		},
		async assignToTreatmentPlan (val) {
			var body = {
				treatmentPlanId: val.treatmentPlanId,
				deviceType: val.deviceType,
				measurementPeriod: 'D',
				measurementsCount: 1,
				time1: '08:00',
			};
			await this.$store.dispatch('treatmentplan/assignDevice', body).then(() => {
				this.initialize();
			});
		},
		async deAssignToTreatmentPlan (val) {
			await this.$store.dispatch('treatmentplan/deassignDevice', val.id).then(() => {
				this.initialize();
			});
		},
		// deleteMedicalDevice: async function (val) {
		// 	var body = {
		// 		id: val.id,
		// 		patientId: this.detailedUser.userData.id,
		// 	};
		// 	await this.$store.dispatch('medicaldevice/deleteMedicalDevice', body).then(() => {
		// 		this.initialize();
		// 	}).catch((e) => {
		// 		this.$store.commit('alerts/add', {
		// 			id: 'addedHospital',
		// 			type: 'error',
		// 			color: 'main_red',
		// 			message: 'Forbidden operation. Contact administrator',
		// 		});
		// 		this.$store.dispatch('alerts/clearWithDelay', this.delay);
		// 	});
		// },
		deleteMedicalDevice: async function (val) {
			var body = {
				id: val.id,
				patientId: this.detailedUser.userData.id,
			};
			try {
				await this.$store.dispatch('medicaldevice/deleteMedicalDevice', body);
				this.initialize();
			} catch (error) {
				if (error.response && error.response.status === 403) {
					this.$store.commit('alerts/add', {
						id: 'addedHospital',
						type: 'error',
						color: 'main_red',
						// message: 'Forbidden operation. Contact administrator',
						// message: error.response.data.msg,
						message: this.$t('failed'),
					});
				} else {
					this.$store.commit('alerts/add', {
						id: 'addedHospital',
						type: 'error',
						color: 'main_red',
						// message: 'Forbidden operation. Contact administrator',
						// message: 'General error happen...',
						message: this.$t('failed'),
					});
				}
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		// async saveParameters (val) {
		// 	var body = {
		// 		deviceDefaultValuesJson: this.convertForStore(val),
		// 		patientId: this.detailedUser.userData.id,
		// 		deviceIdentifier: val.deviceIdentifier,
		// 	};
		// 	await this.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', body);
		// },
		async saveParameters (val) {
			const body = {
				deviceDefaultValuesJson: this.convertForStore(val),
				patientId: this.detailedUser.userData.id,
				deviceIdentifier: val.deviceIdentifier,
			};

			try {
				const response = await this.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', body);
				if (response.resFlag) {
					this.initialize();
				}
			} catch (error) {
				// console.error('Error while saving parameters:', error);
			}
		},

		stringFormater (val) {
			return this.$t(val);
		},
		unitFormater (val, item) {
			var units = '';
			switch (item.deviceType) {
			case 1:
				units = this.userSettings.Units.toLowerCase() === 'metric' ? '(°C)' : '(°F)';
				break;
			case 2:
				if (val.toLowerCase() === 'spo2') {
					units = '(%)';
				}
				break;
			case 3:
				if (val === 'SystolicMin') {
					units = '(mmHg)';
				}
				if (val === 'SystolicMax') {
					units = '(mmHg)';
				}
				if (val === 'SystolicMin') {
					units = '(mmHg)';
				}
				if (val === 'DiastolicMax') {
					units = '(mmHg)';
				}
				if (val === 'DiastolicMin') {
					units = '(mmHg)';
				}
				break;
			case 6:
				units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dL') + ')' : '(' + this.$t('glucose-mmol-L') + ')';
				break;
			case 7:
				units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dL') + ')' : '(' + this.$t('glucose-mmol-L') + ')';
				break;
			default:
				break;
			}

			return units;
		},

		// setValues (item, defaultValues) {
		// 	switch (item.lowValue) {
		// 	case 1:
		// 		if (this.userSettings.Units === 'IMPERIAL') {
		// 			this.value1 = defaultValues ? fromTo(defaultValues.MinTemperature, 'C', 'F').toFixed(2) : null;
		// 			this.value2 = defaultValues ? fromTo(defaultValues.MaxTemperature, 'C', 'F').toFixed(2) : null;
		// 		} else {
		// 			this.value1 = defaultValues ? defaultValues.MinTemperature : null;
		// 			this.value2 = defaultValues ? defaultValues.MaxTemperature : null;
		// 		}
		// 		break;
		// 	case 2:
		// 		this.value0 = defaultValues ? defaultValues.SpO2 : null;
		// 		this.value1 = defaultValues ? defaultValues.HeartBeatMin : null;
		// 		this.value2 = defaultValues ? defaultValues.HeartBeatMax : null;
		// 		this.value3 = defaultValues ? defaultValues.PiMin : null;
		// 		this.value4 = defaultValues ? defaultValues.PiMax : null;
		// 		break;
		// 	case 3:
		// 		this.value1 = defaultValues ? defaultValues.DiastolicMin : null;
		// 		this.value2 = defaultValues ? defaultValues.DiastolicMax : null;
		// 		this.value3 = defaultValues ? defaultValues.HeartBeatMin : null;
		// 		this.value4 = defaultValues ? defaultValues.HeartBeatMax : null;
		// 		this.value5 = defaultValues ? defaultValues.SystolicMin : null;
		// 		this.value6 = defaultValues ? defaultValues.SystolicMax : null;
		// 		break;
		// 	case 6:
		// 		if (this.userSettings.Concentration === 'mg/dl') {
		// 			this.value1 = defaultValues ? (defaultValues.BloodSugarLevelMin * 18).toFixed(2) : null;
		// 			this.value2 = defaultValues ? (defaultValues.BloodSugarLevelMax * 18).toFixed(2) : null;
		// 		} else {
		// 			this.value1 = defaultValues ? defaultValues.BloodSugarLevelMin : null;
		// 			this.value2 = defaultValues ? defaultValues.BloodSugarLevelMax : null;
		// 		}
		// 		break;
		// 	case 7:
		// 		if (this.userSettings.Concentration === 'mg/dl') {
		// 			this.value1 = defaultValues ? (defaultValues.BloodSugarLevelMin * 18).toFixed(2) : null;
		// 			this.value2 = defaultValues ? (defaultValues.BloodSugarLevelMax * 18).toFixed(2) : null;
		// 			this.value3 = defaultValues ? (defaultValues.CholesterolLevelMin * 38.67).toFixed(2) : null;
		// 			this.value4 = defaultValues ? (defaultValues.CholesterolLevelMax * 38.67).toFixed(2) : null;
		// 		} else {
		// 			this.value1 = defaultValues ? defaultValues.BloodSugarLevelMin : null;
		// 			this.value2 = defaultValues ? defaultValues.BloodSugarLevelMax : null;
		// 			this.value3 = defaultValues ? defaultValues.CholesterolLevelMin : null;
		// 			this.value4 = defaultValues ? defaultValues.CholesterolLevelMax : null;
		// 		}
		// 		break;
		// 	}
		// },

		convertForStore (item) {
			function parseNumbers (obj) {
				for (const key in obj) {
					if (!isNaN(obj[key])) {
						obj[key] = parseFloat(obj[key]);
					}
				}
				return obj;
			}

			if (item.deviceType === 1) {
				var temp = item.deviceDefaultValuesJson;
				if (this.userSettings.Units.toLowerCase() === 'imperial') {
					temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'F', 'C').toFixed(1);
					temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'F', 'C').toFixed(1);
				}
				return JSON.stringify(parseNumbers(temp));
			} if (item.deviceType === 6) {
				var gluco = item.deviceDefaultValuesJson;
				if (this.userSettings.Concentration === 'mmol/l') {
					return JSON.stringify(parseNumbers(gluco));
				}
				gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin / 18).toFixed(1);
				gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax / 18).toFixed(1);
				return JSON.stringify(parseNumbers(gluco));
			} if (item.deviceType === 7) {
				var glucoCol = item.deviceDefaultValuesJson;
				if (this.userSettings.Concentration === 'mmol/l') {
					return JSON.stringify(parseNumbers(glucoCol));
				}
				glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin / 18).toFixed(1);
				glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax / 18).toFixed(1);

				glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin / 38.67).toFixed(1);
				glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax / 38.67).toFixed(1);
				return JSON.stringify(parseNumbers(parseNumbers(glucoCol)));
			} else {
				return JSON.stringify(parseNumbers(item.deviceDefaultValuesJson));
			}

			// console.log(JSON.stringify(val.deviceDefaultValuesJson));
			// return JSON.stringify(val.deviceDefaultValuesJson);
		},

		convertedValue (item) {
			if (item.deviceType === 1) {
				var temp = JSON.parse(item.deviceDefaultValuesJson);
				if (this.userSettings.Units.toLowerCase() === 'imperial') {
					temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'C', 'F').toFixed(1);
					temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'C', 'F').toFixed(1);
				}
				return temp;
			} if (item.deviceType === 6) {
				var gluco = JSON.parse(item.deviceDefaultValuesJson);
				if (this.userSettings.Concentration === 'mmol/l') {
					return gluco;
				}
				gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin * 18).toFixed(1);
				gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax * 18).toFixed(1);
				return gluco;
			} if (item.deviceType === 7) {
				var glucoCol = JSON.parse(item.deviceDefaultValuesJson);
				if (this.userSettings.Concentration === 'mmol/l') {
					return glucoCol;
				}
				glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin * 18).toFixed(1);
				glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax * 18).toFixed(1);

				glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin * 38.67).toFixed(1);
				glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax * 38.67).toFixed(1);
				return glucoCol;
			} else {
				return JSON.parse(item.deviceDefaultValuesJson);
			}
		},

		async initialize () {
			this.assignDevices = [];
			this.monitoringDevice = [];
			this.filteredDevices = [];
			await this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(res => {
				res.data.medicalDevicesList[0].assignedMedicalDevicesList.forEach(item => {
					var manufacturerName = this.verifiersFromRepo.find(element => element.lowValue === item.manufacturerId).shortDescription;
					var tableElement = {
						treatmentPlanId: res.data?.patientActiveTreatmentPlan?.id ?? 0,
						id: item.id,
						deviceDefaultValuesJsonNames: Object.keys(JSON.parse(item.deviceDefaultValuesJson)),
						deviceDefaultValuesJson: this.convertedValue(item),
						name: item.deviceName,
						nameEsp: item.deviceNameEsp,
						deviceType: item.deviceType,
						manufacturer: manufacturerName,
						manufacturerId: item.manufacturerId,
						isManualMeasurement: item.isManualMeasurement,
						deviceIdentifier: item.deviceIdentifier,
						isThisDeviceAssignedToMonitoringPlan: item.isThisDeviceAssignedToMonitoringPlan,
					};
					this.assignDevices.push(tableElement);
				});
				this.trustedHubIdentifier = res.data.medicalDevicesList[0].hubIdentifier;
				if (res.data.patientActiveTreatmentPlan) {
					res.data.patientActiveTreatmentPlan.treatmentPlanDevicesList.forEach(item => {
						var monitoringElement = {
							id: item.id,
							deviceType: item.deviceType,
							measurementPeriod: item.measurementPeriod,
							measurementsCount: item.measurementsCount,
							treatmentPlanId: item.treatmentPlanId,
							deviceTypeTitle: item.deviceTypeTitle,
							deviceTypeTitleEsp: item.deviceTypeTitleEsp,
							time1: item.time1 ? item.time1 : '',
							time2: item.time2 ? item.time2 : '',
							time3: item.time3 ? item.time3 : '',
							time4: item.time4 ? item.time4 : '',
						};
						this.monitoringDevice.push(monitoringElement);
					});
				}
			});
			var filteredPossibleDevices = [];
			await this.$store.dispatch('medicaldevice/getPossibleDevices').then(res => {
				res.forEach(item => {
					var flag = false;
					this.assignDevices.forEach(element => {
						if (element.deviceType === item.deviceType && element.manufacturerId === item.manufacturerId) {
							flag = true;
							return false;
						}
					});
					if (!flag) {
						filteredPossibleDevices.push(item);
					}
				});
				filteredPossibleDevices.forEach(item => {
					var	itemsSelect = {
						body: {
							deviceType: item.deviceType,
							manufacturerId: item.manufacturerId,
							manufacturerName: item.manufacturerName,
							// hubIdentifier: this.trustedHubs[0]?.hubIdentifier,
							hubIdentifier: this.trustedHubIdentifier,
							deviceName: item.deviceName,
						},
						deviceName: item.deviceName + ' (' + item.manufacturerName + ')',
					};
					this.filteredDevices.push(itemsSelect);
				});
			});
			// this.deviceType = this.currentDevice.lowValue
		},
		async isManual (val) {
			var body = {
				patientId: this.detailedUser.userData.id,
				val: val,
			};
			await this.$store.dispatch('medicaldevice/updateIsManualMeasurementForMedicalDevice', body).then(async () => {
				this.initialize();
			});
		},
		async addDevice () {
			this.createDropdownStatus = true;
		},
	},
};
</script>
<style>
.small-icon {
width: 15px; /* set the width of the image */
height: 15px; /* set the height of the image */
}
</style>
