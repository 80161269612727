<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      class="dialog-style"
  >
    <v-card style="overflow: hidden">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{ patient.userData.firstName }} {{ patient.userData.lastName }}&nbsp;</span>
        <span class="dialog-title" v-if="this.show === 'allergies'">{{ $t("allergies") }}</span>
        <span class="dialog-title" v-if="this.show === 'homeMed'">{{ $t("home-med") }}</span>
        <span class="dialog-title" v-if="this.show === 'problemList'">{{ $t("problem-list") }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
      </v-card-title>
      <v-form>
        <v-card flat style="overflow: hidden">
          <template>
            <vue-editor v-if="this.show === 'allergies'" style="height: 500px" v-model="alergies" :editorToolbar="customToolbar" :editorOptions="editorOptions" @selection-change="onSelectionChange">
            </vue-editor>
            <vue-editor v-if="this.show === 'homeMed'" style="height: 500px" v-model="prescriptions" :editorToolbar="customToolbar" :editorOptions="editorOptions" @selection-change="onSelectionChange">
            </vue-editor>
            <vue-editor v-if="this.show === 'problemList'" style="height: 500px" v-model="diagnose" :editorToolbar="customToolbar" :editorOptions="editorOptions" @selection-change="onSelectionChange">
              <div>
                getDiagnoseCategory
              </div>
            </vue-editor>
          </template>
        </v-card>
        <div class="my-4 mx-2" style="float: right;">
          <v-btn class="table-cancel-button " @click="$emit('false')">
            <span color="black--text">{{ $t("cancel") }}</span>
          </v-btn>
          <v-btn
              class="table-create-button mx-2"
              :disabled = saveButtonDisabled
              @click="updatePatientMetaData">
            <span>{{ $t("save") }}</span>
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapGetters, mapState } from 'vuex';
// import patientmetadata from '@/data/store/modules/patientmetadata';

export default {
	components: {
		VueEditor,
	},
	props: ['dialog', 'patient', 'show', 'edit'],
	data () {
		return {
			categoryLowValue: '',
			saveButtonDisabled: true,
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4] }],
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
				[{ align: '' },
					{ align: 'center' },
					{ align: 'justify' }],
			],
			editorOptions: {
				modules: {
					toolbar: false,
				},
			},
			prescriptions: '',
			diagnose: '',
			diagnosesCategory: [],
			alergies: '',
			lowValue: '',
			selectedVerifier: -1,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			getDiagnoseCategory: 'patientmetadata/getDiagnoseCategory',
		}),
		headers () {
			const headers = [
				{ text: 'ICD-10', sortable: false, value: 'ICD_10', class: 'color_header pl-9' },
				{ text: this.$t('diagnoses'), sortable: false, value: 'diagnose', class: 'color_header' },
			];
			return headers;
		},
	},
	async mounted () {
		// this.diagnose = this.patient.userData.patientMetadata.diagnose;
		this.prescriptions = this.patient.userData.patientMetadata.prescriptions;
		this.alergies = this.patient.userData.patientMetadata.metadataInfo;
		// await this.getVerifiersForDomain();
		// await this.getDiagnosesByCategory();
		// await this.getDeviceTypes();
	},
	methods: {
		onSelectionChange () {
			this.saveButtonDisabled = false;
		},
		async updatePatientMetaData () {
			const metaDataBody = {
				userId: this.patient.userData.id,
			};
			switch (this.show) {
			case 'problemList':
				metaDataBody.diagnose = this.diagnose;
				break;
			case 'homeMed':
				metaDataBody.prescriptions = this.prescriptions;
				break;
			case 'allergies':
				metaDataBody.metadataInfo = this.alergies;
				break;
			}
			try {
				await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$emit('false');
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} catch (error) {
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'error',
					color: 'main_red',
					// message: error.message,
					message: this.$t('failed'),
				});
			}
		},
	},
};
</script>
