<template>
	<div data-app>
		<v-container v-if="item.deviceType !== 7" ref="content" >
				<v-card-title class="py-1 my-n2" @click="item.hide ? $emit('show') : $emit('hide')" style="cursor: pointer; background-color:#E8E8E8; border-radius:8px;">
					<v-row dense>
							<v-col cols="4" class="d-flex justify-start" style="font-size: 14px;"> {{ deviceNameLocalizationlanguage }} </v-col>
							<v-col cols="7" class="d-flex justify-start">
								<span style="font-size: 13px !important;">{{ lastMeasuredData(item.deviceType) }}</span>
								<span style="font-size: 14px !important;" v-if="lastMeasuredTime ">( {{lastMeasuredTime}} )</span>
							</v-col>
							<v-col cols="1" class="d-flex justify-end">
								<v-icon color="primary" v-if="item.hide">mdi-arrow-up-drop-circle</v-icon>
								<v-icon color="primary" v-else>mdi-arrow-down-drop-circle</v-icon>
							</v-col>
					</v-row>
				</v-card-title>
				<div v-if="item.hide" >
          <div style="overflow-y: scroll">
					<v-card-text >
						<v-row >
							<v-col cols="12" lg="12">
								<!-- Thermometer Section -->
								<div v-if="item.deviceType === 1 && item.measurementsData.length !== 0">
									<v-card-text >
										<v-row dense justify="center">
											<v-col cols="12" lg="6" xs="2" sm="2" md="2">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
													<span class="primary--text" style="font-size:15px;">{{ minValues.value }}<span v-html="minValues.unit === 'c' ? ` ${celsius}` : ` ${fahrenheit}`"></span> - {{ minValues.date }}</span>
												</span>
											</v-col>
											<v-col cols="12" lg="6" xs="2" sm="2" md="2">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
												<span class="primary--text" style="font-size:15px;">{{ maxValues.value }}<span v-html="minValues.unit === 'c' ? ` ${celsius}` : ` ${fahrenheit}`"></span> - {{ maxValues.date }}</span></span>
											</v-col>
										</v-row>
									</v-card-text>
								</div>
								<!-- End of Thermometer Section -->

								<!-- Oximeter Section -->
								<v-card v-if="item.deviceType === 2 && item.measurementsData.length !== 0" flat>
									<v-card-text>
										<v-row dense>
										<v-col cols="12" lg="6" xs="2" sm="2" md="2" class="mb-2">
											<span style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
												<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ minValues.secondValue }} SpO2 {{ minValues.value }} bpm PI {{ (minValues.thirdValue).toFixed(2) }}% - {{ minValues.date }}
												</span>
											</span>
										</v-col>
										<v-col cols="12" lg="6" xs="4" sm="4" md="4" class="mb-2">
											<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
												<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ maxValues.secondValue }} SpO2 {{ maxValues.value }} bpm PI {{ (maxValues.thirdValue).toFixed(2) }}% - {{ maxValues.date }}</span>
											</span>
										</v-col>
										</v-row>
									</v-card-text>
								</v-card>
								<!-- End of Oximeter Section -->

								<!-- Blood Pressure Section -->
								<v-card v-if="item.deviceType === 3 && item.measurementsData.length !== 0" flat>
									<v-card-text>
										<v-row dense>
											<v-col cols="12" lg="6" xs="12" sm="12" md="12">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
													<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ minValues.value }}/{{ minValues.secondValue }} mmHg {{ minValues.thirdValue }} bpm - {{ minValues.date }}</span>
												</span>
											</v-col>
											<v-col cols="12" lg="6" xs="12" sm="12" md="12">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
													<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ maxValues.value }}/{{ maxValues.secondValue }} mmHg {{ maxValues.thirdValue }} bpm - {{ maxValues.date }}</span>
												</span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
								<!-- End of Blood Pressure Section -->

								<!-- Weight Scale Section -->
								<v-card v-if="item.deviceType === 5 && item.measurementsData.length !== 0" flat>
									<v-card-text>
										<v-row dense>
											<v-col cols="12" lg="6" xs="12" sm="12" md="12">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
													<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ (minValues.value).toFixed(2) }} {{ minValues.unit }} - {{ minValues.date }}</span>
												</span>
											</v-col>
											<v-col cols="12" lg="6" xs="12" sm="12" md="12">
												<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
													<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ (maxValues.value).toFixed(2) }} {{ minValues.unit }} - {{ maxValues.date }}</span>
												</span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
								<!-- End of Scale Section -->

								<!-- Glucose Section -->
								<v-card v-if="item.deviceType === 6 && item.measurementsData.length !== 0" flat>
									<v-card-text>
										<v-row dense justify="center">
											<v-row>
												<v-col cols="12" lg="6" xs="12" sm="12" md="12">
													<span  style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
														<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ minValues.value }} {{ minValues.unit }} - {{ minValues.date }}</span>
													</span>
												</v-col>
												<v-col cols="12" lg="6" xs="12" sm="12" md="12">
													<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
														<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ maxValues.value }} {{ minValues.unit }} - {{ maxValues.date }}</span>
													</span>
												</v-col>
											</v-row>
										</v-row>
									</v-card-text>
								</v-card>
								<!-- End of Glucose Section -->

								<!-- Cholesterol Section -->
								<v-card v-if="item.deviceType === 7 && item.measurementsData.length !== 0" flat>
									<v-card-text>
										<v-row dense justify="center">
											<v-row>
												<v-col cols="12" lg="6" xs="12" sm="12" md="12">
													<span style="color: grey; font-size: 16px" class="mb-2">{{$t('min')}}:
														<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ minValues.value }} {{ minValues.unit }} - {{ minValues.date }}</span>
													</span>
												</v-col>
												<v-col cols="12" lg="6" xs="12" sm="12" md="12">
													<span style="color: grey; font-size: 16px" class="mb-2">{{$t('max')}}:
														<span style="font-weight: 500; font-size: 15px;" class="primary--text">{{ maxValues.value }} {{ minValues.unit }} - {{ maxValues.date }}</span>
													</span>
												</v-col>
											</v-row>
										</v-row>
									</v-card-text>
								</v-card>
								<!-- End of Cholesterol Section -->
							</v-col>
						</v-row>
						<v-spacer></v-spacer>
						<v-row>
							<v-col v-if="item.deviceType !== 2 && item.deviceType !== 3">
								<GoogleLine
								v-if="item.measurementsData.length !== 0"
								style="z-index: 0"
								:googleChartData="googleChartData"
								:maxValues="maxValues.value"
								:minValues="minValues.value"
								/>
							</v-col>
<!--							<v-col v-if="item.deviceType === 3">-->
<!--								<GoogleCandleStick-->
<!--								v-if="item.measurementsData.length !== 0"-->
<!--								style="z-index: 0"-->
<!--								:googleChartData="bloodPressureData"-->
<!--								:maxValues="maxValues.value"-->
<!--								:minValues="minValues.value"-->
<!--								/>-->
<!--							</v-col>-->
							<div style="width:100%;" v-if="item.deviceType === 2">
								<GoogleLine
								v-if="item.measurementsData.length !== 0"
								style="z-index: 0"
								:googleChartData="dataForSpo2"
								:maxValues="maxValues.value"
								:minValues="minValues.value"
								/>
							</div>
							<div style="width:100%;" v-if="item.deviceType === 2">
								<GoogleLine
								v-if="item.measurementsData.length !== 0"
								style="z-index: 0"
								:googleChartData="dataForBpm"
								:maxValues="maxValues.value"
								:minValues="minValues.value"
								/>
							</div>
							<v-col cols="12" v-if="item.deviceType === 2">
								<GoogleLine
								v-if="item.measurementsData.length !== 0"
								style="z-index: 0"
								:googleChartData="dataForPi"
								:maxValues="maxValues.value"
								:minValues="minValues.value"
								/>
							</v-col>
						</v-row>
						<v-row v-if="item.measurementsData.length !== 0" style="margin-top: 15px">
							<v-col>
								<v-data-table
								:headers="measurementsHeaders"
								:items="measuredData"
								:no-data-text="$t('no-data-available')"
								:loading-text="$t('loading')"
								:items-per-page="-1"
								dense
                class="elevation-3"
                :height="480"
								style="cursor: pointer; overflow-y: auto;"
                fixed-header
                :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
								>
								<!-- Your template or slots here -->
								</v-data-table>
							</v-col>
							<v-col v-if="deviceType !== 5">
								<MeasurementReferences :item="item" />
							</v-col>
							</v-row>
						<div v-if="item.measurementsData.length === 0" style="margin-top:10px;">
							<span style="color:#6600FF;font-size:15px;">{{$t('noDataHaveBeenMeasuredUntilNow')}}</span>
						</div>
					</v-card-text>
          </div>
				</div>

		</v-container>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
// import { formatterFilter } from '@/utils/luxon-formater';
import { fromTo } from '@/utils/unit-converter.js';
// import { DateTime } from 'luxon';
import { formatterFilter } from '@/utils/luxon-formater';
import GoogleLine from './GoogleLine.vue';
// import GoogleCandleStick from './GoogleCandleStick.vue';
import MeasurementReferences from '@/views/Client/components/MeasurementReferences.vue';
import ValueObject from '@/data/models/RenderValues.js';

export default {
	components: {
		GoogleLine,
		// GoogleCandleStick,
		MeasurementReferences,
	},
	props: ['item', 'dates', 'idealWeight'],
	data () {
		return {
			fahrenheit: '&deg;F',
			celsius: '&deg;C',
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
		}),
		lastMeasuredTime () {
			// return DateTime.fromISO(this.measuredData[this.measuredData.length - 1]?.measuredAt).toRelative();
			// console.log('timeAgo(this.measuredData[this.measuredData.length - 1]?.measuredAt)', timeAgo(this.measuredData[this.measuredData.length - 1]?.measuredAt));
			if (this.measuredData[this.measuredData.length - 1]?.measuredAt !== undefined || this.measuredData[this.measuredData.length - 1]?.measuredAt != null) {
				var result = this.timeAgo(this.measuredData[this.measuredData.length - 1]?.measuredAt);
				return result.intervalValue + ' ' + this.$t(result.intervalType);
			} else {
				return '';
			}
		},
		deviceNameLocalizationlanguage () {
			return this.$i18n.locale === 'en' ? this.item.deviceName : this.item.deviceNameEsp;
		},
		measurementsHeaders () {
			var headers = [
				{ text: this.$t('measured-at'), value: 'measuredAtFormatted', width: '35%' },
			];
			switch (this.item.deviceType) {
			case 1:
				headers.push(
					{ text: this.$t('temperature-in-celsius'), value: 'Temperature_C' },
					{ text: this.$t('temperature-in-fahrenheit'), value: 'Temperature_F' },
				);
				break;
			case 2:
				headers.push(
					{ text: 'BPM', value: 'BPM' },
					{ text: 'SpO2', value: 'Spo2' },
					{ text: this.$t('pulse-index'), value: 'PI' },
				);
				break;
			case 3:
				headers.push(
					{ text: this.$t('systolic'), value: 'Sys' },
					{ text: this.$t('diastolic'), value: 'Dias' },
					{ text: this.$t('pulse'), value: 'Pul' },
				);
				break;
			case 5:
				headers.push(
					{ text: this.$t('weight-in-kg'), value: 'weight_kg' },
					{ text: this.$t('weight-in-lbs'), value: 'weight_lbs' },
				);
				break;
			case 6:
				headers.push(
					{ text: this.$t('glucose-mg-dL'), value: 'glucose_mgL' },
					{ text: this.$t('glucose-mmol-L'), value: 'glucose_mmolL' },
					{ text: this.$t('glucose-value-befor-meal'), value: 'translation' },
				);
				break;
			case 7:
				headers.push(
					{ text: this.$t('cholesterol-mg-dL'), value: 'cholesterol_mgL' },
					{ text: this.$t('cholesterol-mmol-L'), value: 'cholesterol_mmolL' },
				);
				break;
			}
			return headers;
		},
		measurements () {
			return this.item.measurementsData;
		},
		deviceType () {
			return this.item.deviceType;
		},
		/**
			* 1: {"MeasurementStatus":1,"TemperatureBodyType":2,"Temperature_C":36.6,"Temperature_F":97.9,"device_id":"d6df7bf5-5755-43c4-b44a-3a056ae76f43","device_type":"1"}
			* 2: {"BPM":85,"MeasurementStatus":1,"PI":8.5,"device_id":"7f6d42ad-a6b7-4087-a82d-1a4c9fce332c","device_type":"2","Spo2":94}
			* 3: {"Dias":10,"MeasurementStatus":0,"Measurement_Status":0,"PAD_Detected":0,"Pul":10,"Sys":5,"device_id":"E3:F0:01:00:18:72","device_type":"3"}
			* 5: {"MeasurementStatus":1,"device_id":"A4:C1:38:CF:13:70","device_type":"5","impedance_status":2,"isbattery_low":0,"measurement_unit":1,"weight":75.0,"weight_kg":75.0,"weight_lbs":165.4}
			* 5: {"MeasurementStatus":1,"device_id":"A4:C1:38:CF:13:70","device_type":"5","impedance_status":2,"isbattery_low":0,"measurement_unit":1,"weight":75.0,"weight_kg":75.0,"weight_lbs":165.4}
			* 5a: {"basal_metabolis":1631,"bmi":23.3,"body_age":33,"device_id":"A4:C1:38:CF:13:70","device_type":"5","fat_rate":20.8,"impedance":490,"impedance_status":3,"isbattery_low":0,"measurement_unit":0,"muscle":75.0,"protein_rate":20.4,"subcutaneous_fat":18.6,"visceral_fat":8.0,"water_rate":54.8,"weight":74.1,"weight_kg":74.1,"weight_lbs":163.4,"wheight_bone":3.0}
			* 6: {"MeasurementStatus":1,"device_id":"7bf48612-e2e8-413c-b68b-6877af4f981c","device_type":"6","glucose_mgL":50.0,"glucose_mmolL":2.8,"isMeasuredBeforeMeal":true,"time":"2023-05-27T18:52:29.295"}
			* 7: {"MeasurementStatus":1,"cholesterol_mgL":180.0,"cholesterol_mmolL":10.0,"device_id":"B4:52:A9:C8:68:78","device_type":"7","glucose_mgL":0.0,"glucose_mmolL":0.0}
		 */
		measuredData () {
			const values = [];

			if (this.measurements.length > 0) {
				this.measurements.forEach((data) => {
					const parsedData = JSON.parse(data.measuredDataJson);

					switch (parsedData.device_type) {
					case '1':
						parsedData.Temperature_C = Number(parsedData.Temperature_C.toFixed(1));
						parsedData.Temperature_F = Number(parsedData.Temperature_F.toFixed(1));
						break;
					case '2':
						parsedData.Spo2 = Number(parsedData.Spo2.toFixed(0));
						parsedData.PI = Number(parsedData.PI.toFixed(1));
						parsedData.BPM = Number(parsedData.BPM.toFixed(0));
						break;
					case '3':
						parsedData.Dias = Number(parsedData.Dias.toFixed(0));
						parsedData.Sys = Number(parsedData.Sys.toFixed(0));
						parsedData.Pul = Number(parsedData.Pul.toFixed(1));
						break;
					case '5':
						parsedData.weight_kg = Number(parsedData.weight_kg.toFixed(1));
						parsedData.weight_lbs = Number(parsedData.weight_lbs.toFixed(1));
						break;
					case '6':
						parsedData.glucose_mmolL = Number(parsedData.glucose_mmolL.toFixed(1));
						parsedData.glucose_mgL = Number(parsedData.glucose_mgL.toFixed(1));
						if (parsedData.isMeasuredBeforeMeal) {
							parsedData.translation = this.$t('before');
						} else {
							parsedData.translation = this.$t('after');
						}
						break;
					case '7':
						parsedData.cholesterol_mgL = Number(parsedData.cholesterol_mgL.toFixed(2));
						parsedData.cholesterol_mmolL = Number(parsedData.cholesterol_mmolL.toFixed(2));
						parsedData.glucose_mgL = Number(parsedData.glucose_mgL.toFixed(2));
						parsedData.glucose_mmolL = Number(parsedData.glucose_mmolL.toFixed(2));
						break;
					default:
						// Handle default case or unknown device_type
						// ...
						break;
					}
					parsedData.deviceType = data.deviceType;
					parsedData.measuredAt = data.measuredAtUtc;
					parsedData.measuredAtFormatted = formatterFilter(data.measuredAtUtc, 'datetime_med', this.timeLocalization, null);

					values.push(parsedData);
				});
			}
			return values.reverse();
		},
		bloodPressureData () {
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			// const data = [[this.$t('date'), this.$t('diastolic-min') + ' - ' + this.$t('systolic-max'), this.$t('diastolic'), this.$t('systolic'), this.$t('systolic-max')]];
			const data = [[this.$t('date'), this.$t('diastolic-min') + ' - ' + this.$t('systolic-max'), this.$t('diastolic'), this.$t('systolic'), this.$t('systolic-max')]];
			if (this.item.deviceType === 3) {
				this.measuredData.filter((c) => {
					const arr = [formatterFilter(c.measuredAt, 'date', this.timeLocalization), values.DiastolicMin, c.Dias, c.Sys, values.SystolicMax];
					data.push(arr);
				});
			}
			return data;
		},
		googleChartData () {
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			switch (this.item.deviceType) {
			case 1:
				var data = [[this.$t('date'), this.$t('value'), this.$t('min'), this.$t('max')]];
				this.measuredData.filter((c) => {
					const arr = [
						formatterFilter(c.measuredAt, 'date', this.timeLocalization),
						this.userSettings.Units.toLowerCase() === 'metric' ? c.Temperature_C : c.Temperature_F,
						this.userSettings.Units.toLowerCase() === 'metric' ? values.MinTemperature : fromTo(values.MinTemperature, 'C', 'F'), // based on user settings!!!
						this.userSettings.Units.toLowerCase() === 'metric' ? values.MaxTemperature : fromTo(values.MaxTemperature, 'C', 'F'),
					];
					data.push(arr);
				});
				break;
			case 5:
				data = [[this.$t('date'), this.$t('value')]];
				this.measuredData.filter((c) => {
					const arr = [formatterFilter(c.measuredAt, 'date', this.timeLocalization), this.userSettings.Units.toLowerCase() === 'metric' ? c.weight_kg : c.weight_lbs];
					data.push(arr);
				});
				break;
			case 6:
				data = [[this.$t('date'), this.$t('value'), this.$t('min'), this.$t('max')]];
				this.measuredData.filter((c) => {
					const arr = [
						formatterFilter(c.measuredAt, 'date', this.timeLocalization),
						this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? c.glucose_mmolL : c.glucose_mgL,
						this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.BloodSugarLevelMin * 18 : values.BloodSugarLevelMin,
						this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.BloodSugarLevelMax * 18 : values.BloodSugarLevelMax,
					];
					data.push(arr);
				});
				break;
			case 7:
				data = [[this.$t('date'), this.$t('value'), this.$t('min'), this.$t('max')]];
				this.measuredData.filter((c) => {
					const arr = [
						formatterFilter(c.measuredAt, 'date', this.timeLocalization),
						this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? c.cholesterol_mmolL : c.cholesterol_mgL,
						this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.CholesterolLevelMin / 0.02586 : values.CholesterolLevelMin,
						this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.CholesterolLevelMax / 0.02586 : values.CholesterolLevelMax,
					];
					data.push(arr);
				});
				break;
			default:
				break;
			}
			return data;
		},
		dataForBpm () {
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			var data = [[this.$t('date'), 'B' +
      'PM', 'BPM Min', 'BPM Max']];
			this.measuredData.filter((c) => {
				const arr = [
					formatterFilter(c.measuredAt, 'date', this.timeLocalization),
					c.BPM,
					values.HeartBeatMin,
					values.HeartBeatMax,
				];
				data.push(arr);
			});
			return data;
		},
		dataForSpo2 () {
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			var data = [[this.$t('date'), 'SpO2', 'SpO2 Ref']];
			this.measuredData.filter((c) => {
				const arr = [
					formatterFilter(c.measuredAt, 'date', this.timeLocalization),
					c.Spo2,
					values.SpO2,
				];
				data.push(arr);
			});
			return data;
		},
		dataForPi () {
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			var data = [[this.$t('date'), 'PI', 'PI Min', 'PI Max']];
			this.measuredData.filter((c) => {
				const arr = [
					formatterFilter(c.measuredAt, 'date', this.timeLocalization),
					c.PI,
					values.PiMin,
					values.PiMax,
				];
				data.push(arr);
			});
			return data;
		},
		filterValues () {
			const measurementsFiltered = [];
			this.measuredData.filter((c) => {
				switch (c.device_type) {
				case '1':
					measurementsFiltered.push(new ValueObject(this.userSettings.Units.toLowerCase() === 'metric' ? c.Temperature_C : c.Temperature_F, c.device_type, c.measuredAtFormatted, this.userSettings.Units.toLowerCase() === 'metric' ? 'c' : 'f'));
					break;
				case '2':
					measurementsFiltered.push({ value: c.BPM, dev: c.device_type, date: c.measuredAtFormatted, secondValue: c.Spo2, thirdValue: c.PI });
					break;
				case '3':
					measurementsFiltered.push(new ValueObject(c.Dias, c.device_type, c.measuredAtFormatted, null, c.Sys, c.Pul));
					break;
				case '5':
					measurementsFiltered.push(new ValueObject(this.userSettings.Units.toLowerCase() === 'metric' ? c.weight_kg : c.weight_lbs, c.device_type, c.measuredAtFormatted, this.userSettings.Units.toLowerCase() === 'metric' ? 'kg' : 'lbs'));
					break;
				case '6':
					measurementsFiltered.push(new ValueObject(this.userSettings.Concentration === 'mmol/l' ? c.glucose_mmolL : c.glucose_mgL, c.device_type, c.measuredAtFormatted, this.userSettings.Concentration === 'mmol/l' ? 'mmol/L' : 'mg/dL'));
					break;
				case '7':
					measurementsFiltered.push(new ValueObject(this.userSettings.Concentration === 'mmol/l' ? c.cholesterol_mmolL : c.cholesterol_mgL, c.device_type, c.measuredAtFormatted, this.userSettings.Concentration === 'mmol/l' ? 'mmol/L' : 'mg/dL'));
					break;
				}
			});
			return measurementsFiltered;
		},
		minValues () {
			const tempArray = this.filterValues;
			if (this.deviceType === 3) {
				var minFound = Math.min.apply(Math, tempArray.map(function (o) { return o.value; }));
				var minFoundSecondValue = Math.min.apply(Math, tempArray.map(function (o) { return o.secondValue; }));
				var minFoundThirdValue = Math.min.apply(Math, tempArray.map(function (o) { return o.thirdValue; }));
				var secondValueDate = this.measuredData.filter((c) => { return c.Sys === minFoundSecondValue; });
				var thirdValueDate = this.measuredData.filter((c) => { return c.Pul === minFoundThirdValue; });
				var returnedObject = tempArray.filter((c) => c.value === minFound);
				returnedObject[0].secondValue = minFoundSecondValue;
				returnedObject[0].thirdValue = minFoundThirdValue;
				returnedObject[0].secondValueDate = secondValueDate[0].measuredAtFormatted;
				returnedObject[0].thirdValueDate = thirdValueDate[0].measuredAtFormatted;
				return returnedObject[0];
			} else if (this.deviceType === 2) {
				minFound = Math.min.apply(Math, tempArray.map(function (o) { return o.value; }));
				minFoundSecondValue = Math.min.apply(Math, tempArray.map(function (o) { return o.secondValue; }));
				minFoundThirdValue = Math.min.apply(Math, tempArray.map(function (o) { return o.thirdValue; }));
				secondValueDate = this.measuredData.filter((c) => { return c.Spo2 === minFoundSecondValue; });
				thirdValueDate = this.measuredData.filter((c) => { return c.PI === minFoundThirdValue; });
				returnedObject = tempArray.filter((c) => c.value === minFound);
				returnedObject[0].secondValue = minFoundSecondValue;
				returnedObject[0].thirdValue = minFoundThirdValue;
				returnedObject[0].secondValueDate = secondValueDate[0].measuredAtFormatted;
				returnedObject[0].thirdValueDate = thirdValueDate[0].measuredAtFormatted;
				return returnedObject[0];
			} else {
				minFound = Math.min.apply(Math, tempArray.map(function (o) { return o.value; }));
				returnedObject = tempArray.filter((c) => c.value === minFound);
			}
			return returnedObject[0];
		},
		maxValues () {
			const tempArray = this.filterValues;
			if (this.deviceType === 3) {
				var maxFound = Math.max.apply(Math, tempArray.map(function (o) { return o.value; }));
				var maxFoundSecondValue = Math.max.apply(Math, tempArray.map(function (o) { return o.secondValue; }));
				var maxFoundThirdValue = Math.max.apply(Math, tempArray.map(function (o) { return o.thirdValue; }));
				var secondValueDate = this.measuredData.filter((c) => { return c.Sys === maxFoundSecondValue; });
				var thirdValueDate = this.measuredData.filter((c) => { return c.Pul === maxFoundThirdValue; });
				var returnedObject = tempArray.filter((c) => c.value === maxFound);
				returnedObject[0].secondValue = maxFoundSecondValue;
				returnedObject[0].thirdValue = maxFoundThirdValue;
				returnedObject[0].secondValueDate = secondValueDate[0].measuredAtFormatted;
				returnedObject[0].thirdValueDate = thirdValueDate[0].measuredAtFormatted;
				return returnedObject[0];
			} else if (this.deviceType === 2) {
				maxFound = Math.max.apply(Math, tempArray.map(function (o) { return o.value; }));
				maxFoundSecondValue = Math.max.apply(Math, tempArray.map(function (o) { return o.secondValue; }));
				maxFoundThirdValue = Math.max.apply(Math, tempArray.map(function (o) { return o.thirdValue; }));
				secondValueDate = this.measuredData.filter((c) => { return c.Spo2 === maxFoundSecondValue; });
				thirdValueDate = this.measuredData.filter((c) => { return c.PI === maxFoundThirdValue; });
				returnedObject = tempArray.filter((c) => c.value === maxFound);
				returnedObject[0].secondValue = maxFoundSecondValue;
				returnedObject[0].thirdValue = maxFoundThirdValue;
				returnedObject[0].secondValueDate = secondValueDate[0].measuredAtFormatted;
				returnedObject[0].thirdValueDate = thirdValueDate[0].measuredAtFormatted;
				return returnedObject[0];
			} else {
				maxFound = Math.max.apply(Math, tempArray.map(function (o) { return o.value; }));
				returnedObject = tempArray.filter((c) => c.value === maxFound);
			}
			return returnedObject[0];
		},
	},
	methods: {
		timeAgo (date) {
			if (!date) {
				return;
			}
			if (typeof date !== 'object') {
				date = new Date(date);
			}

			var seconds = Math.floor((new Date() - date) / 1000);
			var intervalType;

			var interval = Math.floor(seconds / 31536000);
			if (interval >= 1) {
				intervalType = 'year';
			} else {
				interval = Math.floor(seconds / 2592000);
				if (interval >= 1) {
					intervalType = 'month';
				} else {
					interval = Math.floor(seconds / 86400);
					if (interval >= 1) {
						intervalType = 'day';
					} else {
						interval = Math.floor(seconds / 3600);
						if (interval >= 1) {
							intervalType = 'hour';
						} else {
							interval = Math.floor(seconds / 60);
							if (interval >= 1) {
								intervalType = 'minute';
							} else {
								interval = seconds;
								intervalType = 'second';
							}
						}
					}
				}
			}
			if (interval > 1 || interval === 0) {
				intervalType += 's';
			}
			var temp = intervalType + ' ago';
			var result = {
				intervalValue: interval,
				intervalType: temp,
			};
			return result;
		},
		defaultValues (type) {
			var val = {};
			var values = JSON.parse(this.item.deviceDefaultValuesJson);
			switch (this.item.deviceType) {
			case 1:
				val.min = this.userSettings.Units.toLowerCase() === 'metric' ? values.MinTemperature : fromTo(values.MinTemperature, 'C', 'F').toFixed(2);
				val.max = this.userSettings.Units.toLowerCase() === 'metric' ? values.MaxTemperature : fromTo(values.MaxTemperature, 'C', 'F').toFixed(2);
				break;
			case 2:
				if (type === 'Spo2') {
					val.min = values.SpO2;
					val.max = 100;
				} else if (type === 'HeartBeat') {
					val.min = values.HeartBeatMin;
					val.max = values.HeartBeatMax;
				} else if (type === 'Pi') {
					val.min = values.PiMin;
					val.max = values.PiMax;
				}
				break;
			case 3:
				if (type === 'Systolic') {
					val.min = values.DiastolicMin;
					val.max = values.SystolicMax;
				} else if (type === 'HeartBeat') {
					val.min = values.HeartBeatMin;
					val.max = values.HeartBeatMax;
				}
				break;
			case 5:
				val.ideal = this.userSettings.Units.toLowerCase() === 'metric' ? this.idealWeight : this.idealWeight * 2.20462;
				break;
			case 6:
				val.min = this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.BloodSugarLevelMin * 18 : values.BloodSugarLevelMin;
				val.max = this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.BloodSugarLevelMax * 18 : values.BloodSugarLevelMax;
				break;
			case 7:
				val.min = this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.CholesterolLevelMin / 0.02586 : values.CholesterolLevelMin;
				val.max = this.userSettings.Concentration.toLowerCase() !== 'mmol/l' ? values.CholesterolLevelMax / 0.02586 : values.CholesterolLevelMax;
				break;
			}
			return val;
		},
		// itemRowBackground: function () {
		// 	return 'style-dtable';
		// },
		lastMeasuredData (device) {
			if (this.measuredData.length === 0) {
				return;
			}
			switch (device) {
			case 1:
				if (this.userSettings.Units.toLowerCase() === 'metric') {
					return this.measuredData[this.measuredData.length - 1]?.Temperature_C + ' °C';
				} else if (this.userSettings.Units.toLowerCase() === 'imperial') {
					return this.measuredData[this.measuredData.length - 1]?.Temperature_F + ' °F';
				}
				break;
			case 2:
				return this.measuredData[this.measuredData.length - 1]?.BPM !== undefined ? this.measuredData[this.measuredData.length - 1]?.Spo2 + ' SpO2 ' + this.measuredData[this.measuredData.length - 1]?.BPM + ' bpm ' + ' Pi ' + (this.measuredData[this.measuredData.length - 1]?.PI).toFixed(2) + ' %' : '';
			case 3:
				return this.measuredData[this.measuredData.length - 1]?.Sys !== undefined ? this.measuredData[this.measuredData.length - 1]?.Sys + '/' + this.measuredData[this.measuredData.length - 1]?.Dias + ' mmHg ' + this.measuredData[this.measuredData.length - 1]?.Pul + ' bpm ' : '';
			case 5:
				if (this.userSettings.Units.toLowerCase() === 'metric') {
					return this.measuredData[this.measuredData.length - 1]?.weight_kg + ' kg';
				} else return this.measuredData[this.measuredData.length - 1]?.weight_lbs + ' lb';
			case 6:
				if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
					return this.measuredData[this.measuredData.length - 1]?.glucose_mmolL + ' mmol/L';
				} else return this.measuredData[this.measuredData.length - 1]?.glucose_mgL + ' mg/dL';
			case 7:
				if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
					return this.measuredData[this.measuredData.length - 1]?.cholesterol_mmolL + ' mmol/L';
				} else return this.measuredData[this.measuredData.length - 1]?.cholesterol_mgL + ' mg/dL';
			}
		},
	},
};
</script>
<style>
/* .v-card--link:before {
  background: none;
} */
/* .style-dtable {
  font-size: 14px;
  font-weight: normal;
  color: primary;
} */

.no-background-hover::before {
  background-color: transparent !important;
}

.v-input--selection-controls {
  margin-left: 60px !important;
  margin-top: 5px !important;
  padding-top: 4px;
}
</style>
