<template>
  <v-dialog v-model="localDialog" max-width="80%">
    <template>
      <v-card flat style="overflow: hidden" >
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{ $t("survey-dialog") }}</span>
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-card>
          <v-card-title class="font-weight-bold font-weight-black d-flex justify-center">{{ item[0].mentalHealthCategoryTitle }}</v-card-title>
          <v-card-subtitle class="text-left font-weight-bold d-flex justify-center">{{ item[0].templateDescription }}</v-card-subtitle>
          <v-row>
            <v-col>
              <v-card-title class="d-flex justify-start font-weight-black font-weight-bold">{{ patientFullName }}</v-card-title>
            </v-col>
            <v-col>
              <v-card-subtitle v-if="item[0].isCompleted" class="d-flex justify-end font-weight-black font-weight-bold">{{$t("submitedAt")}} : {{ item[0].submitAtDate }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
        <v-card-text color="primary" class="mt-3" >
            <div>
                  <div style="height: 700px; overflow-y: scroll;">
                    <div v-for="(question, questionIndex) in item[0].surveyQuestionsList" :key="questionIndex">
                      <v-list-item dense>
                        <v-list-item-avatar color="primary" size="21" style="margin-left:5px;">
                          <v-avatar size="19">
                            <span style="color: white; font-size: 14px;">{{ questionIndex + 1 }}</span>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-card-text class="d-flex justify-start text-h7 font-weight-bold">
                            {{ question.question }}
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-data-table
                          :headers="headersAnswer"
                          :items="question.offeredAnswersList"
                          :no-data-text="$t('no-option-entered')"
                          :items-per-page="-1"
                          hide-default-footer
                          dense
                          class="elevation-2 mx-2 my-2"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td class="d-flex align-center justify-center">
                              <v-checkbox disabled dense hide-details v-model="item.selectedAsAnswer" class="mb-2" :class="{ 'primary--text': !item.triggerNotification, 'error--text': item.triggerNotification }"></v-checkbox>
                            </td>
                            <td :class="{ 'text-left': true }" :style="item.triggerNotification ? 'color:red' : ''">{{ item.answer }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
              </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
	props: ['dialog', 'item'],
	components: {
	},
	data () {
		return {
			edit: false,
			show: false,
			surveyAnswerType: [],
			expanded: [],
			showQuestionForSurvey: {},
			isOpen: true,
			titleMap: new Map(),
			statusMap: new Map(),
			descriptionMap: new Map(),
			localDialog: this.dialog,
		};
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.survey,
			verifires: (state) => state.verifiers.verifiers,
		}),
		patientFullName () {
			return this.detailedUser.userData.firstName + ' ' + this.detailedUser.userData.lastName;
		},
		headersAnswer () {
			return [
				{ text: 'Selected answer', value: 'checkbox', sortable: false, width: '10%', align: 'center' },
				{ text: 'Possible answers', value: 'answer', sortable: false, width: '90%' },
			];
		},
		headerForUser () {
			return [
				{
					text: this.$t('date'),
					value: 'submitAtDate',
					width: '80%',
				},
				{
					text: this.$t('is-completed'),
					value: 'isCompleted',
					width: '10%',
				},
			];
		},
	},
	watch: {
		async item () {
			await this.findSurvey();
			await this.closeAll();
		},
	},
	mounted () {
		this.findSurvey();
		this.closeAll();
		this.manageMentalHealthDialog();
	},
	methods: {
		renderAnswers (offeredAnswersList) {
			const sortedAnswers = offeredAnswersList.sort((a, b) => a.orderNumber - b.orderNumber);
			return sortedAnswers.map(answer => ({
				answer: answer.answer,
				selected: answer.selectedAsAnswer,
				answeredAt: answer.answeredAt,
				orderNumber: answer.orderNumber,
				scorePoints: answer.scorePoints === null ? 0 : answer.scorePoints,
				selectedAsAnswer: answer.selectedAsAnswer,
			}));
		},
		async manageMentalHealthDialog () {
			const header2 = { domainsList: 'SURVEY_ANSWER_TYPE' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header2).then(() => {
				this.surveyAnswerType = [];
				this.verifires.forEach(item => {
					if (item.lowValue !== 4) { this.surveyAnswerType.push(item); }
				});
				this.surveyCreate = true;
			});
		},
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		editTemplate (id) {
			this.$store.dispatch('mentalhealth/SurveysAssignedById', id).then(() => {
				this.edit = true;
			});
		},
		isOpenFunction () {
			this.isOpen = !this.isOpen;
			return this.isOpen;
		},
		findSurvey () {
			this.item.forEach(item => {
				this.titleMap.set(item.surveyId, item.templateName);
				this.statusMap.set(item.surveyId, item.status);
				this.descriptionMap.set(item.surveyId, item.templateDescription);
				this.expanded.push(item.isCompleted);
			});
		},
	},
};
</script>
