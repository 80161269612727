<template>
  <v-dialog v-model="localDialog" max-width="90%" persistent>
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{ $t("survey-dialog") }}</span>
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card>
          <v-card-title class="font-weight-bold font-weight-black d-flex justify-center">{{ item[0].mentalHealthCategoryTitle }}</v-card-title>
          <v-card-subtitle class="text-left font-weight-bold d-flex justify-center">{{ item[0].templateDescription }}</v-card-subtitle>
            <v-row>
              <v-col>
            <v-card-title class="d-flex justify-start font-weight-black font-weight-bold">{{ patientFullName }}</v-card-title>
              </v-col>
              <v-col>
            <v-card-subtitle v-if="item[0].isCompleted" class="d-flex justify-end font-weight-black font-weight-bold">{{$t("submitedAt")}} : {{ item[0].submitAtDate }}</v-card-subtitle>
              </v-col>
            </v-row>
        </v-card>
        <v-card-text color="primary" class="mt-3" style="max-height: 715px; overflow: auto">
          <v-container class="py-10">
                  <v-card-text>
                    <v-row>
                      <v-col class="py-0" cols="7">
                      </v-col>
                      <v-col
                          class="py-0 d-flex align-center"
                          :style="{ 'border': '1px solid #888' }"
                          v-for="(answer, index) in renderAnswers(item[0].surveyQuestionsList[0].offeredAnswersList)"
                          :key="index"
                      >
                        <v-card-text class="font-weight-bold font-regular">
                          {{ answer.answer }}
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row v-for="(question, indexQ) in item[0].surveyQuestionsList" :key="question.id">
                      <!-- Check if the current question is not the last one before rendering content -->
                      <template v-if="indexQ !== item[0].surveyQuestionsList.length - 1">
                        <v-col cols="12">
                          <v-row style="height: 50px" class="py-0">
                            <v-col cols="7" class="d-flex align-center text-left font-weight-bold py-0" style="border: 1px solid #888;">
                              {{ question.orderNumber }}. {{ question.question }}
                            </v-col>
                            <v-col
                                :style="{
                                  'background-color': index > 1 && indexQ <= 7 ? 'lightgray' : (index > 0 && indexQ === 8 ? 'lightgray' : ''),
                                    'border': '1px solid #888'
                                  }"
                                class="d-flex align-center justify-center py-0"
                                v-for="(answer, index) in renderAnswers(question.offeredAnswersList)"
                                :key="index"
                            >
                              <v-icon color="main_black" v-if="answer.selected">mdi-18px mdi-check</v-icon>
                              <v-icon v-else>mdi-check-empty</v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12">
                          <v-row class="py-5">
                            <v-col class="py-0" cols="7"></v-col>
                            <v-col>Add columns</v-col>
                            <v-col
                                :style="{ 'background-color': 'lightgray', 'border': '1px solid #888' }"
                                class="py-0 d-flex align-center">
                              <v-card-text class="font-weight-bold font-regular">
                                {{ item[0].totalScorePointsSeveralDays }}
                              </v-card-text>
                            </v-col>
                            <v-col
                                :style="{ 'background-color': 'lightgray', 'border': '1px solid #888' }"
                                class="py-0 d-flex align-center">
                              <v-card-text class="font-weight-bold font-regular">
                                {{ item[0].totalScorePointsMoreThanHalfTheDays }}
                              </v-card-text>
                            </v-col>
                            <v-col
                                :style="{ 'background-color': 'lightgray', 'border': '1px solid #888' }"
                                class="py-0 d-flex align-center">
                              <v-card-text class="font-weight-bold font-regular">
                                {{ item[0].totalScorePointsNearlyEveryDay }}
                              </v-card-text>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="py-0" cols="7"></v-col>
                            <v-col cols="1" class="ml-6">Total</v-col>
                            <v-col
                                cols="3"
                                :style="{ 'background-color': 'lightgray', 'border': '1px solid #888' }"
                                class="py-0 ml-15"
                            >
                              <v-card-text class="font-weight-bold font-regular">
                                {{ item[0].totalScorePoints }}
                              </v-card-text>
                            </v-col>
                          </v-row>
                          <v-col cols="12" class="mt-5">
                            <v-row style="height: 50px">
                              <!-- Question Column -->
                              <v-col cols="7" class="d-flex align-center text-left font-weight-bold py-0" style="border: 1px solid #888;">
                                {{ question.orderNumber }}. {{ question.question }}
                              </v-col>
                              <!-- Answers Column -->
                              <v-col class="py-2" cols="5" style="border: 1px solid #888;">
                                <div>
                                    <span
                                        v-for="(answer, index) in renderAnswers(question.offeredAnswersList)"
                                        :key="index"
                                        class="d-flex"
                                        :style="{ 'display': 'flex', 'justify-content': 'space-between', 'align-items': 'center', 'background-color': index > 1 && indexQ <= 7 ? 'lightgray' : (index > 0 && indexQ === 8 ? 'lightgray' : '') }"
                                    >
                                        <span class="font-weight-bold font-regular">{{ answer.answer }}</span>
                                        <v-icon v-if="answer.selected" class="mdi-check ml-auto pr-4">mdi-18px mdi-check</v-icon>
                                        <div v-else class="ml-auto" style="border-bottom: 1px solid #888; width:50px;"></div>
                                    </span>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-container>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
	props: ['dialog', 'item'],
	components: {
	},
	data () {
		return {
			surveyAnswerType: [],
			localDialog: this.dialog,
		};
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
		}),
		patientFullName () {
			return this.detailedUser.userData.firstName + ' ' + this.detailedUser.userData.lastName;
		},
	},
	methods: {
		renderAnswers (offeredAnswersList) {
			const sortedAnswers = [...offeredAnswersList].sort((a, b) => a.orderNumber - b.orderNumber);
			return sortedAnswers.map(answer => ({
				answer: answer.answer,
				selected: answer.selectedAsAnswer,
				answeredAt: answer.answeredAt,
				orderNumber: answer.orderNumber,
				scorePoints: answer.scorePoints === null ? 0 : answer.scorePoints,
				selectedAsAnswer: answer.selectedAsAnswer,
			}));
		}
		,
	},
};
</script>
