<template>
    <v-dialog v-if="dialog" v-model="dialog" width="500" persistent>
        <v-card flat style="overflow: auto">
            <v-card-title class="secondary py-1" style="height: 40px">
                <span class="dialog-title">{{ $t("medicalTeam") }} {{ $t("members") }}</span>
                <v-spacer />
                <v-icon small color="primary" @click="$emit('false')">
                    mdi-window-close
                </v-icon>
            </v-card-title>

            <v-list flat>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <v-row dense>
                        <v-col class="col-center-alignment">
                            <v-list-item-title>{{ medicalTeams[0].medicalTeamTitle }}</v-list-item-title>
                        </v-col>
                    </v-row>
                    <v-row dense class="pb-2">
                        <v-col cols="12" class="col-center-alignment">
                            <v-list-item-subtitle class="grey--text">{{ medicalTeams[0].medicalTeamDescription }}</v-list-item-subtitle>
                        </v-col>
                    </v-row>
                    <v-list-item :aria-readonly="true" v-for="(member, i) in membersList" :key="i">
                        <v-list-item-content>
                            <v-row>
                                <v-col cols="12" lg="1" md="1" sm="1" class="col-left-alignment">
                                    <v-icon v-if="member.roleId === 3" size="24px" color="primary">mdi-doctor</v-icon>
                                    <v-icon v-else size="24px" color="primary">mdi-mother-nurse</v-icon>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="6" class="col-left-alignment">
                                   <v-list-item-title>{{ member.firstName }} {{ member.lastName }}</v-list-item-title>
                                   <v-list-item-subtitle>{{ member.email }}</v-list-item-subtitle>
                                </v-col>
                                <v-col cols="12" lg="5" md="5" sm="5" class="col-right-alignment">
                                    {{ asyoutype(member.phone) }}
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <div v-if="membersList === undefined" class="pa-6 ma-6" style=" backgroundcolor: transparent; border: 1px dashed #E8E8E8; border-radius: 5px;">
                <span class="primary--text pa-2" depressed outlined style="border: solid #E8E8E8; background-color: transparent !important; border-radius: 8px;">
                    This user has no medical team!
                </span>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { AsYouType } from 'libphonenumber-js';

export default {
	data () {
		return {
			// selectedItem: null,
		};
	},
	props: ['dialog', 'medicalTeams'],
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
		}),
		membersList () {
			return this.medicalTeams[0]?.membersList;
		},
		selectedItem: {
			get: function () {
				return this.medicalTeams[0]?.primaryDoctorId;
			},
			set: function (newValue) {
				return newValue;
			},
		},
	},
	mounted () {
	},
	methods: {
		asyoutype (val) {
			return new AsYouType().input(val);
		},
	},
};
</script>
